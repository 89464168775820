import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { NgxSpinnerService } from 'ngx-spinner';
import { SigningService } from '../signing.service';

@Component({
  selector: 'app-oegdsigning',
  templateUrl: './oegdsigning.component.html',
  styleUrls: ['./oegdsigning.component.scss']
})
export class OEGDSigningComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad!: SignaturePad;
  @ViewChild('signaturePadInstance') signaturePadInstance!: ElementRef;

  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'canvasWidth': 2480 / 3508 * (window.innerHeight - 75),
    'canvasHeight': (window.innerHeight - 75),
    'minWidth': 0.5,
    'maxWidth': 1.5 
  };

  showSignature = false;
  signatureUUID = "";
  signatureTimeout: any = null;
  signatureBlob = "";
  signatureDrawn = "";

  constructor(private spinnerService: NgxSpinnerService, private renderer: Renderer2, private signingService: SigningService) { }

  ngOnInit(): void {
    this.spinnerService.show('signing-spinner');
  }

  ngOnDestroy(): void {
    if(this.signatureTimeout !== null) clearTimeout(this.signatureTimeout);
  }

  ngAfterViewInit(): void {
    this.loadSignature();
  }

  loadSignature(): void {
    this.signingService.postSigningStatus({}).subscribe((response) => {
      switch(response.data.signing_status) {
        case "NONE":
          this.showSignature = false;
          this.signatureDrawn = "";
          this.signatureBlob = "";
          this.signatureUUID = "";
          this.spinnerService.show('signing-spinner');
          this.spinnerService.hide('signing-success-spinner');
          this.signatureTimeout = setTimeout(() => {
            this.loadSignature();
          }, 3000);
        break;
        case "DONE":
          this.showSignature = false;
          this.signatureDrawn = "";
          this.signatureBlob = "";
          this.signatureUUID = "";
          this.spinnerService.hide('signing-spinner');
          this.spinnerService.show('signing-success-spinner');
          this.signatureTimeout = setTimeout(() => {
            this.loadSignature();
          }, 3000);
        break;
        case "NEW":
        case "WAITING":
          this.spinnerService.hide('signing-spinner');
          this.spinnerService.hide('signing-success-spinner');

          this.signatureBlob = response.data.signing_blob;

          if(response.data.signing_status === "NEW" || this.signatureUUID !== response.data.request_id) {
            this.showSignature = false;
            this.signatureDrawn = "";
            this.signatureUUID = response.data.request_id;
            this.renderer.setStyle(this.signaturePadInstance.nativeElement, 'width', `${(2480 / 3508 * (window.innerHeight - 75))}px`);
            var canvas = this.signaturePadInstance.nativeElement.firstChild.firstChild;
            var context = canvas.getContext('2d');
            let image = new Image();
            image.src = this.signatureBlob;
            image.onload = function() {
                context.drawImage(image, 0, 0, (window.innerHeight - 75) * image.width / image.height, window.innerHeight - 75);
            }
          }

          this.showSignature = true;

          this.signatureTimeout = setTimeout(() => {
            this.loadSignature();
          }, 3000);
      }
    }, (error) => {
      this.showSignature = false;
      this.spinnerService.show('signing-spinner');
      this.spinnerService.hide('signing-success-spinner');
      this.signatureTimeout = setTimeout(() => {
        this.loadSignature();
      }, 3000);
    });
  }

  resetSignature(): void {
    this.signatureDrawn = "";
    var canvas = this.signaturePadInstance.nativeElement.firstChild.firstChild;
    var context = canvas.getContext('2d');
    let image = new Image();
    image.src = this.signatureBlob;
    image.onload = function() {
        context.drawImage(image, 0, 0, (window.innerHeight - 75) * image.width / image.height, window.innerHeight - 75);
    }
  }

  onDrawComplete(): void {
    this.signatureDrawn = this.signaturePad.toDataURL();
  }

  sendSignature(): void {
    clearTimeout(this.signatureTimeout);
    this.spinnerService.show();
    this.signingService.postSigningStatus({
      "save": true,
      "request_id": this.signatureUUID,
      "signing_blob": this.signaturePad.toDataURL()
    }).subscribe((response) => {
      this.spinnerService.hide();
      if(response.data.signing_status === "DONE") {
        this.spinnerService.hide('signing-spinner');
        this.spinnerService.show('signing-success-spinner');
        this.signatureTimeout = setTimeout(() => {
          this.spinnerService.show('signing-spinner');
          this.loadSignature();
        }, 10000);
      } else {
        this.spinnerService.show('signing-spinner');
        this.loadSignature();
      }
    }, (error) => {
      this.spinnerService.hide();
      this.spinnerService.show('signing-spinner');
      this.loadSignature();
    });
  }
}
