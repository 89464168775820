import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../company.service';
import * as moment from 'moment';
import { Order } from '../../../models/order';

@Component({
  selector: 'app-company-order-edit',
  templateUrl: './company-order-edit.component.html',
  styleUrls: ['./company-order-edit.component.css']
})
export class CompanyOrderEditComponent implements OnInit {

  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();
  @Input() order: Order | undefined;
  @Input() companyId: any;

  form!: FormGroup;
  errorMessage = '';

  constructor(private fb: FormBuilder, private translate: TranslateService, private companyService: CompanyService) { }

  ngOnInit(): void {
    let date: any = moment(this.order?.date).format('DD.MM.yyyy');
    date = (date === 'Invalid date') ? this.order?.date : date;

    this.form = this.fb.group({
      name: [this.order?.name, [Validators.required]],
      date: [date, [Validators.required]],
      max_users: [{value: this.order?.selfcheckin_max_users, disabled: (this.order?.selfcheckin_max_users === null) ? true : false}, [Validators.required]],
      consecutive_order_ids: [this.order?.consecutive_order_ids],
      is_walk_in: [this.order?.is_walk_in],
      selfcheckin: [this.order?.selfcheckin],
      minimal_required_fields: [this.order?.minimal_required_fields],
    });

    this.form.controls.selfcheckin.valueChanges.subscribe(value => {
      if (value) {
        this.form.controls.max_users.enable();
        this.form.controls.minimal_required_fields.enable();
      } else {
        this.form.controls.max_users.disable();
        this.form.controls.minimal_required_fields.disable();
      }
    });
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    const date = moment(this.form.controls.date.value, 'DD.MM.YYYY').utc(true).toISOString() ?? '';
    this.errorMessage = '';

    const updated: Order | any = { ...this.order };

    updated!.name = this.form.controls.name.value;
    updated!.date = date;
    updated!.selfcheckin_max_users = this.form.controls.max_users.value;
    updated!.is_walk_in = this.form.controls.is_walk_in.value;
    updated!.selfcheckin = this.form.controls.selfcheckin.value;
    updated!.consecutive_order_ids = this.form.controls.consecutive_order_ids.value;
    updated!.company_id = this.companyId;
    updated!.minimal_required_fields = this.form.controls.minimal_required_fields.value;

    this.companyService.editCompanyOrder(updated!).subscribe((res: any) => {
      this.submitFinished.emit(updated);
    }, (error: any) => {
      this.errorMessage = error?.error?.error?.description ?? 'Unknown Error';
    });

  }

}
