import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  @Output() userDeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() userUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() userCreated: EventEmitter<any> = new EventEmitter<any>();

  @Input() locations: any;
  @Input() companies: any;
  @Input() editMode = true;
  @Input() user: any = {
    username: '',
    firstname: '',
    lastname: '',
    location: '',
    location_name: '',
    role: '',
    role_name: '',
    company_id: '',
    company_name: '',
    password: '',
  };

  form!: FormGroup;

  constructor(private fb: FormBuilder, private userService: UserService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      username: [this.user.username, [Validators.required]],
      firstname: [this.user.firstname, []],
      lastname: [this.user.lastname, []],
      location: [this.user.location, []],
      location_name: [this.user.location_name, []],
      role: [this.user.role, [Validators.required]],
      role_name: [this.user.role_name, []],
      company_id: [this.user.company_id, []],
      company_name: [this.user.company_name, []],
      password: ['', []],
    });

    if (!this.editMode) {
      this.form.get('password')?.clearValidators();
      this.form.get('password')?.setValidators(Validators.required);
      this.form.get('password')?.updateValueAndValidity();
    }

    this.form.get('role')?.valueChanges.subscribe(val => {
      this.form.get('username')?.clearValidators();
      if (parseInt(val, 10) === 7) { // 7 is company role
        this.form.get('username')?.setValidators([Validators.required, Validators.email]);
      } else {
        this.form.get('username')?.setValidators([Validators.required]);
      }
      this.form.get('password')?.updateValueAndValidity();
    });
  }

  save(): void {
    if (this.editMode) {
     this.userService.editUserAdmin(this.user.id, this.form.value).subscribe(() => {
       this.userUpdated.emit(this.form.value);
     });
    } else {
      this.userService.addUserAdmin(this.form.value).subscribe(() => {
        this.userCreated.emit(this.user);
      });
    }
  }

  delete(): void {
    if (confirm('Benutzer ' + this.user.username + ' wirklich löschen?')) {
      this.userService.deleteUserAdmin(this.user.id).subscribe(() => {
        this.userDeleted.emit(this.user);
      });
    }
  }

}
