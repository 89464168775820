import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-privkey',
  templateUrl: './privkey.component.html',
  styleUrls: ['./privkey.component.css']
})
export class PrivkeyComponent implements OnInit {
  public error = '';
  public hideSkip = false;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.hideSkip = this.route.snapshot.paramMap.get('privkey') === '2';
  }

  ngOnInit(): void {
  }

  skip(): void {
    sessionStorage.removeItem('privkey');
    this.router.navigate(['/']);
  }

  uploadPrivkey($event: any): void {
    this.error = '';
    if ($event && $event.target && $event.target.files && $event.target.files[0]) {
      const file = $event.target.files[0];
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        if (!fileReader.result?.toString().startsWith('-----BEGIN') && !fileReader.result?.toString().startsWith('LOCATION')) {
          this.error = 'Kein privater Schlüssel.';
        } else {
          sessionStorage.setItem('privkey', fileReader.result.toString());
          this.router.navigate(['/']);
        }
      };
      fileReader.readAsText(file);
    } else {
      this.error = 'Keine Datei ausgewählt.';
    }
  }
}
