import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import templateDe from './privacy-de-template';
import templateEn from './privacy-en-template';
import domtoimage from 'dom-to-image';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css'],
})
export class SignatureComponent implements OnInit {

  templateDe = templateDe;
  templateEn = templateEn;

  activeTemplate = templateDe;

  @Output() done: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(SignaturePad) signaturePad!: SignaturePad;
  @ViewChild('privacyContainer', {static: false}) privacyContainer!: ElementRef;
  @ViewChild('printContainer', {static: false}) printContainer!: ElementRef;

  signature = '';

  privacyDocBlob: Blob | null = null;

  constructor(private renderer: Renderer2, private spinnerService: NgxSpinnerService) {
  }

  ngOnInit(): void {
  }

  public switchLanguage(lang: string): void {
    this.activeTemplate = (lang === 'de') ? this.templateDe : this.templateEn;
  }

  public confirmSignature(): void {
    this.spinnerService.show();
    const tempContainer = document.createElement('div');
    const position =  tempContainer.style.position;
    const left =  tempContainer.style.left;

    tempContainer.style.padding = '90px 120px';
    tempContainer.style.boxSizing = 'border-box';
    tempContainer.style.backgroundColor = '#fff';
    tempContainer.style.width = '1240px';
    tempContainer.style.height = '1754px';
    tempContainer.style.fontSize = '17px';
    tempContainer.style.position = 'absolute';
    tempContainer.style.right = '-2000px';
    tempContainer.innerHTML = this.activeTemplate;

    const img = document.createElement('img');
    img.src = this.signaturePad.toDataURL();

    tempContainer.appendChild(img);

    this.renderer.appendChild(this.printContainer.nativeElement, tempContainer);
    setTimeout(() => {
      domtoimage.toJpeg(tempContainer, {
        quality: 0.3,
        style: {
          position,
          left
        }
      })
        .then((image: any) => {
          this.printContainer.nativeElement.innerHTML = '';
          this.spinnerService.hide();
          this.done.emit(image);
        });
    }, 250);
  }

  onDrawComplete(): void {
    this.signature = this.signaturePad.toDataURL();
  }

  resetSignaturePad(): void {
    this.signature = '';
    this.signaturePad.clear();
  }
}
