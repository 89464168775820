import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { CompanyService } from '../../company.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit, OnDestroy {

  @ViewChild('deviceSelect') deviceSelect!: ElementRef;
  @Output() receiveData: EventEmitter<Result> = new EventEmitter<Result>();
  @Input() companyId: any;

  public faAddressCard: IconDefinition = faAddressCard;
  public deviceList: Appliance[] = [];
  public isVerifying = false;
  public modalOpen = false;
  public errorMessage = '';
  public result: Result | undefined;
  public loadingInstructions: string | undefined;
  private verificationId: any;
  private intervalId: any;
  private readonly currentApplianceStorageKey = 'currentAppliance';

  constructor(private companyService: CompanyService, private renderer: Renderer2, private translate: TranslateService) { }

  ngOnInit(): void {
    const companyId = this.companyId ?? this.companyService.currentCompany.id;

    if (!companyId) {
      return;
    }

    this.companyService.getApplianceList(companyId)
      .subscribe((res: any) => this.initSelect(res.data), error => this.handleError(error));
    this.translate.get('VERIFICATION.LOADING_INSTRUCTIONS').subscribe(text => this.loadingInstructions = text);
  }

  initSelect(deviceList: Appliance[]): void {
    if (deviceList && deviceList.length) {
      this.deviceList = deviceList;
      const currentAppliance = localStorage.getItem(this.currentApplianceStorageKey);
      if (currentAppliance) {
        setTimeout(() => this.renderer.setProperty(this.deviceSelect.nativeElement, 'value', currentAppliance));
      }
    }
  }

  initVerify(): void {
    const applianceId = this.deviceSelect.nativeElement.value;
    this.isVerifying = true;
    this.companyService.createPassportVerification(applianceId).subscribe((res: any) => {
      this.modalOpen = true;
      this.verificationId = res.data.verificationId;
      this.intervalId = setInterval(() => {
        this.companyService.getPassportVerificationStatus(this.verificationId).subscribe((statusResponse: any) => {
          if (['PROCESSED', 'FINISHED'].includes(statusResponse.data.status)) {
            clearInterval(this.intervalId);
            this.companyService.getVerificationResult(this.verificationId).subscribe((reportResponse: any) => {
              this.isVerifying = false;
              this.result = reportResponse.data.result;
            });
          }
        });
      }, 1000 * 5);

    }, error => this.handleError(error));
  }

  changeDevice(e: any): void {
    localStorage.setItem(this.currentApplianceStorageKey, e.target.value);
  }

  confirm(): void {
    this.receiveData.emit(this.result);
    this.result = undefined;
    this.modalOpen = false;
  }

  abortVerify(): void {
    if (this.isVerifying) {
      this.translate.get('VERIFICATION.CANCEL_CONFIRM').subscribe((translation) => {
        if (confirm(translation)) {
          this.isVerifying = false;
          this.modalOpen = false;
        }
      });
    } else {
      this.modalOpen = false;
    }
  }

  handleError(error: any): void {
    if (error.status === 403) {
      return;
    }
    this.errorMessage = error.error?.error?.description ?? 'Error';
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}

export interface Appliance {
  label: string;
  id: string;
}

export interface Result {
  idcardno: string;
  firstname: string;
  lastname: string;
  birthday: string;
  address: string;
  zip: string;
  city: string;
  sex: string;
}
