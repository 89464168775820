import { Component, Input, OnInit, EventEmitter, Output, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CheckinComponent, TestType, TestTypes } from '../../checkin.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthenticationService } from '../../../authentication.service';

@Component({
  selector: 'app-testkit-form',
  templateUrl: './testkit-form.component.html',
  styleUrls: ['./testkit-form.component.css']
})
export class TestKitFormComponent implements OnInit, OnDestroy {

  @ViewChild('testKitInput') testKitInput!: ElementRef;
  @ViewChild('iframe') iframe!: ElementRef;

  @Output() setTestTypeTitle: EventEmitter<any> = new EventEmitter();
  @Output() setErrorResponse: EventEmitter<any> = new EventEmitter();
  @Output() changeVisibility: EventEmitter<any> = new EventEmitter();
  @Output() setInstructionsInfoText: EventEmitter<any> = new EventEmitter();
  @Output() allDone: EventEmitter<any> = new EventEmitter();

  @Input() testKitNo: any;
  @Input() consecutiveOrderIds = true;
  @Input() companyMode = false;

  companyAntigenButtonLabel = '';
  showCompanyAntigenButton = false;
  showCustomIdButton = false;

  validationPattern = '^[A-Z0-9]{8,12}$';

  @Input() set data(value: TestType[]) {
    this.testTypes = value;

    value.forEach(testType => {
      this.translate.get('TEST_TYPES.' + testType.testType.toUpperCase()).subscribe((text: string) => {
        this.testKitEntries.push({
          ...testType,
          label: text,
          formControl: new FormControl()
        });
      });
    });

    this.activeTestKitEntry = this.testKitEntries[0];
  }

  public testTypes: TestType[] = [];
  public testKitEntries: EnrichedTestType[] = [];
  public showValidationError = false;
  public activeTestKitEntry: EnrichedTestType;
  public pdfLink = '';
  public inputMode = false;
  public customerName = '';
  public showUpgradeBtn = false;
  public eurofinsTypeFree = false;
  public eurofinsTypePaid = false;
  public eurofinsType = '';
  public wVal = '50%';

  constructor(
    private auth: AuthenticationService,
    private translate: TranslateService,
    private userService: UserService,
    private spinnerService: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private checkinComponent: CheckinComponent) {
    this.activeTestKitEntry = this.testKitEntries[0];
    this.customerName = checkinComponent.firstname + ' ' + checkinComponent.lastname;
  }

  public ngOnInit(): void {
    this.checkinComponent.setCancelButtonText('REGISTRATION.FINISH');
    this.checkinComponent.cancelButtonIsDisabled = true;
    this.checkFinishButton();
  }

  public ngOnDestroy(): void {
    this.setTitleOnParentView(true);
  }

  public submit(): void {
    if (!this.validateInput()) {
      return;
    }
    this.resetNotifications();
    this.handleRequest();
  }

  public sanitize(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public back(): void {
    this.resetNotifications();
    this.setTitleOnParentView(true);
    this.checkinComponent.setCancelButton(true);
    this.checkinComponent.setCancelButtonText('REGISTRATION.FINISH');
    this.inputMode = false;
    this.eurofinsType = '';
  }

  public setActiveTestType(testType: EnrichedTestType): void {
    if (testType.done) {
      return;
    }

    this.pdfLink = '';
    this.activeTestKitEntry = testType;
    this.inputMode = true;
    this.setTitleOnParentView();
    this.checkinComponent.setCancelButton(false);

    if (this.companyMode && this.testKitNo &&
      [TestTypes.ANTIGEN, TestTypes.ANTIGEN_FREE].includes(testType.testType) && this.consecutiveOrderIds) {
      this.checkinComponent.userInstructionsText = '';
      this.activeTestKitEntry.formControl.setValue(this.testKitNo.toString());
      this.companyAntigenButtonLabel = 'Checkin ' + this.testKitNo.toString().slice(this.testKitNo.toString().length - 3);
      this.showCompanyAntigenButton = true;
      this.showCustomIdButton = true;
    }

    setTimeout(() => {
      if ([TestTypes.PCR_LAB_FREE, TestTypes.PCR_LAB_PAID].includes(testType.testType)) {
        this.wVal = this.companyMode ? '100%' : '50%';
        if (testType.testType == TestTypes.PCR_LAB_FREE) this.eurofinsType = 'free';
        else if (testType.testType == TestTypes.PCR_LAB_PAID) this.eurofinsType = 'paid';
      }
      if (this.activeTestKitEntry.testType === TestTypes.PCR_LAB_FREE || this.activeTestKitEntry.testType === TestTypes.PCR_LAB_PAID) {
        this.validationPattern = '^[A-Z0-9]{8,10}$';
      } else if (this.testKitNo) {
        this.validationPattern = '^[0-9]{8,12}$';
      } else {
        this.validationPattern = '^[0-9]{8}$';
      }
      // this.validationPattern = (this.activeTestKitEntry.testType === TestTypes.PCR_LAB_FREE || this.activeTestKitEntry.testType === TestTypes.PCR_LAB_PAID) || this.testKitNo ? '^[A-Z0-9]{8,10}$' : '^[0-9]{8}$';
      this.focusInput();
    });
  }

  public setCustomId(): void {
    this.showCustomIdButton = false;
    this.showCompanyAntigenButton = false;
    this.companyAntigenButtonLabel = '';
    this.companyMode = false;
  }

  private resetNotifications(): void {
    this.setErrorResponse.emit(false);
    this.setTitleOnParentView(false);
  }

  private validateInput(): boolean {
    if (!this.activeTestKitEntry) {
      return false;
    }

    const formControl = this.activeTestKitEntry?.formControl;
    if (formControl.errors?.pattern || formControl.errors?.required || formControl.value === '') {
      this.showValidationError = true;
      return false;
    }
    this.showValidationError = false;
    return true;
  }

  private handleRequest(): void {
    this.userService.checkIn(
      this.checkinComponent.orderIDEntry.value,
      this.activeTestKitEntry.formControl.value,
      this.activeTestKitEntry.testType,
    )
      .subscribe(
        response => {
          if (response?.data?.new_password) {
            this.translate.get('REGISTRATION.NEW_PASSWORD_TEXT').subscribe((text: string) => {
              this.setInstructionsInfoText.emit(text);
            });
          } else {
            this.translate.get('REGISTRATION.OLD_PASSWORD_TEXT').subscribe((text: string) => {
              this.setInstructionsInfoText.emit(text);
            });
          }

          if ((this.activeTestKitEntry?.testType === TestTypes.PCR_LAB_FREE || this.activeTestKitEntry?.testType === TestTypes.PCR_LAB_PAID) && typeof response?.data === 'string') {
            const byteCharacters = atob(response.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'application/pdf'});

            this.pdfLink = URL.createObjectURL(blob);

            this.iframe.nativeElement.src = this.pdfLink;
            setTimeout(() => this.spinnerService.show());
            setTimeout(() => {
              this.spinnerService.hide();
              this.iframe.nativeElement.style.display = 'block';
              this.iframe.nativeElement.contentWindow.print();
            }, 2000);
          }

          this.setTitleOnParentView(true);
          this.inputMode = false;
          this.activeTestKitEntry.done = true;
          this.checkinComponent.setCancelButtonText('REGISTRATION.FINISH');
          this.checkinComponent.setCancelButton(true);

          if (!this.testKitEntries.filter(e => !e.done).length) {
            this.allDone.emit(this.testKitEntries[0].formControl.value);
          }
          this.checkFinishButton();
        },
        errorResponse => this.setErrorResponse.emit(errorResponse)
      );
  }

  private checkFinishButton(): void {
    if (this.testKitEntries.find(test => test.done)) {
      this.checkinComponent.cancelButtonIsDisabled = false;
    }
  }

  private setTitleOnParentView(reset = false): void {
    this.setTestTypeTitle.emit(reset ? '' : this.activeTestKitEntry.label);
  }

  private focusInput(): void {
    setTimeout(() => {
      this.testKitInput.nativeElement.focus();
    }, 0);
  }
}

export interface EnrichedTestType extends TestType {
  done: boolean;
  label: string;
  formControl: FormControl;
}
