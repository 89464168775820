import { Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CompanyService } from '../../../company.service';
import { AuthenticationService } from '../../../authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { isValidEmail } from '../../../helper';

@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.scss']
})
export class CompanyAddComponent implements OnInit {

  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputCompanyName') inputCompanyName!: ElementRef;
  @ViewChild('inputCompanyTestcenterId') inputCompanyTestcenterId!: ElementRef;
  @ViewChild('inputCompanyOegdName') inputCompanyOegdName!: ElementRef;
  @ViewChild('inputCompanyStreet') inputCompanyStreet!: ElementRef;
  @ViewChild('inputCompanyPostalCode') inputCompanyPostalCode!: ElementRef;
  @ViewChild('inputCompanyCity') inputCompanyCity!: ElementRef;
  @ViewChild('inputCompanyDescription') inputCompanyDescription!: ElementRef;
  @ViewChild('inputCompanyUserName') inputCompanyUserName!: ElementRef;
  @ViewChild('inputCompanyFirstname') inputCompanyFirstname!: ElementRef;
  @ViewChild('inputCompanyLastname') inputCompanyLastname!: ElementRef;
  @ViewChild('inputMultipleOrderUseAllowed') inputMultipleOrderUseAllowed!: ElementRef;

  companyIsWalkIn = false;
  companySkipFreeCheck = false;
  companyMultipleOrderUseAllowed = false;
  errorMessage = '';
  formIsValid = false;

  constructor(
    private companyService: CompanyService,
    private authService: AuthenticationService,
    private translate: TranslateService,
  ) {
    this.companyIsWalkIn = this.authService.isLocationShoppingcenter();
  }

  ngOnInit(): void {
    setTimeout(() => this.inputCompanyName.nativeElement.focus(), 0);
  }

  descriptionChanged(inVal: string): void {
    if (inVal === '') {
      this.inputCompanyDescription.nativeElement.style.setProperty('text-align', 'center');
    } else {
      this.inputCompanyDescription.nativeElement.style.setProperty('text-align', 'left');
    }
  }

  validateForm(): void {
    this.formIsValid = (this.inputCompanyDescription.nativeElement.value &&
      this.inputCompanyName.nativeElement.value &&
      isValidEmail(this.inputCompanyUserName.nativeElement.value)
    );
  }

  postNewCompanyEntry(): void {
    this.errorMessage = '';
    this.companyService.addCompany(
      this.inputCompanyName.nativeElement.value,
      this.inputCompanyOegdName.nativeElement.value ? this.inputCompanyOegdName.nativeElement.value : null,
      this.inputCompanyTestcenterId.nativeElement.value ? this.inputCompanyTestcenterId.nativeElement.value : null,
      (this.inputCompanyStreet.nativeElement.value) ? this.inputCompanyStreet.nativeElement.value : null,
      (this.inputCompanyPostalCode.nativeElement.value) ? this.inputCompanyPostalCode.nativeElement.value : null,
      (this.inputCompanyCity.nativeElement.value) ? this.inputCompanyCity.nativeElement.value : null,
      this.inputCompanyDescription.nativeElement.value,
      this.companyIsWalkIn,
      this.companySkipFreeCheck,
      {
        username: this.inputCompanyUserName.nativeElement.value,
        firstname: this.inputCompanyFirstname.nativeElement.value,
        lastname: this.inputCompanyLastname.nativeElement.value,
      },
      this.companyMultipleOrderUseAllowed
    )
      .subscribe(
        (response: any) => {
          const newCompany = {
            id:  response.data.companyId,
            name_oegd: response.data.name_oegd,
            testcenter_id: response.data.testcenter_id,
            name: this.inputCompanyName.nativeElement.value,
            description: this.inputCompanyDescription.nativeElement.value,
            contact: {
              username: this.inputCompanyUserName.nativeElement.value,
              email: '',
            },
            orders: [],
          };
          this.submitFinished.emit(newCompany);
        });
  }
}
