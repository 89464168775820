import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Person } from '../models/person';
import { UserService } from '../user.service';
import { Company } from '../models/company';
import { Order } from '../models/order';
import { CompanyService } from '../company.service';
import { AuthenticationService } from '../authentication.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-company-checkin',
  templateUrl: './company-checkin.component.html',
  styleUrls: ['./company-checkin.component.css']
})
export class CompanyCheckInComponent implements OnInit {

  @ViewChild('companySearchInput') companySearchInput!: ElementRef;

  public faSearch: IconDefinition = faSearch;
  patients: Person[] = [];
  selectedPatient: Person | null = null;
  infoMessage = '';

  companies: Company[] = [];
  selectedCompany: Company | null = null;
  selectedOrder: Order | null = null;
  selectedEmployee: Person | null = null;
  orders: Order[] = [];
  foundPatients: Person[] | any;

  showCompanyListContainer = true;
  showOrderListContainer = false;

  showSearchResultsContainer = false;
  showPatientListContainer = false;
  showTestKistContainer = false;
  showValidationError = false;
  showCheckinDialog = false;
  showTestResultDialog = false;
  showResendResultDialog = false;
  companySearchString = '';

  description = '';
  errorMessage = '';

  privateKey = '';

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    public companyService: CompanyService,
    private authenticationService: AuthenticationService,
    @Inject('Window') private window: Window
  ) {
    if (this.authenticationService.getPrivateKey() !== '') { this.privateKey = this.authenticationService.getPrivateKey(); }
  }

  ngOnInit(): void {
    this.loadCompanies().subscribe();
    this.reset();
  }

  searchCompany(): void {
    this.companySearchString = this.companySearchInput.nativeElement.value;
  }

  loadCompanies(): Observable<any> {
    return this.companyService.getCompanies(false).pipe(
      tap(response => {
        const currentCompany = this.companyService.currentCompany;

        if (currentCompany?.id) {
          this.onSelectCompany(currentCompany!);
          this.selectedCompany!.orders = response.data;
        } else {
          this.companies = response.data;
          if (this.selectedCompany) {
            this.selectedCompany = this.companies.find(company => company.id === this.selectedCompany?.id!) ?? null;
          }
        }
      }),
    );
  }

  onSelectCompany(company: Company): void {
    company.orders = company.orders?.filter(order => !order.is_archive);
    this.selectedCompany = company;
    this.changeVisibility(false, true);
  }

  onSelectOrder(order: Order): void {
    this.selectedOrder = order;

    this.companyService.getCompanyOrderTests(this.selectedOrder.id, this.selectedCompany?.id, this.privateKey).subscribe(response => {
      this.patients = response.data;
    });

    this.changeVisibility(false, false, true);
  }

  searchTestuserLastname(search: string): void {
    this.errorMessage = '';
    this.companyService.getCompanyOrderTestsBySearch(search, null, this.selectedCompany?.id, this.privateKey).subscribe((res: any) => {
      if (res.data?.length) {
        this.description = '';
        this.changeVisibility(false, false, false, false, true);
        this.foundPatients = res.data;
        this.showSearchResultsContainer = true;
        this.translate.get('COMPANY.SEARCH_FOR', { search }).subscribe((text: string) => {
          this.infoMessage = text;
        });
      } else {
        this.changeVisibility(false, true, false, false, false);
        this.foundPatients = [];
        this.translate.get('COMPANY.NO_RESULTS').subscribe((text: string) => {
          this.errorMessage = text;
        });
        this.showOrderListContainer = true;
      }
    });
  }

  cancel(): void {
    this.infoMessage = '';
    this.errorMessage = '';
    if (this.companyService.currentCompany?.id) {
      this.changeVisibility(false, true);
    } else if (this.showPatientListContainer) {
      this.changeVisibility(false, true);
    } else {
      this.changeVisibility(true);
    }
  }

  private reset(): void {
    this.infoMessage = '';
    this.errorMessage = '';
    this.changeVisibility(true);
  }

  private changeVisibility(company = true, order = false, list = false, testKit = false, search = false): void {
    this.window.scroll(0, 0);
    this.showCompanyListContainer = company;
    this.showOrderListContainer = order;
    this.showPatientListContainer = list;
    this.showTestKistContainer = testKit;
    this.showSearchResultsContainer = search;

    if (company) {
      if (!this.companyService.currentCompany?.id) {
        this.loadCompanies().subscribe();
      }
      this.translate.get('COMPANYCHECKIN.COMPANIES').subscribe((text: string) => {
        this.description = text;
      });
    }
    if (order) {
      if (!this.companyService.currentCompany?.id) {
        this.loadCompanies().subscribe();
      }
      this.translate.get('COMPANYCHECKIN.ORDERS').subscribe((text: string) => {
        this.description = text;
      });
    }
    if (list) {
      this.translate.get('COMPANYCHECKIN.TEXT_LIST_HEADER').subscribe((text: string) => {
        this.description = text;
      });
    }
    else if (testKit && !this.selectedPatient?.privacy_data_url) {
      this.translate.get('COMPANYCHECKIN.GIVE_CONSENT').subscribe((text: string) => {
        this.description = text + ': ' + this.selectedPatient?.lastname + ' ' + this.selectedPatient?.firstname;
      });
    }
    else if (testKit) {
      this.translate.get('COMPANYCHECKIN.TEXT_NUMBER_HEADER').subscribe((text: string) => {
        this.description = text + this.selectedPatient?.lastname + ' ' + this.selectedPatient?.firstname + ':';
      });
    }
  }

  private showError(errorResponse: any): void {
    if (errorResponse.error.error.description === 'MCS_ORDER_NOT_FOUND') {
      this.translate.get('TESTRESULT.MCS_ORDER_NOT_FOUND').subscribe((text: string) => {
        this.errorMessage = text;
      });
    } else {
      this.translate.get('TESTRESULT.UNKNOWN_ERROR').subscribe((text: string) => {
        this.errorMessage = text;
      });
    }
  }
}
