import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { USER_ROLES } from '../roles';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public readonly R = USER_ROLES;

  public username: string;
  public role: any;
  public location: string;
  public privateKeyUploaded: boolean;

  constructor(private authenticationService: AuthenticationService) {
    this.username = this.authenticationService.getLoggedInUser().username;
    this.role = this.authenticationService.getLoggedInUser().role;
    this.location = this.authenticationService.getLoggedInUser().location;
    this.privateKeyUploaded = this.authenticationService.getPrivateKey() !== '' ? true : false;
  }

  ngOnInit(): void {
  }

}
