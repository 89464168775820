import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../user.service';

@Component({
  selector: 'app-utilization',
  templateUrl: './utilization.component.html',
  styleUrls: ['./utilization.component.css']
})
export class UtilizationComponent implements OnInit {

  public utilizationData: any;
  public showBackendError = false;
  public errorMessage = "";

  constructor(
    private spinner: NgxSpinnerService,
    private userService: UserService
  ) { };

  ngOnInit(): void {
    this.spinner.show();
    this.userService.getUtilization()
      .subscribe(
        response => {
          this.spinner.hide();

          this.utilizationData = response.data;
          console.log(response.data);
        },
        errorResponse => {
          this.spinner.hide();
          console.log(errorResponse);
          this.errorMessage = errorResponse;
          this.showBackendError = true;
        }
      );
  }

}
