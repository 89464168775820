import { Component, OnInit } from '@angular/core';
import { Company } from '../../models/company';
import { CompanyService } from '../../company.service';

@Component({
  selector: 'app-infopoint-selfcheck',
  templateUrl: './infopoint-selfcheck.component.html',
  styleUrls: ['./infopoint-selfcheck.component.scss']
})
export class InfopointSelfcheckComponent implements OnInit {

  company: Company = {};

  constructor(private companyService: CompanyService) { }

  ngOnInit(): void {
    this.company = this.companyService.currentCompany;
  }

}
