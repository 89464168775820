<div class="center" [class.items-3]="!showId && !showActions">
  <ul class="header">
    <li>
      <span *ngIf="showId" class="normal" (click)="sortList('id', orderIdLabel)" #orderIdLabel>{{ 'COMPANY.ORDER_ID' | translate }}<fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      <span class="normal" (click)="sortList('name', orderNameLabel)" #orderNameLabel>{{ 'COMPANY.ORDER_NAME' | translate }} <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      <span class="normal" (click)="sortList('date', orderDateLabel)" #orderDateLabel>{{ 'COMPANY.ORDER_DATE' | translate }} <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      <span class="normal">{{ 'COMPANY.ORDER_STATUS' | translate }}</span>
      <span *ngIf="showActions" class="normal">{{ 'ORDERMGMT.TABLE_ACTIONS' | translate }}</span>
    </li>
  </ul>

  <div class="divider"></div>

  <ul class="list">
    <li *ngFor="let order of companyOrders" (click)="onSelectOrder($event, order)">
      <span *ngIf="showId" class="item normal">{{order.id}}</span>
      <span class="item normal">{{order.name}}</span>
      <span class="item normal">{{order.date | date: 'EE, dd.MM.yyyy' }}</span>
      <span class="item normal">
            {{ 'COMPANY.ORDER_STATUS_OPEN' | translate }}: {{ order.TESTS_OPEN ? order.TESTS_OPEN : 0 }},
        {{ 'COMPANY.ORDER_STATUS_PENDING' | translate }}: {{ order.TESTS_WAITING ? order.TESTS_WAITING : 0 }},
        {{ 'COMPANY.ORDER_STATUS_DONE' | translate }}: {{ order.TESTS_DONE ? order.TESTS_DONE : 0 }}
          </span>
      <span *ngIf="showActions" class="item normal actions">
        <fa-icon [icon]="faEdit" (click)="onEditCompanyOrder($event, order)" title="EDIT"></fa-icon>
        <fa-icon [icon]="faTrash" (click)="onDeleteCompanyOrder($event, order)" title="DELETE"></fa-icon>
        <fa-icon *ngIf="!archiveIsActive && allowArchive" [icon]="faFolderOpen" (click)="onArchiveCompanyOrder($event, order)"
                 title="ARCHIVE"></fa-icon>
      </span>
    </li>
  </ul>
</div>

<app-modal *ngIf="showEditOrderModal" (closeClicked)="showEditOrderModal = false">
  <app-company-order-edit [order]="selectedOrder" [companyId]="company.id" (submitFinished)="companyEditFinished($event)"></app-company-order-edit>
</app-modal>
