import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PrivkeyComponent } from './privkey/privkey.component';
import { SharedModule } from './shared-module/shared-module.module';
import { AdminRoutingModule } from './admin/admin.module';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/privkey', component: PrivkeyComponent },
  { path: '', loadChildren: './admin/admin.module#AdminRoutingModule' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), SharedModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
