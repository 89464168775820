import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from './shared-module/shared.service';

@Injectable({
  providedIn: 'root'
})
export class LocationInterceptorService implements HttpInterceptor {

  constructor(private sharedService: SharedService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const req = this.addLocationIdHeader(request);

    return next.handle(req);
  }

  private addLocationIdHeader(request: HttpRequest<any>): HttpRequest<any> {
    if (this.sharedService.locationId) {
      return request.clone({
        setHeaders: {
          'location-id': this.sharedService.locationId
        }
      });
    }
    return request;
  }
}
