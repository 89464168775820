<div class="center">
  <p>{{ 'COMPANY.TEXT_HEADER_ORDER_ENTRY' | translate }}
    {{company?.name }} ein.</p>
</div>

<div class="alert alert-danger" role="alert" *ngIf="errorMessage">
  {{ errorMessage | translate }}
</div>

<div class="center">
  <input #inputOrderName type="text" class="rounded"
         (keyup.enter)="inputOrderName.focus()" placeholder="{{ 'COMPANY.ORDER_NAME' | translate }}"/>
</div>
<div class="center">
  <input #inputOrderDate type="text" class="rounded"
         placeholder="{{ 'COMPANY.ORDER_DATE' | translate }}"/>
</div>

<label class="center">
  <input #inputOrderConsecutiveOrderIs type="checkbox" [(ngModel)]="orderConsecutiveOrderIs" />
  <span>{{ 'COMPANY.CONSECUTIVE_ORDER_IDS_LABEL' | translate }}</span>
</label>

<label class="center">
  <input #inputOrderWalkIn type="checkbox" [(ngModel)]="orderWalkIn" />
  <span>{{ 'COMPANY.IS_WALK_IN_LABEL' | translate }}</span>
</label>

<label class="center">
  <input #inputOrderSelfCheck type="checkbox" [(ngModel)]="orderSelfCheck"/>
  <span>{{ 'COMPANY.SELF_CHECK_LABEL' | translate }}</span>
</label>

<label class="center" *ngIf="orderSelfCheck">
  <input type="checkbox" [(ngModel)]="minimalRequiredFields"/>
  <span>Reduzierte Pflichtfelder im SelfCheck</span>
</label>

<div class="center" [hidden]="!orderSelfCheck">
  <input #inputOrderMaxUser type="text" class="rounded" value="1500"
         placeholder="{{ 'COMPANY.ORDER_MAX_USERS' | translate }}"/>
</div>

<div class="center">
  <button class="custom-btn rounded"
          (click)="submit()">{{ 'COMPANY.BTN_NEW_ORDER' | translate }}</button>
</div>
