import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject, Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(
          100,
          style({
            opacity: 1,
          }),
        ),
      ]),
      transition(':leave', [
        style({
          opacity: 1,
        }),
        animate(
          100,
          style({
            opacity: 0,
          }),
        ),
      ])
    ]),
  ],
})
export class ModalComponent implements OnInit, OnDestroy {
  @HostBinding('@openClose')
  @Output() closeClicked: EventEmitter<any> = new EventEmitter<any>();

  @Input() fixedSize = true;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    setTimeout(() => this.closeClicked.emit());
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    // this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
  }

  onClickOverlay(): void {
    this.closeClicked.emit();
  }

  onClickClose(): void {
    this.closeClicked.emit();
  }

  ngOnDestroy(): void {
    // this.renderer.setStyle(this.document.body, 'overflow', 'auto');
  }

}
