import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Person} from '../../../models/person';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {CompanyService} from '../../../company.service';
import {TestTypes} from '../../../checkin/checkin.component';
import {Result} from '../../../shared-module/verification/verification.component';
import * as COUNTRIES from './countries.json';
import {postcodeValidator} from 'postcode-validator';
import {getCountryCodeByName} from './countryHelper';
import {AuthenticationService} from '../../../authentication.service';
import {Company} from '../../../models/company';
import {UserService} from '../../../user.service';
import {downloadFile} from '../../../helper';

@Component({
  selector: 'app-testuser-edit',
  templateUrl: './testuser-edit.component.html',
  styleUrls: ['./testuser-edit.component.scss']
})
export class TestuserEditComponent implements OnInit {

  public countries: any = (COUNTRIES as any).default;
  public isShoppingcenter = false;
  public showUserPaymentModal = false;
  public selectedCompany: Company | null = null;

  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();
  @Input() companyId: any;
  @Input() pk: any;
  @Input() allowVerification = true;
  @Input() headline: string | undefined;
  @Input() lockTestType: TestTypes | any;
  @Input() addMode = false;
  @Input() orderId: any;
  @Input() showOrderSelection = false;
  @Input() testuser: Person | undefined | any = {
    firstname: '',
    lastname: '',
    birthday: '',
    email: '',
    phone: '',
    test_type: null,
    country: this.countries[0].name,
    zip: '',
    city: '',
    address: '',
    idcardno: '',
    test_location: '',
    test_time: '',
    sex: '',
    job_function: '',
    // reason: '',
  };
  form!: FormGroup;
  errorMessage = '';
  reasons: any = [];
  isLocationCologne = false;
  userHasNoCompany = false;
  companies: Company[] | any[] = [];

  public showImportCWAvCardModal = false;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private companyService: CompanyService,
    private authService: AuthenticationService,
    private userService: UserService,
  ) {
    this.isShoppingcenter = this.authService.isLocationShoppingcenter();
    this.isLocationCologne = this.authService.isLocationCologne();
    this.userHasNoCompany = this.authService.getCompanyId() === 0;

    this.userService.getTestReasons().subscribe(response => {
      this.reasons = response.data;
    });
  }

  ngOnInit(): void {
    this.testuser!.test_type = this.testuser!.test_type;
    this.initForm();

    if (this.showOrderSelection) {
      this.companyService.getCompanies().subscribe(res => {
        this.companies = res.data;

        if (this.isLocationCologne && this.showOrderSelection) {
          this.form.get('target_order_option')?.setValue('custom');
          this.form.get('target_company')?.setValue(206);
          this.form.get('target_order')?.setValue(Math.max(...this.companies.find(item => item.id === 206).orders.map((a: any) => a.id), 0));
        }
      });
    }
  }

  get showOEGDDownload(): boolean {
    return (this.testuser.test_type === TestTypes.PCR_LAB_FREE || this.testuser.test_type === TestTypes.PCR_LAB_PAID
      && parseInt(this.testuser?.status, 10) > 0);
  }

  public oegdDownload(): void {
    this.userService.downloadOEGD(this.testuser.order_id).subscribe(res => {
      downloadFile(res.body.data, 'OEGD');
    });
  }

  public initForm(): void {
    let birthday: any = moment(this.testuser?.birthday).format('DD.MM.yyyy');
    birthday = (birthday === 'Invalid date') ? this.testuser?.birthday : birthday;

    this.form = this.fb.group({
      firstname: [this.testuser?.firstname, [Validators.required]],
      lastname: [this.testuser?.lastname, [Validators.required]],
      birthday: [birthday, [Validators.pattern(/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/)]],
      email: [this.testuser?.email, [Validators.email]],
      phone: [this.testuser?.phone],
      country: [this.testuser?.country],
      zip: [this.testuser?.zip],
      city: [this.testuser?.city],
      address: [this.testuser?.address],
      idcardno: [this.testuser?.idcardno],
      sex: [this.testuser?.sex],
      reason: [this.testuser?.reason],
      test_type: [this.lockTestType ?? this.testuser?.test_type, [Validators.required]],
      test_location: [this.testuser?.test_location],
      test_time: [this.testuser?.test_time],
      job_function: [this.testuser?.job_function],
      target_order_option: ['current', [Validators.required]],
      target_company: [null, []],
      target_order: [null, []],
    }, {
      validator: (group: FormGroup) => {
        const zipInput = group.get('zip')!;
        const countryName = group.get('country')!.value;
        const countryCode = getCountryCodeByName(countryName);
        const zip = zipInput.value;

        if (countryCode && zip && !postcodeValidator(zip, countryCode.toUpperCase())) {
          zipInput.setErrors({ wrongZipFormat: true });
        } else {
          zipInput.setErrors(null);
        }

        const birthdayInput = group.get('birthday')!;
        const birthdayValue = birthdayInput.value;

        if (birthdayValue) {
          const formatted =  moment(birthdayValue, 'DD.MM.YYYY').utc(true).toISOString() ?? '';
          if (!formatted) {
            birthdayInput.setErrors({ invalidDate: true });
          }
        } else {
          birthdayInput.setErrors(null);
        }

        return null;
      }
    });

    setTimeout(() => {
      this.form.get('reason')?.setValue(this.testuser?.reason);
    });

    this.form.get('target_order_option')?.valueChanges.subscribe(val => {
      this.selectedCompany = null;
      this.form.get('target_company')?.setValue(null);
      this.form.get('target_order')?.setValue(null);

      if (val === 'custom') {
        this.form.get('target_company')?.setValidators([Validators.required, Validators.minLength(1)]);
        this.form.get('target_order')?.setValidators([Validators.required, Validators.minLength(1)]);
      } else {
        this.form.get('target_company')?.clearValidators();
        this.form.get('target_order')?.clearValidators();
      }
      this.form.get('target_company')?.updateValueAndValidity();
      this.form.get('target_order')?.updateValueAndValidity();
    });

    this.form.get('target_company')?.valueChanges.subscribe(val => {
      this.selectedCompany = this.companies.find(item => parseInt(item.id, 10) === parseInt(val, 10));
      this.form.get('target_order')?.setValue(null);
    });

    this.form.get('test_type')?.valueChanges.subscribe(val => {
      this.form.get('reason')?.setValue(null);
      if ([TestTypes.ANTIGEN_PAID_REDUCED, TestTypes.ANTIGEN_FREE, TestTypes.ANTIGEN_PAID].includes(val)) {
        this.form.get('reason')?.setValidators([Validators.required]);
      } else {
        this.form.get('reason')?.clearValidators();
      }
      this.form.get('reason')?.updateValueAndValidity();
    });

    // if (this.addMode) {
    //   this.form.get('test_type')?.valueChanges.subscribe(value => {
    //     if (value === TestTypes.ANTIGEN_FREE) {
    //       this.form.get('reason')?.setValidators(Validators.required);
    //     } else {
    //       this.form.get('reason')?.clearValidators();
    //     }
    //     this.form.get('reason')?.updateValueAndValidity();
    //   });
    // }

    if (this.lockTestType) {
      this.form.controls.test_type.disable();
    }

    // if (!this.addMode) {
    //   this.addValidators(this.form.controls.email, [Validators.required]);
    // }
    //
    // if (this.addMode) {
    //   this.addValidators(this.form.controls.test_type, [Validators.required]);
    // }
  }

  public receiveData(result: Result): void {
    const birthday: any = moment(result.birthday).format('DD.MM.yyyy');
    result.birthday = (birthday === 'Invalid date') ? result.birthday : birthday;

    this.form.patchValue(result);
  }

  private addValidators(formControl: AbstractControl, validators: ValidatorFn[]): void {
    formControl.setValidators(formControl.validator ? [formControl.validator, ...validators] : validators);
    formControl.updateValueAndValidity();
  }

  save(userPaid: boolean = false): void {
    this.showUserPaymentModal = false;

    if (!this.form.valid) {
      return;
    }

    if (this.addMode && this.isShoppingcenter && !userPaid
      && [TestTypes.ANTIGEN_PAID, TestTypes.ANTIGEN_PAID_REDUCED, TestTypes.ANTIGEN_CARD_TEAM_PAID, TestTypes.ANTIGEN_CARD].includes(this.form.controls.test_type.value)) {
      this.showUserPaymentModal = true;
      return;
    }

    const birthday = moment(this.form.controls.birthday.value, 'DD.MM.YYYY').utc(true).toISOString() ?? '';
    this.errorMessage = '';

    const updated: Person | any = {...this.testuser};

    updated!.firstname = this.form.controls.firstname.value;
    updated!.lastname = this.form.controls.lastname.value;
    updated!.birthday = birthday;
    updated!.phone = this.form.controls.phone.value;
    updated!.email = this.form.controls.email.value;
    updated!.idcardno = this.form.controls.idcardno.value;
    updated!.address = this.form.controls.address.value;
    updated!.country = this.form.controls.country.value;
    updated!.zip = this.form.controls.zip.value;
    updated!.city = this.form.controls.city.value;
    updated!.test_type = this.form.controls.test_type.value;
    updated!.test_location = this.form.controls.test_location.value;
    updated!.test_time = this.form.controls.test_time.value;
    updated!.sex = this.form.controls.sex.value;
    updated!.job_function = this.form.controls.job_function.value;
    updated!.reason = this.form.controls.reason.value;

    let companyId = this.companyId ?? this.testuser?.company_id;
    let orderId = this.orderId ?? this.testuser?.company_order_id;
    const targetOption = this.form.controls.target_order_option.value;

    if (targetOption === 'custom') {
      companyId = this.form.controls.target_company.value;
      orderId = this.form.controls.target_order.value;
    }

    (this.addMode ?
      this.companyService.addCompanyEmployee(
        updated!,
        companyId,
        orderId,
      ) :
      this.companyService.editCompanyEmployee(updated!, this.pk)).subscribe((res: any) => {
        if (targetOption === 'custom') {
          this.submitFinished.emit(null);
        } else {
          this.submitFinished.emit(this.addMode ? {
            ...updated,
            order_id: res.data.virtualOrderId,
            testkit_id: null,
            status: '0'
          } : updated);
        }
        if (this.addMode) {
          this.form.reset();
          this.initForm();
        }
    }, error => {
      this.errorMessage = error?.error?.error?.description ?? 'Unknown Error';
      if (this.errorMessage === 'COMPANY_HAS_NO_CURRENT_ORDER') {
        this.translate.get('COMPANY.COMPANY_HAS_NO_CURRENT_ORDER').subscribe(text => this.errorMessage = text);
      }
    });
  }

  openCWAvCardImport(): void {
    this.showImportCWAvCardModal = true;
  }

  importFinished(importVO: any): void {
    this.form.patchValue(importVO);
    this.showImportCWAvCardModal = false;
  }

}
