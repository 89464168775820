<div class="wrapper">
  <h1>{{ 'STATISTICS.TITLE' | translate }}</h1>

  <div class="center">
    <!-- <p>{{ 'STATISTICS.TEXT_HEADER' | translate }}</p> -->

    <div class="row">
      <div class="col-2">
        <span>Datum von</span><input #dateInputFrom type="text" class="form-control" style="width:150px;" value="{{ today | date:'dd.MM.yyyy'}}" placeholder="DD.MM.YYYY" (keyup.enter)="generateStatistics(dateInputFrom.value,dateInputTo.value,statisticsType.value,statisticsBase.value)"/><br>
        <span>Datum bis</span><input #dateInputTo type="text" class="form-control" style="width:150px;" value="{{ today | date:'dd.MM.yyyy'}}" placeholder="DD.MM.YYYY" (keyup.enter)="generateStatistics(dateInputFrom.value,dateInputTo.value,statisticsType.value,statisticsBase.value)"/>
      </div>
      <div class="col-3">
        Art der Statistik<br>
        <select class="form-control" #statisticsType style="width:250px;">
          <option value="reasons">Antigen Gründe</option>
          <option value="companies">Firmenbasiert</option>
          <option value="hours">Uhrzeitbasiert</option>
          <option value="days" selected>Tagbasiert</option>
        </select>
        <br>
          Datenbasis<br>
          <select class="form-control" #statisticsBase style="width:250px;">
              <option value="all" *ngxPermissionsOnly="P.READ_STATISTICS_ALL_COMPANIES" selected>Alle Firmen</option>
              <option [value]="company.id" *ngFor="let company of companies">{{ company.name }}</option>
          </select>
      </div>
      <div class="col-7" style="margin-bottom:40px;">
        <br>
        <button class="custom-btn form-control" style="width:300px;" (click)="generateStatistics(dateInputFrom.value,dateInputTo.value,statisticsType.value,statisticsBase.value);">{{ 'STATISTICS.BTN_GENERATE' | translate }}</button>
        <br><br>
        <button class="custom-btn form-control" style="width:300px;" (click)="downloadStatisticsPDF(dateInputFrom.value,dateInputTo.value,statisticsType.value,statisticsBase.value);">{{ 'STATISTICS.BTN_PDF_DOWNLOAD' | translate }}</button>
      </div>
    </div>


    <div [hidden]="!showValidationError" class="center small-text">
      {{ 'DOWNLOAD.TEXT_ERROR_VALIDATION' | translate }}
    </div>

    <ul *ngIf="statisticsTypeCompanies || statisticsTypeHours" class="header">
      <li>
          <span *ngIf="statisticsTypeCompanies" class="normal">{{ 'STATISTICS.COMPANY_NAME' | translate }}</span>
          <span *ngIf="statisticsTypeHours" class="normal">{{ 'STATISTICS.TIME' | translate }}</span>
          <span class="normal" *ngIf="!isShoppingcenter">{{ 'STATISTICS.STAT_ANTIGEN' | translate }}</span>
          <span class="normal">{{ 'STATISTICS.STAT_ANTIGEN_FREE' | translate }}</span>
          <span class="normal">{{ 'STATISTICS.STAT_ANTIGEN_PAID' | translate }}</span>
          <span class="normal">{{ 'STATISTICS.STAT_ANTIGEN_PAID_REDUCED' | translate }}</span>

          <span class="normal">{{ 'STATISTICS.STAT_ANTIGEN_CARD' | translate }}</span>
          <span class="normal">{{ 'STATISTICS.STAT_ANTIGEN_CARD_USE' | translate }}</span>
          <span class="normal">{{ 'STATISTICS.STAT_ANTIGEN_TEAM_PAID' | translate }}</span>
          <span class="normal">{{ 'STATISTICS.STAT_ANTIGEN_TEAM_FREE' | translate }}</span>

          <span class="normal">{{ 'STATISTICS.STAT_PCR_LAB_FREE' | translate }}</span>
          <span class="normal">{{ 'STATISTICS.STAT_PCR_LAB_PAID' | translate }}</span>
          <span class="normal" *ngIf="!isShoppingcenter">{{ 'STATISTICS.STAT_PCR_STD' | translate }}</span>
          <span class="normal" *ngIf="!isShoppingcenter">{{ 'STATISTICS.STAT_PCR_EXP' | translate }}</span>
          <span class="normal">{{ 'STATISTICS.STAT_TOTAL' | translate }}</span>
      </li>
    </ul>

    <ul *ngIf="statisticsTypeReasons" class="header">
      <li>
        <span class="normal">Company</span>
        <span *ngFor="let reason of reasons; let counter = index" class="normal col-flex">
          <span>Grund {{reason.label}}</span>
          <span class="col-desc">{{reason.title}}</span></span>
        <span class="normal">Gesamt</span>
      </li>
    </ul>

    <div *ngIf="statisticsTypeCompanies || statisticsTypeHours || statisticsTypeReasons" class="divider"></div>

    <ul *ngIf="statisticsTypeReasons" class="list">
      <li *ngFor="let stat of this.stats">
        <span class="item normal">{{stat.company_name}}</span>
        <span *ngFor="let r of stat.reasons" class="item normal">{{ r }}</span>
        <span class="item normal">{{stat.total}}</span>
      </li>
    </ul>

    <ul *ngIf="statisticsTypeCompanies || statisticsTypeHours" class="list">
      <li *ngFor="let stat of this.stats">
        <span class="item normal">{{stat.company_name}}</span>
        <span class="item normal" *ngIf="!isShoppingcenter">{{stat.num_antigen}}</span>
        <span class="item normal">{{stat.num_antigen_free}}</span>
        <span class="item normal">{{stat.num_antigen_paid}}</span>
        <span class="item normal">{{stat.num_antigen_paid_reduced}}</span>

        <span class="item normal">{{stat.num_antigen_card}}</span>
        <span class="item normal">{{stat.num_antigen_card_use}}</span>
        <span class="item normal">{{stat.num_antigen_team_paid}}</span>
        <span class="item normal">{{stat.num_antigen_team_free}}</span>

        <span class="item normal">{{stat.num_pcr_free}}</span>
        <span class="item normal">{{stat.num_pcr_paid}}</span>
        <span class="item normal" *ngIf="!isShoppingcenter">{{stat.num_pcr_std}}</span>
        <span class="item normal" *ngIf="!isShoppingcenter">{{stat.num_pcr_exp}}</span>
        <span class="item normal" style="font-weight:bold;">{{stat.num_total}}</span>
      </li>
    </ul>




    <div *ngIf="statisticsTypeDays" style="width:100%; overflow-x:scroll; white-space: nowrap;">
      <table style="width:100%; overflow-x:scroll; white-space: nowrap;">
        <tr  class="header">
          <td style="display:inline-block; width:200px;">Firmenname</td>
          <td style="display:inline-block; width:100px; text-align: center;" *ngFor="let day of this.days">{{day}}</td>
          <td style="display:inline-block; width:100px; text-align: center;">Gesamt</td>
        </tr>
        <tr>
          <td class="devider" [attr.colspan]="this.days.length"></td>
          <!-- <td class="devider" colspan="{{this.days.length}}"></td> -->
        </tr>
      </table>
      <table style="width:100%; overflow-x:scroll; white-space: nowrap;">
        <tr *ngFor="let stat of this.daystats">
          <td style="display:inline-block; width:200px;">{{stat[0]}}</td>
          <td style="display:inline-block; width:100px; text-align: center;" *ngFor="let dayval of stat[1]">{{dayval}}</td>
          <td style="display:inline-block; width:100px; text-align: center; font-weight:bold;">{{stat[2]}}</td>
        </tr>
        <tr>
          <!-- <td class="devider" colspan="{{this.days.length}}"></td> -->
          <td class="devider" [attr.colspan]="this.days.length"></td>
        </tr>
        <tr>
          <td style="display:inline-block; width:200px;"></td>
          <td style="display:inline-block; width:100px; text-align: center; font-weight:bold;" *ngFor="let totalcnt of this.totalCntArr">{{totalcnt}}</td>
        </tr>
      </table>
      </div>


    <div *ngIf="statisticsTypeCompanies || statisticsTypeHours || statisticsTypeReasons" class="divider"></div>

    <ul *ngIf="statisticsTypeReasons" class="list" style="font-weight:bold">
      <li>
        <span class="item normal"></span>
        <span *ngFor="let j of totalReasonsCount" class="item normal">{{j}}</span>
        <span class="item normal">{{total}}</span>
      </li>
    </ul>

    <ul *ngIf="statisticsTypeCompanies || statisticsTypeHours" class="list" style="font-weight:bold">
      <li *ngIf="stats.length">
        <span class="item normal"></span>
        <span class="item normal" *ngIf="!isShoppingcenter">{{total_antigen}}</span>
        <span class="item normal">{{total_antigen_free}}</span>
        <span class="item normal">{{total_antigen_paid}}</span>
        <span class="item normal">{{total_antigen_paid_reduced}}</span>

        <span class="item normal">{{total_antigen_card}}</span>
        <span class="item normal">{{total_antigen_card_use}}</span>
        <span class="item normal">{{total_antigen_team_paid}}</span>
        <span class="item normal">{{total_antigen_team_free}}</span>

        <span class="item normal">{{total_pcr_free}}</span>
        <span class="item normal">{{total_pcr_paid}}</span>
        <span class="item normal" *ngIf="!isShoppingcenter">{{total_pcr_std}}</span>
        <span class="item normal" *ngIf="!isShoppingcenter">{{total_pcr_exp}}</span>
        <span class="item normal" style="font-weight:bold;">{{total}}</span>
      </li>
    </ul>
  </div>


</div>
