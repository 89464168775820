import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {

  @Input() uuid: string | null | undefined = '';
  @Input() printLink = false;
  @Input() orderId: string | null | undefined = '';
  @Input() checkinLink = false;

  link = '';
  qrSrc = '';
  content = '';

  readonly selfCheckBaseLink = 'https://selfcheckin.meincoronaschnelltest.de/?id=';

  constructor(@Inject('Window') private window: Window) {}

  ngOnInit(): void {
    this.content = this.uuid ? this.selfCheckBaseLink + this.uuid : this.orderId!.toString();
  }

  print(): void {
    const printWindow = this.window.open('', 'QR Code', 'height=400,width=600');
    if (printWindow) {

      // @ts-ignore
      const src = this.window.document.querySelector('.qr-code-container canvas').toDataURL();

      printWindow.document.write('<html><head><title>Print Window</title>');
      printWindow.document.write('</head><body style="text-align: center"><img src=\'' + src + '\' />');
      printWindow.document.write('<h2 style="font-family: arial, sans-serif;">' + this.orderId + '</h2>');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      setTimeout(() => printWindow.print(), 100);
    }
  }

}
