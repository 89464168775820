import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../user.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AuthenticationService } from '../authentication.service';
import * as FileSaver from 'file-saver';
import { CompanyService } from '../company.service';
import exportTestuserData from './csvHelper';
import { PERMISSIONS } from '../roles';
import { NgxPermissionsService } from 'ngx-permissions';
import { Company } from '../models/company';
import * as JSZip from 'jszip';
import {downloadFile} from '../helper';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  @ViewChild('dateInput') dateInput!: ElementRef;
  @ViewChild('filterInput') filterInput!: ElementRef;
  @ViewChild('testDateInputFrom') testDateInputFrom!: ElementRef;
  @ViewChild('testDateInputTo') testDateInputTo!: ElementRef;
  @ViewChild('companyInput') companyInput!: ElementRef;
  @ViewChild('zipCb') zipCb!: ElementRef;
  @ViewChild('csvFilterAllInput') csvFilterAllInput!: ElementRef;
  @ViewChild('zipCsvInput') zipCsvInput!: ElementRef;
  @ViewChild('billingModeInput') billingModeInput!: ElementRef;

  public readonly P = PERMISSIONS;

  showValidationError = false;
  privateKeyUploaded = false;
  errorMessage = '';
  serverErrorMessage = '';
  privateKey = '';
  location = '';
  companyId: number | undefined;
  companies: Company[] | [] = [];
  today: number = Date.now();

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    private permissionService: NgxPermissionsService,
  ) {
    this.privateKeyUploaded = this.authenticationService.getPrivateKey() !== '' ? true : false;
    if (this.privateKeyUploaded) { this.privateKey = this.authenticationService.getPrivateKey(); }
    this.location = this.authenticationService.getLoggedInUser().location;
    this.companyId = this.companyService.currentCompany.id;
    if (this.permissionService.getPermission(PERMISSIONS.CAN_SWITCH_COMPANY_DOWNLOAD_RESULTS)) {
      this.companyService.getCompanies().subscribe((res: any) => this.companies = res.data);
    }
  }

  ngOnInit(): void {
    this.reset();
  }

  validateDate(dateString: string): any {
    this.errorMessage = '';

    try {
      const dateTemp = moment(dateString, 'DD.MM.YYYY');
      if (isNaN(dateTemp.toDate().valueOf())) {
        throw new Error();
      }
      return dateTemp.format('YYYY-MM-DD');
    } catch (err: any) {
      this.translate.get('REGISTRATION.DATE_INVALID').subscribe((text: string) => {
        this.errorMessage = text;
      });
      return false;
    }
  }

  downloadResultsPdf(date: string): void {
    const dateTemp = this.validateDate(date);
    const filter = this.filterInput.nativeElement.value;
    let company: any = '';

    if (this.permissionService.getPermission(PERMISSIONS.CAN_SWITCH_COMPANY_DOWNLOAD_RESULTS)) {
      company = this.companyInput.nativeElement.value ?? this.companyId;
    } else {
      company = this.companyId;
    }

    const zip = this.zipCb.nativeElement.checked;

    if (dateTemp) {
      this.downloadPositives(dateTemp, filter, company, zip);
    }
  }

  setCurrentMonth(): void {
    const date = new Date();
    this.testDateInputFrom.nativeElement.value = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('DD.MM.yyyy');
    this.testDateInputTo.nativeElement.value = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('DD.MM.yyyy');
  }

  downloadTestuserDataCSV(): void {

    const dateFrom = this.testDateInputFrom.nativeElement.value;
    const dateTo = this.testDateInputTo.nativeElement.value;

    const dateTempFrom = this.validateDate(dateFrom);
    const dateTempTo = this.validateDate(dateTo);

    if (dateTempFrom && dateTempTo) {
      const allFilter = this.csvFilterAllInput.nativeElement.value;
      const csvZip = this.zipCsvInput.nativeElement.checked;
      const billingMode = this.billingModeInput.nativeElement.checked;
      this.downloadCsvTestuserData(dateTempFrom, dateTempTo, allFilter, csvZip, billingMode);
    }
  }

  public changeBillingModeCb(): void {
    this.csvFilterAllInput.nativeElement.value = 'sc';
  }

  private downloadCsvTestuserData(
    dateStringFrom: string,
    dateStringTo: string,
    filter: string = '',
    zip = false,
    billingMode = false
  ): void {

    const dateString = `${dateStringFrom}to${dateStringTo}`;
    let dateLabel = dateStringFrom;

    if (dateStringFrom !== dateStringTo) {
      dateLabel = `${dateStringFrom} - ${dateStringTo}`;
    }

    this.companyService.getCompanyTestuserDataByTestdate(dateString, this.companyId, this.privateKey, filter, billingMode)
      .subscribe((response: any) => {
          if (response.status === 204) {
            this.testDateInputFrom.nativeElement?.focus();
            this.translate.get('DOWNLOAD.NO_RESULTS_CSV').subscribe((text: string) => this.errorMessage = text);
          } else {
            if (this.companyId) {
              exportTestuserData(response.body.data, dateLabel, 'personendaten.csv', this.companyService.currentCompany.name);
            } else {

              let results = response.body.data;
              results.sort((a: any, b: any) => {
                if (a.test_type > b.test_type) { return 1; }
                if (a.test_type < b.test_type) { return -1; }

                if (a.company_name > b.company_name) { return 1; }
                if (a.company_name < b.company_name) { return -1; }

                if (a.checkin_time > b.checkin_time) { return 1; }
                if (a.checkin_time < b.checkin_time) { return -1; }

                return 0;
              });

              if (zip && !billingMode) {
                const companyResults = this.groupBy(results, 'company_name');
                const zipFile: JSZip = new JSZip();

                // tslint:disable-next-line:forin
                for (const company in companyResults) {
                  const csvData = exportTestuserData(companyResults[company], dateLabel, company + '.csv', company, true, false);
                  zipFile.file(csvData.fileName, csvData.blob);
                }

                zipFile.generateAsync({type: 'base64'}).then((content) => {
                  location.href = 'data:application/zip;base64,' + content;
                });

                return;
              }

              if (billingMode) {
                const companyResults = this.groupBy(results, 'company_name');
                const companyGrouped = [];
                const zipFile: JSZip = new JSZip();
                // tslint:disable-next-line:forin
                for (const company in companyResults) {
                  companyResults[company].push({ total_price: companyResults[company].map((item: any) =>
                      item.billing_price).reduce((prev: any, next: any) => parseFloat(prev) + parseFloat(next))});
                  if (zip) {
                    const csvData = exportTestuserData(companyResults[company], dateLabel, company + '.csv', company, true, false, true);
                    zipFile.file(csvData.fileName, csvData.blob);
                  } else {
                    companyGrouped.push(companyResults[company]);
                  }
                }
                if (zip) {
                  zipFile.generateAsync({type: 'base64'}).then((content) => {
                    location.href = 'data:application/zip;base64,' + content;
                  });
                } else {
                  results = ([] as any[]).concat(...companyGrouped);
                  exportTestuserData(results, null, 'personendaten.csv', '', true, true, true);
                }
                return;
              }

              exportTestuserData(results, null, 'personendaten.csv', '', true, true);
            }
          }
        },
        errorResponse => {
          this.serverErrorMessage = errorResponse.message;
        }
      );
  }

  private groupBy = (array: any, key: string) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }

  private downloadPositives(dateString: string, filter: string, companyId: any, zip = false): void {
    this.userService.downloadPositives(dateString, filter, this.privateKey, companyId, zip)
      .subscribe(
        response => {
          if (response.status === 204) {
            this.reset();
            this.translate.get('DOWNLOAD.NO_RESULTS').subscribe((text: string) => this.errorMessage = text);
          } else {
              this.downloadFile(response.body.data, dateString, filter, zip ? 'zip' : 'pdf');
          }
        },
        errorResponse => this.serverErrorMessage = errorResponse.message
      );
  }

  private downloadAsCSVFile(data: any, testDate: string): void {
    const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], {type: 'text/csv' });
    FileSaver.saveAs(blob, 'positiveTestresults_' + this.location + '_' + testDate + '.csv');
  }

  private downloadFile(data: any, testDate: string, filter: string, type = 'pdf'): void {
    downloadFile(data, 'positiveTestresults_' + filter + '_' + testDate, type);
  }

  private reset(): void {
    this.errorMessage = '';
    this.serverErrorMessage = '';

    setTimeout(() => {
      if (this.privateKeyUploaded) {
        this.dateInput.nativeElement.focus();
        const today = moment(new Date()).format('DD.MM.YYYY');
        this.dateInput.nativeElement.value = today;
        if (this.testDateInputFrom?.nativeElement) {
          this.testDateInputFrom.nativeElement.value = today;
        }
      }
    }, 0);
  }
}
