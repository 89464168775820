import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';
import * as errorNotifications from './error-notifications.json';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  public readonly DEFAULT_ERROR_MESSAGE = 'Fehler aufgetreten';

  @Input() message: any = '';
  @Input() autoMode = false;
  @Input() error: any = null;
  @Input() serverMessage?: string;
  @Input() type?: 'success' | 'danger' | 'warning' = 'danger';

  errorNotifications: any = (errorNotifications as any).default;
  showMore = false;

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    if (this.autoMode) {
      this.initAutoMode();
    } else {
      this.init();
    }
  }

  public initAutoMode(): void {
    this.sharedService.lastErrorAsObservable.subscribe((response: any) => this.setErrorResponse(response));
  }

  private setErrorResponse(response: any): void {
    const err = response.error.error;
    this.serverMessage = JSON.stringify(err);
    this.mapErrorMessage(err.description);
    this.sharedService.lastError = null;
  }

  private mapErrorMessage(description: any): void {
    if (this.message) { return; }
    if (this.errorNotifications[description]) {
      this.message = this.errorNotifications[description];
    } else {
      this.message = this.DEFAULT_ERROR_MESSAGE;
    }
  }

  public init(): void {
    if (!this.message && !this.serverMessage && this.error?.error?.error?.description) {
      this.serverMessage = this.error?.error?.error?.description;
      this.message = 'Server Error';
    }

    if (this.sharedService.lastError) {
      this.setErrorResponse(this.sharedService.lastError);
    }
  }
}
