import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Company } from '../../../models/company';
import { AuthenticationService } from '../../../authentication.service';
import * as moment from 'moment';
import { isNumeric } from 'rxjs/internal-compatibility';
import { Order } from '../../../models/order';
import { CompanyService } from '../../../company.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-order-add',
  templateUrl: './company-order-add.component.html',
  styleUrls: ['./company-order-add.component.scss']
})
export class CompanyOrderAddComponent implements OnInit {

  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();
  @Input() company: Company | any;

  @ViewChild('inputOrderName') inputOrderName!: ElementRef;
  @ViewChild('inputOrderDate') inputOrderDate!: ElementRef;
  @ViewChild('inputOrderPrice') inputOrderPrice!: ElementRef;
  @ViewChild('inputOrderSelfCheck') inputOrderSelfCheck!: ElementRef;
  @ViewChild('inputOrderMaxUser') inputOrderMaxUser!: ElementRef;

  orderWalkIn = false;
  orderConsecutiveOrderIs = true;
  minimalRequiredFields = false;
  orderSelfCheck = false;

  errorMessage = '';

  constructor(
    private authService: AuthenticationService,
    private companyService: CompanyService,
    private translate: TranslateService,
  ) {
    if (this.authService.isLocationShoppingcenter()) {
      this.orderSelfCheck = true;
      this.orderWalkIn = true;
      this.orderConsecutiveOrderIs = false;
    }
  }

  ngOnInit(): void {
    setTimeout(() => this.inputOrderName.nativeElement.focus(), 0);
  }

  submit(): void {
    this.errorMessage = '';

    const name = this.inputOrderName.nativeElement.value;
    const date = moment(this.inputOrderDate.nativeElement.value, 'DD.MM.YYYY').utc(true).toISOString();
    const maxUser = this.inputOrderMaxUser?.nativeElement?.value ?? false;

    if (this.orderSelfCheck && !isNumeric(maxUser) || this.orderSelfCheck && (!maxUser || maxUser === '')) {
      this.translate.get('COMPANY.ADD_ORDER_MAX_USER_ERROR').subscribe((text: string) => {
        this.errorMessage = text;
      });
      return;
    }

    this.companyService.addCompanyOrder(
      this.company?.id!,
      name,
      date,
      this.orderSelfCheck,
      maxUser,
      this.orderWalkIn,
      this.orderConsecutiveOrderIs,
      (this.orderSelfCheck && this.minimalRequiredFields),
    )
      .subscribe(
        (response: any) => {
          this.errorMessage = '';
          const newOrder: Order = {
            id: response.data.id,
            name,
            date,
            employees: [],
            is_walk_in: this.orderWalkIn,
            selfcheckin: this.orderSelfCheck,
            selfcheckin_test_type: 'antigen',
            selfcheckin_uuid: response.data.self_checkin_uuid,
            selfcheckin_cur_users: 0,
            selfcheckin_max_users: maxUser,
            selfcheckin_link: '',
            selfcheckin_urlencoded_link: '',
            is_archive: false,
            consecutive_order_ids: this.orderConsecutiveOrderIs,
            minimal_required_fields: (this.orderSelfCheck && this.minimalRequiredFields),
          };
          this.submitFinished.emit(newOrder);
        },
        (errorResponse: any) => {
          this.translate.get('REGISTRATION.EDIT_FAILED').subscribe((text: string) => {
            this.errorMessage = text;
          });
        }
      );

  }

}
