<div class="wrapper">
  <h1>{{ 'REGISTRATION.TITLE' | translate }}</h1>

  <!-- Error Message -->
  <app-alert *ngIf="errorMessage" [serverMessage]="serverErrorMessage" [message]="errorMessage"></app-alert>

  <!-- Header -->

  <div class="center no-margin" [hidden]="modalMode && showEntryContainer">
    <h2 *ngIf="!companyMode">{{ header }} <span *ngIf="headerAddition">{{ headerAddition }}</span></h2>
    <p *ngIf='!this.showSummaryContainer'>{{ description }}</p>
    <p class="alert alert-success" role="alert" *ngIf='this.showSummaryContainer'>{{ description }}</p>
    <p *ngIf="userInstructionsText"><strong>{{ userInstructionsText }}</strong></p>
  </div>

  <!-- Entry Container -->

  <div [hidden]="!showEntryContainer || modalMode">

    <div class="alert alert-warning" role="alert" [hidden]="!showValidationError">
      {{ 'REGISTRATION.TEXT_ERROR_VALIDATION' | translate }}
    </div>
    <div class="center">
      <input #orderInput type="text" class="rounded" (keyup.enter)="search()" [formControl]="orderIDEntry"
        pattern="^[0-9]{5,9}$" placeholder="{{ 'REGISTRATION.PLACEHOLDER_ORDER' | translate }}" />
    </div>
    <div class="center">
      <button class="custom-btn rounded" (click)="search()">
        {{ 'REGISTRATION.BTN_SEARCH' | translate }}
      </button>
    </div>

    <br>
    <br>
    <br>
    <br>

    <div *ngIf="searchResults.length">
      <h4>Suchergebnisse</h4>

      <ul class="header">
        <li>
          <span class="normal">Order ID</span>
          <span class="normal">Nachname</span>
          <span class="normal">Nachname</span>
          <span class="normal">Auftragsname</span>
          <span class="normal">Firma</span>
        </li>
      </ul>

      <div class="divider"></div>

      <ul class="list">
        <li *ngFor="let result of searchResults" (click)="onClickResult(result.order_id)">
          <span class="item normal">{{result.order_id}}</span>
          <span class="item normal">{{result.firstname}}</span>
          <span class="item normal">{{result.lastname}}</span>
          <span class="item normal">{{result.company_order_name}}</span>
          <span class="item normal">{{result.company_name}}</span>
        </li>
      </ul>

    </div>

    <ng-container *ngIf="!modalMode">
      <ng-container *ngxPermissionsOnly="P.CAN_SEARCH_LASTNAME_AT_CHECKIN">
        <h3>{{ 'REGISTRATION.TITLE_SEARCH_LASTNAME' | translate }}</h3>
        <p *ngxPermissionsOnly="P.VIEW_CHECKIN_SEARCH_INFO_TEXT">{{ 'REGISTRATION.DESCRIPTION_SEARCH_LASTNAME' | translate }}</p>

        <div class="center" >
          <input #orderSearchInput type="text" class="rounded" (keyup.enter)="searchUser()" [formControl]="formControlSearchLastname"
                 placeholder="{{ 'REGISTRATION.PLACEHOLDER_ORDER_SEARCH' | translate }}" />
        </div>
        <div class="center">
          <button class="custom-btn rounded" (click)="searchUser()">
            {{ 'REGISTRATION.BTN_SEARCH' | translate }}
          </button>
        </div>
      </ng-container>
    </ng-container>

  </div>

  <!-- Overview Datacheck Container -->

  <div [hidden]="!showOverviewContainer">

    <div class="alert alert-warning" role="alert" [hidden]="!showValidationError">
      {{ 'REGISTRATION.TEXT_ERROR_VALIDATION' | translate }}
    </div>

    <div class="alert alert-warning" role="alert" [hidden]="!multipleOrderUse">
      {{ 'REGISTRATION.MULTIPLE_ORDER_USE' | translate }} {{ orderIDEntry.value }}
    </div>

    <div class="center">
      <div class="input-cols">
        <div>
          <p class="description-text">{{ 'REGISTRATION.DESCRIPTION_FIRSTNAME' | translate }}</p>
          <h3>
            <input *ngIf="editMode == 'firstname'" autocapitalize="none" type="text" class="rounded" [(ngModel)]="this.firstname" (keyup.enter)="editMode = 'edited'" />
            <span *ngIf="editMode != 'firstname'" class="cursor-pointer" (click)="editMode = 'firstname'">{{ firstname }}</span>
          </h3>
        </div>
        <div>
          <p class="description-text">{{ 'REGISTRATION.DESCRIPTION_LASTNAME' | translate }}</p>
          <h3>
            <input *ngIf="editMode == 'lastname'" autocapitalize="none" type="text" class="rounded" [(ngModel)]="this.lastname" (keyup.enter)="editMode = 'edited'" />
            <span *ngIf="editMode != 'lastname'" class="cursor-pointer" (click)="editMode = 'lastname'">{{ lastname }}</span>
          </h3>
        </div>
      </div>

      <p class="description-text">{{ 'REGISTRATION.DESCRIPTION_BIRTH' | translate }}</p>
      <h3 *ngIf="editMode != 'birthday'" class="cursor-pointer" (click)="editMode = 'birthday'">{{ birth | date:'dd.MM.yyyy' }}</h3>
      <input *ngIf="editMode == 'birthday'" autocapitalize="none" type="text" class="rounded" [(ngModel)]="this.birthString" (keyup.enter)="this.editBirthday()" />

      <p class="description-text">{{ 'REGISTRATION.DESCRIPTION_EMAIL' | translate }}</p>
      <h3>
        <input *ngIf="editMode == 'email'" autocapitalize="none" type="email" class="rounded" [(ngModel)]="this.email" [formControl]="formControlEmail" (keyup.enter)="editMode = 'edited'" />
        <span *ngIf="editMode != 'email'" class="cursor-pointer" (click)="editMode = 'email'">{{ email }}</span>
      </h3>

      <p class="description-text">{{ 'REGISTRATION.DESCRIPTION_PHONE' | translate }}</p>
      <h3>
        <input *ngIf="editMode == 'phone'" autocapitalize="none" type="text" class="rounded" [(ngModel)]="this.phone" (keyup.enter)="editMode = 'edited'" />
        <span *ngIf="editMode != 'phone'" class="cursor-pointer" (click)="editMode = 'phone'">{{ phone }}</span>
      </h3>

      <p class="description-text">{{ 'REGISTRATION.DESCRIPTION_STREET' | translate }}</p>
      <h3>
        <input *ngIf="editMode == 'street'" autocapitalize="none" type="text" class="rounded" [(ngModel)]="this.street" (keyup.enter)="editMode = 'edited'" />
        <span *ngIf="editMode != 'street'" class="cursor-pointer" (click)="editMode = 'street'">{{ street }}</span>
      </h3>

      <div class="input-cols">
        <div>
          <p class="description-text">{{ 'REGISTRATION.DESCRIPTION_ZIP' | translate }}</p>
          <h3>
            <input *ngIf="editMode == 'zip'" autocapitalize="none" pattern="[0-9]{4,6}" type="text" class="rounded" [(ngModel)]="this.zip" [formControl]="formControlZip"  (keyup.enter)="editMode = 'edited'" />
            <span *ngIf="editMode != 'zip'" class="cursor-pointer" (click)="editMode = 'zip'">{{ zip }}</span>
          </h3>
        </div>
        <div>
          <p class="description-text">{{ 'REGISTRATION.DESCRIPTION_CITY' | translate }}</p>
          <h3>
            <input *ngIf="editMode == 'city'" autocapitalize="none" type="text" class="rounded" [(ngModel)]="this.city" (keyup.enter)="editMode = 'edited'" />
            <span *ngIf="editMode != 'city'" class="cursor-pointer" (click)="editMode = 'city'">{{ city }}</span>
          </h3>
        </div>
      </div>

      <p class="description-text">{{ 'REGISTRATION.DESCRIPTION_IDCARDNO' | translate }}</p>
      <h3>
        <input *ngIf="editMode == 'idcardno'" autocapitalize="none" type="text" class="rounded" [(ngModel)]="this.idcardno" (keyup.enter)="editMode = 'edited'" />
        <span *ngIf="editMode != 'idcardno'" class="cursor-pointer" (click)="editMode = 'idcardno'">{{ idcardno }}</span>
      </h3>

      <ng-container *ngIf="isCompanyUser">
        <p class="description-text">{{ 'REGISTRATION.TEST_TYPE' | translate }}</p>
        <h3>
          <!--        <span *ngIf="!isCompanyUser && editMode != 'testtype'" class="cursor-pointer" (click)="editMode = 'testtype'">{{ testTypes[0]?.testType | testTypeLabel | async }}</span>-->
          <!--        <span *ngIf="isCompanyUser && editMode != 'testtype'" class="cursor-pointer"  (click)="editMode = 'testtype'">{{ testtype | testTypeLabel | async }}</span>-->

          <select [(ngModel)]="testtype" (change)="selectTestType()" class="form-control">
            <option value="antigen-free">Kostenloser Bürgertest</option>
            <option [disabled]="hideAntigenSW" value="antigen">Antigen</option>
            <option value="antigen-paid">Antigen (bezahlt)</option>
            <option value="antigen-paid-reduced">Bürgertest (3 EUR)</option>
            <option *ngIf="!isScUser" value="antigen-team-paid">Antigen Team (bezahlt)</option>
<!--            <option value="antigen-team-free">Antigen Team (frei)</option>-->
            <option *ngIf="!isScUser" value="antigen-card">Antigen 10er Karte (bezahlt)</option>
            <option *ngIf="!isScUser" value="antigen-card-use">Antigen 10er Karte (einlösen)</option>
            <option value="pcr-lab-paid">PCR-Labor (bezahlt)</option>
            <option value="pcr-lab-free">PCR-Labor (angeordnet, KV)</option>

            <option value="pcr-std">PCR-Premium</option>
            <option value="pcr-exp">PCR-Express</option>
          </select>

        </h3>
      </ng-container>

      <ng-container *ngIf="showReasonSelect">
        <p class="description-text">{{ 'REGISTRATION.REASON' | translate }}</p>
        <h3>
          <select [(ngModel)]="reason" class="form-control" (change)="selectReason()" id="reason-select">
            <ng-container *ngFor="let reason of reasons">
              <option *ngIf="testtype === reason.test_type" [value]="reason.label">{{ reason.title }}</option>
            </ng-container>
          </select>
        </h3>
      </ng-container>

      <p class="description-text">{{ 'REGISTRATION.DESCRIPTION_PAYMENT' | translate }}</p>
      <h3>{{ payment }} €</h3>

      <ng-container *ngIf="!isCompanyUser">
        <p class="description-text">{{ 'REGISTRATION.TEST_TYPES' | translate }}</p>
        <ul class="test-preview">
          <li *ngFor="let test of testTypes">{{ test.testType | testTypeLabel | async }}</li>
        </ul>
      </ng-container>

    </div>

    <div class="center">
      <button *ngIf="editMode === ''" class="custom-btn rounded" (click)="confirm()">{{ 'REGISTRATION.BTN_CONFIRM' | translate }}</button>
      <button *ngIf="editMode !== ''" class="custom-btn rounded" [disabled]="editMode !== '' && editMode !== 'edited'" (click)="confirmAndEdit()">{{ 'REGISTRATION.BTN_CONFIRM_EDIT' | translate }}</button>
    </div>
  </div>

  <app-modal *ngIf="showUserPaymentModal" (closeClicked)="showUserPaymentModal = false">
    <div class="selfcheckin-box">

      <p>Hat der Kunde bezahlt?</p>

      <div class="button-wrap payment-dialog">
        <button class="custom-btn rounded" style="margin: auto"  (click)="showUserPaymentModal = false;">Nein</button>
        <button class="custom-btn rounded" *ngIf="editMode === ''" (click)="confirm(true)">Ja</button>
        <button class="custom-btn rounded" *ngIf="editMode !== ''" (click)="confirmAndEdit(true)">Ja</button>
      </div>
    </div>
  </app-modal>

  <app-modal *ngIf="showUserReasonModal" (closeClicked)="showUserReasonModal = false">
    <div class="selfcheckin-box">

      <p>Bitte Anspruch auf Test kontrollieren!</p>

      <h4>Grund:</h4>
      <p> {{ currentReasonTitle }}</p>

      <div class="button-wrap payment-dialog">
        <button class="custom-btn rounded" style="margin: auto"  (click)="showUserReasonModal = false;">Abbruch</button>
        <button class="custom-btn rounded" *ngIf="editMode === ''" (click)="confirm(true, true)">Bestätigen</button>
        <button class="custom-btn rounded" *ngIf="editMode !== ''" (click)="confirmAndEdit(true, true)">Bestätigen</button>
      </div>
    </div>
  </app-modal>

  <app-modal *ngIf="showDigitalSigningModal" (closeClicked)="cancelSigning()">
    <div class="selfcheckin-box">
      <h3>Digitale Unterschrift</h3>

      <ng-container *ngIf="signingStatus === 'NONE'">
        <p>Unterschriftsformular wird erstellt.... <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon></p>
        <h4>Grund:</h4>
        <p> {{ currentReasonTitle }}</p>
      </ng-container>

      <ng-container *ngIf="signingStatus === 'NEW'">
        <p>Warte auf Download durch iPad.... <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon></p>
        <h4>Grund:</h4>
        <p> {{ currentReasonTitle }}</p>
      </ng-container>

      <ng-container *ngIf="signingStatus === 'WAITING'">
        <p>Warte auf digitale Unterschrift... <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon></p>
        <h4>Grund:</h4>
        <p> {{ currentReasonTitle }}</p>
      </ng-container>

      <ng-container *ngIf="signingStatus === 'DONE'">
        <h4>Bitte prüfen:</h4>
        <img [src]="signingBlob" style="width: 100%;">
      </ng-container>

      <div class="button-wrap payment-dialog">
        <button class="custom-btn rounded" style="margin: auto" (click)="skipSigning()">Überspringen</button>
        <button *ngIf="signingStatus === 'DONE'" class="custom-btn rounded" style="margin: auto" (click)="saveSigning()">Unterschrift speichern</button>
        <button *ngIf="signingStatus !== 'DONE'" class="custom-btn rounded" (click)="resetSigning()">Unterschrift zurücksetzen</button>
      </div>
    </div>
  </app-modal>

  <app-modal *ngIf="showPrintPcrModal">
    <p><b>OEGD Schein</b> drucken?</p>
    <div class="button-wrap payment-dialog">
      <button class="custom-btn rounded" style="margin: auto" (click)="closeOEGDModal(false)">Abbruch</button>
      <button class="custom-btn rounded" (click)="closeOEGDModal(true)">Ja</button>
    </div>
  </app-modal>

  <!-- Third Party Consent Container -->

  <app-third-party-consent
    *ngIf="showThirdPartyConsentContainer"
    (finished)="confirmThirdPartyConsent()"
    [disabled]="!birth || !email"
    [useAppCwa]="useAppCwa"
    [useAppLuca]="useAppLuca"
    [orderId]="orderIDEntry.value"
    [testTypes]="testTypes"
  ></app-third-party-consent>

  <!-- TestKit Container -->

  <ng-container *ngIf="showTestkitContainer">

    <app-signature *ngIf="isCompanyUser && !consentGiven && !isWalkIn" (done)="addConfirmation($event)"></app-signature>

    <app-testkit-form
      *ngIf="consentGiven || !isCompanyUser || isWalkIn"
      [consecutiveOrderIds]="consecutiveOrderIds"
      [companyMode]="companyMode"
      [data]="testTypes"
      [testKitNo]="nextTestkitId"
      (allDone)="testsDone($event)"
      (setTestTypeTitle)="headerAddition = $event"
      (setErrorResponse)="$event ? showError($event) : errorMessage = ''"
      (setInstructionsInfoText)="userInstructionsText = $event"
      (changeVisibility)="changeVisibility(false, false, false, true)"
    ></app-testkit-form>
  </ng-container>

  <!-- Cancel Container -->

  <app-back-button *ngIf="showCancelButton && !companyMode" (click)="cancel()"></app-back-button>

  <div class="center" *ngIf="showCancelButton && !companyMode && cancelButtonText === 'REGISTRATION.FINISH'">
    <button [disabled]="cancelButtonIsDisabled" class="custom-btn rounded cancel-btn" (click)="cancel()">
      {{ cancelButtonText | translate }}
    </button>
  </div>

</div>
