import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ContactPerson } from '../models/contactperson';
import { Order } from '../models/order';

@Component({
  selector: 'app-tests-received',
  templateUrl: './tests-received.component.html',
  styleUrls: ['./tests-received.component.css']
})
export class TestsReceivedComponent implements OnInit {

  constructor(private userService: UserService) { }

  results: ReceivedResult[] | undefined;

  ngOnInit(): void {
    this.userService.getNotReceived().subscribe((response: { data: ReceivedResult[] }) => this.results = response.data);
  }
}

export interface ReceivedResult {
  first_received: string;
  id: string;
  last_received: string;
  location_id: string;
  order_id: string;
  received: string | boolean;
  test_date: string;
  test_type: string;
}
