<div #oegdSigning id="oegdsigning">
    <div #signaturePadInstance id="signaturePadInstance" [hidden]="!showSignature">
        <signature-pad id="signaturePadElement" [options]="signaturePadOptions" (onEndEvent)="onDrawComplete()"></signature-pad>
    </div>
</div>
<div id="oegdsigningfooter">
    <button class="custom-btn rounded signingButtons" (click)="resetSignature()">Zurücksetzen</button>
    <button [disabled]="!signatureDrawn || signatureDrawn == ''" class="custom-btn rounded signingButtons" (click)="sendSignature()">Absenden</button>
</div>

<ngx-spinner
  [name]="'signing-spinner'"
  bdColor="rgba(51,51,51,0.8)"
  size="large"
  color="#fff"
  type="ball-clip-rotate">
  <p style="margin-top: 80px; font-size: 20px; color: white">Warte auf Checkin...</p>
</ngx-spinner>

<ngx-spinner
  [name]="'signing-success-spinner'"
  bdColor="rgba(51,51,51,0.8)"
  size="large"
  color="#fff"
  type="ball-clip-rotate">
  <p style="margin-top: 80px; font-size: 20px; color: white">Vielen Dank für Ihre Unterschrift.</p>
  <p style="margin-top: 10px; font-size: 20px; color: white">Sie können das Tablet nun zurückgeben.</p>
</ngx-spinner>