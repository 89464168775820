<div class="selfcheckin-box">
  <div class="qr-code-container">
    <qr-code [value]="content"></qr-code>
  </div>
  <p *ngIf="uuid">Self-Checkin Link: <br><a class="selfcheck-link" target="_blank" href="{{ content }}">{{ content }}</a></p>
  <p *ngIf="orderId" class="order-id">{{ orderId }}</p>
  <div *ngIf="printLink" class="button-wrap">
    <button class="custom-btn rounded" style="margin: auto"
            (click)="print()">{{ "COMPANYCHECKIN.PRINT_QR" | translate }}</button>
    <button *ngIf="checkinLink" class="custom-btn rounded"
            routerLink="/checkin/{{orderId}}">{{ "COMPANYCHECKIN.CHECKIN_LINK" | translate }}</button>
  </div>
</div>
