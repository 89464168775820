<div class="wrapper">
  <h1>{{ 'ORDERMGMT.TITLE' | translate }}</h1>

  <!-- Error Message -->
  <app-alert *ngIf="errorMessage" [message]="errorMessage"></app-alert>

  <!-- Entry Container -->

  <div *ngIf="showEntryContainer">
    <div class="center">
      <p>{{ 'ORDERMGMT.TEXT_NUMBER_HEADER' | translate }}</p>
      <input #textInput type="text" id="textinput" class="rounded" (keyup.enter)="search('order')"
        [formControl]="orderIDEntry" placeholder="{{ 'ORDERMGMT.TEXT_ORDER_KIT_NUMBER' | translate }}" />
    </div>
    <div class="center">
      <button class="custom-btn rounded" (click)="search('order')">{{ 'ORDERMGMT.BTN_SEARCH_ORDER' | translate }}</button>
    </div>
     <div class="center">
       <button class="custom-btn rounded" (click)="search('testkit')">{{ 'ORDERMGMT.BTN_SEARCH_TEST' | translate }}</button>
     </div>
  </div>

  <!-- Info Container -->

  <div *ngIf="showResultContainer">
    <div class="center">
      <p class="description-text">{{ 'ORDERMGMT.TEXT_NUMBER' | translate }}</p>
      <h3>{{ orderID }}</h3>
    </div>

    <!-- List -->

    <div class="center">
      <ul class="header">
        <li>
          <span class="short">{{ 'ORDERMGMT.TABLE_STATUS' | translate }}</span>
          <span class="normal">{{ 'ORDERMGMT.TABLE_TESTID' | translate }}</span>
          <span class="normal">{{ 'ORDERMGMT.TABLE_TESTTYPE' | translate }}</span>
          <span class="normal">{{ 'ORDERMGMT.TABLE_TESTTIME' | translate }}</span>
          <span class="normal">{{ 'ORDERMGMT.TABLE_RESULT' | translate }}</span>
          <span class="normal">{{ 'ORDERMGMT.TABLE_FIRST' | translate }}</span>
          <span class="normal">{{ 'ORDERMGMT.TABLE_LAST' | translate }}</span>
          <span class="long">{{ 'ORDERMGMT.TABLE_EMAIL' | translate }}</span>
          <span class="normal">{{ 'ORDERMGMT.TABLE_TESTSUBMITTIME' | translate }}</span>
          <span class="short">{{ 'ORDERMGMT.TABLE_ACTIONS' | translate }}</span>
        </li>
      </ul>

      <div class="devider"></div>

      <ul class="list">
        <li *ngFor="let test of result">
          <span class="item short">
            {{ test.status }}
          </span>
          <span class="item normal">
            {{ test.test_id }}
          </span>
          <span class="item normal">
            {{ test.test_type }}
          </span>
          <span class="item normal">
            {{ test.encrypted_spi?.testtime ? test.encrypted_spi?.testtime : '--' }}
          </span>
          <span class="item normal">
            {{
              test.status == 'checkin' || test.status == 'open' || test.test_type == 'pcr-lab-free'  || test.test_type == 'pcr-lab-paid'
              ? '--'
              : test.encrypted_spi == ''
                ? '--'
                : (test.test_type == 'pcr-exp' || test.test_type == 'pcr-std')
                  ? test.encrypted_spi.testresult.result
                  : test.encrypted_spi.testresult
            }}
          </span>
          <span class="item normal">
            {{
              test.status == 'checkin' || test.status == 'open' || test.test_type == 'pcr-lab-free'  || test.test_type == 'pcr-lab-paid'
              ? test.testuser_pi.order_firstname
              : test.encrypted_spi == ''
                ? '--'
                : test.encrypted_spi.testuser_pi.order_firstname
            }}
          </span>
          <span class="item normal">
            {{
              test.status == 'checkin' || test.status == 'open' || test.test_type == 'pcr-lab-free'  || test.test_type == 'pcr-lab-paid'
              ? test.testuser_pi.order_lastname
              : test.encrypted_spi == ''
                ? '--'
                : test.encrypted_spi.testuser_pi.order_lastname
            }}
          </span>
          <span class="item long">
            <!-- <input type="text" class="rounded input-width" (keyup.enter)="editEmail($event)"
            value="{{ test.status == 'checkin' ? test.testuser_pi.email : test.encrypted_spi == '' ? '--' : test.encrypted_spi.testuser_pi.email }}" /> -->
            {{
              test.status == 'checkin' || test.status == 'open' || test.test_type == 'pcr-lab-free'  || test.test_type == 'pcr-lab-paid'
              ? test.testuser_pi.email
              : test.encrypted_spi == ''
                ? '--'
                : test.encrypted_spi.testuser_pi.email
            }}
          </span>
          <span class="item normal">
            {{ test.encrypted_spi?.testsubmittime ? test.encrypted_spi?.testsubmittime : '--' }}
          </span>
          <span class="item">
            <!-- <button class="cancel-btn rounded action-btn" >
              <fa-icon [icon]="faTrash"></fa-icon>
            </button> -->
            <button (click)="onAddKVOrder($event, test)" *ngIf="privateKeyUploaded" class="btn-secondary rounded action-btn btn-sm" >
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
              <div class="space"></div>
            <button (click)="onEditOrder($event, test)" *ngIf="privateKeyUploaded" class="btn-secondary rounded action-btn btn-sm" >
              <fa-icon [icon]="faEdit"></fa-icon>
            </button>
             <div class="space"></div>
            <button *ngIf="test.status == 'done' && test.encrypted_spi != ''" class="cancel-btn rounded action-btn"
              (click)="encrypt(test.order_id, test.test_type)">
              <fa-icon [icon]="faEncrypt"></fa-icon>
            </button>
        </span>
        </li>
      </ul>
    </div>

    <app-modal *ngIf="showEditOrderModal" (closeClicked)="showEditOrderModal = false">
      <app-order-edit [activeTest]="selectedOrder" [pk]="privateKey" (submitFinished)="orderEditFinished($event)"></app-order-edit>
    </app-modal>

    <app-modal *ngIf="showAddNewTestUserPCR" (closeClicked)="showAddNewTestUserPCR = false">
      <app-testuser-edit
        [testuser]="selectedPerson!"
        [headline]="'COMPANYADMIN.CREATE_NEW_PCR_TEST'"
        [lockTestType]="'pcr-lab-free'"
        [allowVerification]="false"
        [addMode]="true"
        [showOrderSelection]="true"
        (submitFinished)="addNewKVTestUserFinished($event!)">
      </app-testuser-edit>
    </app-modal>

    <div *ngIf="privateKeyUploaded" class="center">
      <p *ngIf="isCompanyOrder"><b>Zurücksetzen derzeit nicht für Firmentestungen verfügbar</b></p>
      <button class="cancel-btn rounded"
        (click)="resetTestuser()" [disabled]="isCompanyOrder">{{ 'ORDERMGMT.BTN_RESET_TESTUSER' | translate }}</button>
    </div>

    <div class="center">
      <button class="custom-btn rounded" (click)="back()">{{ 'ORDERMGMT.BTN_BACK' | translate }}</button>
    </div>

  </div>

  <!-- Reset Container -->
  <div *ngIf="showResetContainer">
    <div class="center">
      <p class="description-text">{{ 'ORDERMGMT.TEXT_RESET_SUCCESSFUL' | translate }}</p>
      <p class="description-text">{{ 'ORDERMGMT.TEXT_RESET_EMAIL' | translate }} <strong>{{ resetEmail }}</strong></p>
      <p class="description-text">{{ 'ORDERMGMT.TEXT_RESET_PW' | translate }} <strong>{{ resetPw }}</strong></p>
      <p class="description-text">{{ 'ORDERMGMT.TEXT_RESET_ORDERS' | translate }} <strong>{{ resetOrders }}</strong></p>
    </div>
    <div class="center">
      <button class="custom-btn rounded" (click)="back()">{{ 'ORDERMGMT.BTN_BACK' | translate }}</button>
    </div>
  </div>

  <!-- PrivKey Check -->
  <div *ngIf="!privateKeyUploaded" class="center">
    <p>{{ 'ORDERMGMT.BTN_ENCRYPT_NO_KEY' | translate }}</p>
  </div>

  <!-- Spinner -->

  <!-- @toDo: REMOVE SPINNER -->
  <!--  <div [hidden]="!showSpinner" class="center">-->
  <!--    <div class="spinner-border"></div>-->
  <!--  </div>-->
</div>
