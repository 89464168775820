import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const time = moment(value, 'hh:m:ss').format('HH:mm');

    if (time === 'Invalid date') {
      return value;
    }

    return time;
  }

}
