<div class="wrapper">
  <h1>{{ 'EDIT_TEST_TYPES.TITLE' | translate }}</h1>

  <app-alert *ngIf="successMessage" [message]="successMessage" type="success"></app-alert>
  <app-alert *ngIf="errorMessage" [error]="errorMessage" type="danger"></app-alert>

  <div class="center">

    <div class="row" *ngIf="locations.length">
      <div class="col-md-6">
        <h5>Test-Typ für Location</h5>
        <form [formGroup]="locationForm">
          <div class="f-row">
            <div class="f-col">
              <label class="form-label">Location</label>
              <select class="form-control" formControlName="id">
                <option [ngValue]="null" disabled selected>{{ 'COMPANYADMIN.CHOOSE_LABEL' | translate }}</option>
                <option *ngFor="let location of locations" [value]="location.location_id">{{ location!.name }}</option>
              </select>
            </div>
            <div class="f-col">
              <label class="form-label">Test-Typ</label>
              <select class="form-control" formControlName="sample_id">
                <option [ngValue]="null">standard (automatisch)</option>
                <option *ngFor="let type of sampleTestTypes" [value]="type.id">{{ type!.name }}</option>
              </select>
            </div>
          </div>
          <button type="submit" class="custom-btn rounded" (click)="submit(locationForm, 'location')">Speichern</button>
        </form>
      </div>
    </div>

    <br>

    <div class="row" *ngIf="companies.length">
      <div class="col-md-6">
        <h5>Test-Typ für Firmen / Shoppingcenter</h5>

        <form [formGroup]="companyForm">
          <div class="f-row">
            <div class="f-col">
              <label class="form-label">Firma / Shoppingcenter</label>
              <select class="form-control" formControlName="id">
                <option [ngValue]="null" disabled selected>{{ 'COMPANYADMIN.CHOOSE_LABEL' | translate }}</option>
                <option *ngFor="let company of companies" [value]="company.company_id">{{ company!.name }}</option>
              </select>
            </div>
            <div class="f-col">
              <label class="form-label">Test-Typ</label>
              <select class="form-control" formControlName="sample_id">
                <option [ngValue]="null">standard (automatisch)</option>
                <option *ngFor="let type of sampleTestTypes" [value]="type.id">{{ type!.name }}</option>
              </select>
            </div>
          </div>
          <button type="submit" class="custom-btn rounded" (click)="submit(companyForm, 'company')">Speichern</button>
        </form>
      </div>
    </div>

    <br>

    <div class="row" *ngIf="sampleTestTypes?.length">
      <div class="col-md-6">
        <h5>Test-Typen verwalten</h5>

        <form [formGroup]="sampleTypeForm">
          <label class="form-label">Test-Typ</label>
          <select class="form-control" formControlName="id">
            <option value="new" selected>Neu anlegen</option>
            <option *ngFor="let sampleType of sampleTestTypes" [value]="sampleType.id">{{ sampleType!.name }}</option>
          </select><br>
          <label class="form-label">Name* (wird nicht im Zertifikat angezeigt)</label>
          <input type="text" formControlName="name" class="rounded" placeholder=""><br><br>
          <label class="form-label">Test-Typ*</label>
          <input type="text" formControlName="type" class="rounded" placeholder=""><br><br>
          <label class="form-label">Beschreibung* </label>
          <textarea formControlName="description" class="rounded" placeholder=""></textarea><br><br>
          <label class="form-label">Test Id*</label>
          <input type="text" formControlName="test_id" class="rounded" placeholder=""><br><br>
          <label class="form-label">Device Id* (CWA Integration)</label>
          <input type="text" formControlName="device_id" class="rounded" placeholder=""><br><br>
          <button type="submit" class="custom-btn rounded" (click)="submitSampleTestTypeForm()">{{ sampleTestButtonLabel }}</button>
        </form>
      </div>
    </div>

  </div>
</div>
