import { Component, Inject, OnInit } from '@angular/core';
import { CompanyService } from '../../company.service';
import { Company } from '../../models/company';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { PERMISSIONS } from '../../roles';

@Component({
  selector: 'app-add-test-infopoint',
  templateUrl: './add-test-infopoint.component.html',
  styleUrls: ['./add-test-infopoint.component.scss']
})
export class AddTestInfopointComponent implements OnInit {

  public readonly P = PERMISSIONS;

  message = '';
  showOrderQRCode = false;
  orderId = '';
  company: Company = {};

  constructor(
    private companyService: CompanyService,
    private translate: TranslateService,
    private permissions: NgxPermissionsService,
    @Inject('Window') private window: Window
  ) { }

  ngOnInit(): void {
    this.company = this.companyService.currentCompany;
  }

  testAdded(data: any): void {
    this.orderId = data.order_id;
    this.translate.get('INFOPOINT.TEST_ADDED_SUCCESS').subscribe(text => this.message = text);
    this.showOrderQRCode = true;
  }
}
