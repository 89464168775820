<div class="wrapper">
  <h1>{{ 'MANAGE_USERS.TITLE' | translate }}</h1>
  <br><br>
  <div class="btn-container">
    <button class="custom-btn rounded add-btn" (click)="addModal = true">+</button>
  </div>
  <div class="center">
    <ul class="header">
      <li>
        <span class="normal" (click)="sortList('id', idLabel)" #idLabel>ID <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
        <span class="normal" (click)="sortList('username', usernameLabel)" #usernameLabel>Username <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
        <span class="normal" (click)="sortList('firstname', firstnameLabel)" #firstnameLabel>Vorname <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
        <span class="normal" (click)="sortList('lastname', lastnameLabel)" #lastnameLabel>Nachname <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
        <span class="normal" (click)="sortList('role_name', roleLabel)" #roleLabel>Rolle <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
        <span class="normal" (click)="sortList('company_name', companyLabel)" #companyLabel>Company <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
        <span class="normal" (click)="sortList('location', locationLabel)" #locationLabel>Location <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      </li>
    </ul>
    <div class="divider"></div>
    <ul class="list">
      <li *ngFor="let user of users" (click)="edit(user)">
        <span class="accent normal"> {{ user.id }} </span>
        <span class="item normal"> {{ user.username ? user.username : '-' }}</span>
        <span class="item normal"> {{ user.firstname ? user.firstname : '-' }}</span>
        <span class="item normal"> {{ user.lastname ? user.lastname : '-' }}</span>
        <span class="item normal"> {{ user.role_name ? user.role_name : '-' }}</span>
        <span class="item normal"> {{ user.company_name ? user.company_name : '-' }}</span>
        <span class="item normal"> {{ user.location_name ? user.location_name : '-' }}</span>
      </li>
    </ul>
  </div>
</div>

<app-modal *ngIf="editModal" (closeClicked)="editModal = false">
  <app-user-edit
    [locations]="locations"
    [user]="currentUser"
    [companies]="companies"
    (userDeleted)="delete($event)"
    (userUpdated)="updated($event)"
  ></app-user-edit>
</app-modal>

<app-modal *ngIf="addModal" (closeClicked)="addModal = false">
  <app-user-edit
    [locations]="locations"
    [editMode]="false"
    [companies]="companies"
    (userCreated)="created()"
  ></app-user-edit>
</app-modal>
