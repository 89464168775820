import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'testresult'
})
export class TestresultPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {

    const status = args[0]; // 0, 1, 2

    if (status === '0') {
      return '<div title="OPEN" class="testresult-indicator open"></div>';
    }
    if (status === '1') {
      return '<div title="CHECKED IN" class="testresult-indicator checkedin"></div>';
    }
    // if (!['positive', 'negative', 'invalid'].includes(value) && status === '2') {
    //   return '<div title="ERROR" class="testresult-indicator error">✖</div>';
    // }
    if (value === 'positive') {
      return '<div title="POSITIVE" class="testresult-indicator positive"></div>';
    }
    if (value === 'negative') {
      return '<div title="NEGATIVE"  class="testresult-indicator negative"></div>';
    }
    if (value === 'invalid') {
      return '<div title="INVALID" class="testresult-indicator invalid"></div>';
    }
    if (!value || value === '') {
      return '<span class="testresult-none">-</span>';
    }

    return value;
  }

}
