import {Component, Inject, OnInit} from '@angular/core';
import { UserService } from '../user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-edit-test-types',
  templateUrl: './edit-test-types.component.html',
  styleUrls: ['./edit-test-types.component.scss']
})
export class EditTestTypesComponent implements OnInit {

  successMessage = '';
  errorMessage = '';

  locationForm!: FormGroup;
  companyForm!: FormGroup;
  sampleTypeForm!: FormGroup;

  sampleTestButtonLabel = 'Neu anlegen';

  public locations: any = [];
  public companies: any = [];
  public sampleTestTypes: any = [];

  constructor(
    private apiService: UserService,
    private fb: FormBuilder,
    @Inject('Window') private window: Window
  ) {}

  ngOnInit(): void {

    forkJoin([
      this.apiService.sampleTestTypes(),
      this.apiService.getMappedSampleTestTypes(),
    ]).subscribe(responses => {
      this.sampleTestTypes = responses[0].data;
      this.locations = responses[1].data.locations;
      this.companies = responses[1].data.companies;

      this.locationForm = this.fb.group({
        id: [null, [Validators.required]],
        sample_id: [null, []],
      });

      this.companyForm = this.fb.group({
        id: [null, [Validators.required]],
        sample_id: [null, []],
      });

      this.sampleTypeForm = this.fb.group({
        id: ['new', [Validators.required]],
        name: ['', [Validators.required]],
        type: ['', [Validators.required]],
        description: ['', [Validators.required]],
        device_id: ['', [Validators.required]],
        test_id: ['', [Validators.required]],
      });

      this.locationForm.get('id')!.valueChanges.subscribe(value => {
        this.locationForm.get('sample_id')?.setValue(this.locations.find((item: any) => item.location_id === value)?.sample_id);
      });

      this.companyForm.get('id')!.valueChanges.subscribe(value => {
        this.companyForm.get('sample_id')?.setValue(this.companies.find((item: any) => item.company_id === value)?.sample_id);
      });

      this.sampleTypeForm.get('id')!.valueChanges.subscribe(value => {
        this.sampleTestButtonLabel = (value === 'new') ? 'Neu anlegen' : 'Änderung speichern';

        if (value !== 'new') {
          this.sampleTypeForm.get('name')?.setValue(this.sampleTestTypes.find((item: any) => item.id === value)?.name);
          this.sampleTypeForm.get('type')?.setValue(this.sampleTestTypes.find((item: any) => item.id === value)?.type);
          this.sampleTypeForm.get('description')?.setValue(this.sampleTestTypes.find((item: any) => item.id === value)?.description);
          this.sampleTypeForm.get('device_id')?.setValue(this.sampleTestTypes.find((item: any) => item.id === value)?.device_id);
          this.sampleTypeForm.get('test_id')?.setValue(this.sampleTestTypes.find((item: any) => item.id === value)?.test_id);
        } else {
          this.sampleTypeForm.reset({ id: 'new' }, { emitEvent: false });
        }
      });
    });
  }

  submitSampleTestTypeForm(): void {
    this.successMessage = '';
    this.errorMessage = '';

    const form = this.sampleTypeForm;

    if (!this.sampleTypeForm.valid) {
      return;
    }

    const id = form.get('id')?.value;
    const name = form.get('name')?.value;
    const type = form.get('type')?.value;
    const description = form.get('description')?.value;
    const deviceId = form.get('device_id')?.value;
    const testId = form.get('test_id')?.value;

    if (id === 'new') {
      this.apiService.addSampleTestType(name, type, description, deviceId, testId).subscribe(
        (response: any) => this.successMessage = 'Test-Typ gespeichert',
        (error: any) => this.errorMessage = error,
        () => this.apiService.sampleTestTypes().subscribe(res => this.afterFinishedSampleTestUpdate())
      );
    } else {
      this.apiService.updateSampleTestType(id, name, type, description, deviceId, testId).subscribe(
        (response: any) => this.successMessage = 'Test-Typ geändert',
        (error: any) => this.errorMessage = error,
        () => this.apiService.sampleTestTypes().subscribe(res => this.afterFinishedSampleTestUpdate())
      );
    }
  }

  afterFinishedSampleTestUpdate(): void {
    this.apiService.sampleTestTypes().subscribe(res => {
      this.sampleTestTypes = res.data;
      this.window.scroll(0, 0);
      this.sampleTypeForm.reset({ id: 'new' }, { emitEvent: false });
    });
  }

  submit(form: FormGroup, cat: string): void {

    this.successMessage = '';
    this.errorMessage = '';

    if (!form.valid) {
      return;
    }

    const id = form.get('id')?.value;
    const sampleTypeId = form.get('sample_id')?.value;

    this.apiService.updateSampleTestTypeMapping(id, sampleTypeId, cat).subscribe(
      response => {
        this.successMessage = 'Test-Typ gespeichert';
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

}

