<div class="center">
  <ul class="header">
    <li>
      <span class="normal" (click)="sortList('id', companyIdLabel)" #companyIdLabel>{{ 'COMPANY.COMPANY_ID' | translate }} <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      <span class="long" (click)="sortList('name', companyNameLabel)" #companyNameLabel>{{ 'COMPANY.COMPANY_NAME' | translate }} <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      <span class="long">{{ 'COMPANY.COMPANY_USERNAME' | translate }}</span>
      <span class="normal" *ngIf="!hideActions">{{ 'ORDERMGMT.TABLE_ACTIONS' | translate }}</span>
    </li>
  </ul>

  <div class="divider"></div>

  <ul class="list">
    <li *ngFor="let company of filterCompanies(companies)" (click)="onSelectCompany($event, company)">
      <span class="accent normal">{{company.id}}</span>
      <span class="item long">{{company.name}}</span>
      <span class="item long">{{company.contact?.username}}</span>
      <span class="item normal actions" *ngIf="!hideActions">
        <fa-icon *ngIf="showArchiveAction" (click)="onArchiveCompany($event, company)" [icon]="faFolderOpen" title="ARCHIVE" [class.unavailable]="!company.can_archive"></fa-icon>
        <fa-icon *ngIf="showUnArchiveAction" (click)="onUnArchiveCompany($event, company)" [icon]="faFolder" title="UN-ARCHIVE" [class.unavailable]="!company.can_archive"></fa-icon>
        <fa-icon (click)="onEditCompany($event, company)" [icon]="faEdit" title="EDIT"></fa-icon>
        <fa-icon (click)="onDeleteCompany($event, company)" [icon]="faTrash" [class.unavailable]="company?.orders?.length! > 0"></fa-icon>
      </span>
    </li>
  </ul>
</div>

<app-modal *ngIf="showEditCompanyModal" (closeClicked)="showEditCompanyModal = false">
  <app-company-edit [company]="selectedCompany" (submitFinished)="companyEditFinished($event)"></app-company-edit>
</app-modal>
