import * as FileSaver from 'file-saver';

export const mapSexToSalutation = (value: string) => {
  if (!value) {
    return '';
  }
  switch (value.toLowerCase()) {
    case 'm':
      return 'mr';
    case 'w':
      return 'ms';
    case null:
      return '';
    case undefined:
      return '';
    default:
      return value;
  }
};

export const isValidEmail = (value: string) => {
  const EMAIL_REGEXP = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return EMAIL_REGEXP.test(value);
};

export const downloadFile = (data: any, filename = 'file', type = 'pdf') => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {type: 'application/' + type});
  FileSaver.saveAs(blob, filename + '.' + type);
};
