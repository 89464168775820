import { Component, ElementRef, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { CompanyService } from '../../company.service';
import { forkJoin } from 'rxjs';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Company } from '../../models/company';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-usermgmt',
  templateUrl: './usermgmt.component.html',
  styleUrls: ['./usermgmt.component.scss']
})
export class UsermgmtComponent implements OnInit {

  users: any = [];
  locations = [];
  companies = [];
  editModal = false;
  addModal = false;
  currentUser: any = null;

  private currentOrder: any = null;
  private element: ElementRef;

  public faChevronUp: IconDefinition = faChevronUp;

  sortState = {
    id: true,
    username: true,
  };

  constructor(elem: ElementRef, private userService: UserService, private companyService: CompanyService) {
    this.element = elem;
  }

  ngOnInit(): void {

    forkJoin([
      this.companyService.getAllCompanies(),
      this.userService.getAllLocations(),
      this.userService.getAllUsersAdmin()
    ]).subscribe(responses => {
      this.companies = responses[0].data;
      this.locations = responses[1].data;
      this.users = responses[2].data.map((user: any) => {
        user.id = parseInt(user.id, 10);
        return user;
      });
    });
  }

  edit(user: any): void {
    this.currentUser = user;
    this.editModal = true;
  }

  delete(deletedUser: any): void {
    this.users = this.users.filter((user: any) => user.id !== deletedUser.id);
    this.editModal = false;
  }

  updated(updatedUser: any): void {
    location.reload();
    this.editModal = false;
  }

  created(): void {
    location.reload();
  }

  public sortList(key: any, elem: HTMLSpanElement): void {
    this.element.nativeElement.querySelectorAll('.header span').forEach((element: Element) => element.classList.remove('up', 'down', 'active'));
    this.currentOrder = { key, elem };
    // @ts-ignore
    const compare = this.sortState[key] ? 1 : -1;
    elem.classList.add(compare < 1 ? 'up' : 'down', 'active');
    this.users?.sort((a: any, b: any) => {

      if (['firstname', 'lastname', 'username', 'company_name', 'role'].includes(key)) {

        const aCopy = { [key]: a[key] ?? ''};
        aCopy[key] = aCopy[key].toLowerCase();

        const bCopy = { [key]: b[key] ?? ''};
        bCopy[key] = bCopy[key].toLowerCase();

        if (aCopy[key]! < bCopy[key]!) { return compare; }
        if (aCopy[key]! > bCopy[key]!) { return -compare; }
        return 0;

      } else {
        if (a[key]! < b[key]!) { return compare; }
        if (a[key]! > b[key]!) { return -compare; }
        return 0;
      }

    });
    // @ts-ignore
    this.sortState[key] = !this.sortState[key];
  }

}
