<app-alert *ngIf="errorMessage" [message]="errorMessage" [type]="'danger'"></app-alert>

<form class="row g-3" [formGroup]="form">
  <div class="col-md-12">
    <label class="form-label">{{ 'COMPANY.COMPANY_NAME' | translate }}</label>
    <input type="text" class="form-control" required formControlName="name">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANY.COMPANY_OEGD_NAME' | translate }}</label>
    <input type="text" class="form-control" formControlName="name_oegd">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANY.COMPANY_TID' | translate }}</label>
    <input type="text" class="form-control" formControlName="testcenter_id">
  </div>
  <div class="col-md-12">
    <label class="form-label">{{ 'COMPANY.COMPANY_STREET' | translate }}</label>
    <input type="text" class="form-control" formControlName="street">
  </div>
  <div class="col-md-3">
    <label class="form-label">{{ 'COMPANY.COMPANY_POSTCODE' | translate }}</label>
    <input type="text" class="form-control" formControlName="postalCode">
  </div>
  <div class="col-md-9">
    <label class="form-label">{{ 'COMPANY.COMPANY_CITY' | translate }}</label>
    <input type="text" class="form-control" formControlName="city">
  </div>
  <div class="col-md-12">
    <label class="form-label">{{ 'COMPANY.COMPANY_DESCRIPTION' | translate }}</label>
    <textarea class="form-control" formControlName="description" maxlength="21000" style="width:100%; height:200px;"></textarea>
  </div>
  <div class="col-md-12">
    <label class="center">
      <input type="checkbox" class="" formControlName="isWalkIn">
      <span>{{ 'COMPANY.IS_WALK_IN_COMPANY' | translate }}</span>
    </label>
  </div>
  <div class="col-md-12">
    <label class="center">
      <input type="checkbox" class="" formControlName="multipleOrderUseAllowed">
      <span>{{ 'COMPANY.MULTIPLE_ORDER_USE_ALLOWED' | translate }}</span>
    </label>
  </div>

  <div class="col-12">
    <button [disabled]="!form.valid" (click)="save()" class="custom-btn rounded">{{ 'COMPANYCHECKIN.BTN_SUBMIT' | translate }}</button>
  </div>
</form>
