<app-alert *ngIf="errorMessage" [message]="errorMessage" [type]="'danger'"></app-alert>

<form class="row g-3" [formGroup]="form">
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYADMIN.EMPLOYEE_SEX' | translate }}</label>
     <select class="form-control" formControlName="salutation">
      <option [ngValue]="null" disabled selected>{{ 'COMPANYADMIN.CHOOSE_LABEL' | translate }}</option>
      <option value="ms">weiblich</option>
      <option value="mr">männlich</option>
      <option value="d">divers</option>
    </select>
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_IDCARDNO' | translate }}</label>
    <input type="text" class="form-control" formControlName="idcardno">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_FIRST' | translate }}</label>
    <input type="text" class="form-control" required formControlName="firstname">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_LAST' | translate }}</label>
    <input type="text" class="form-control" required formControlName="lastname">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_BIRTH' | translate }}</label>
    <input type="text" class="form-control" placeholder="DD.MM.YYYY" [ngClass]="{'border-danger' : formErrors.additional_address_line1}" formControlName="additional_address_line1">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_EMAIL' | translate }}</label>
    <input type="text" class="form-control" formControlName="email">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_PHONE' | translate }}</label>
    <input type="text" class="form-control" formControlName="phone">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_ADDRESS' | translate }}</label>
    <input type="text" class="form-control" formControlName="street">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_ZIP' | translate }}</label>
    <input type="text" class="form-control" pattern="[0-9]{4,6}" formControlName="zipcode">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_CITY' | translate }}</label>
    <input type="text" class="form-control" formControlName="city">
  </div>

  <div class="col-md-6">
    <label class="form-label">{{ 'ORDERMGMT.TABLE_TESTTYPE' | translate }}</label>
    <input type="text" class="form-control" readonly formControlName="type">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'ORDERMGMT.TABLE_RESULT' | translate }}</label>
    <input type="text" class="form-control" readonly formControlName="result">
  </div>

  <div class="col-12">
    <button [disabled]="!form.valid" style="width:100%;" (click)="save()" class="custom-btn rounded">{{ 'COMPANYCHECKIN.BTN_SUBMIT' | translate }}</button>
  </div>

  <div [hidden]="!showResultEditButton" class="col-12" style="margin-top:60px;">
    <button [disabled]="!form.valid" style="width:100%;" (click)="showResultEditor()" class="custom-btn rounded">{{ 'ORDERMGMT.BTN_EDIT_RESULT' | translate }}</button>
  </div>

  <app-alert class="col-12" *ngIf="errorMessageResult" [message]="errorMessageResult" [type]="'danger'"></app-alert>
  <!-- Result Container ANTIGEN -->
  <div class="col-12" *ngIf="type.includes('antigen')">
    <div [hidden]="!showResultContainer">
      <div class="center">
        <p style="margin-top:20px;">{{ 'TESTRESULT.TEXT_RESULT_HEADER_WITHOUT_TESTKIT' | translate }}</p>

        <button class="result-btn rounded" style="width:100%; margin-top:0px;"
          (click)="resultSubmit('positive')">{{ 'TESTRESULT.POSITIV' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded" style="width:100%; margin-top:0;"
          (click)="resultSubmit('invalid')">{{ 'TESTRESULT.INVALID' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded" style="width:100%; margin-top:0;"
          (click)="resultSubmit('negative')">{{ 'TESTRESULT.NEGATIV' | translate }}</button>
      </div>
    </div>


    <div [hidden]="!showResultCheckContainer">
      <div class="devider" style="width:100%;"></div>
      <div class="center">
        <p>{{ 'TESTRESULT.TEXT_RESULT_REPEAT' | translate }}</p>

        <button class="result-btn rounded" style="width:100%; margin-top:0;"
          (click)="resultCheckSubmit('positive')">{{ 'TESTRESULT.POSITIV' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded" style="width:100%; margin-top:0;"
          (click)="resultCheckSubmit('invalid')">{{ 'TESTRESULT.INVALID' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded" style="width:100%; margin-top:0;"
          (click)="resultCheckSubmit('negative')">{{ 'TESTRESULT.NEGATIV' | translate }}</button>
      </div>
    </div>
  </div>

  <!-- Result Container ANTIBODY -->
  <div class="col-12" *ngIf="type === 'antibody'">
    <div [hidden]="!showResultContainer">
      <div class="center">
        <p style="margin-top:20px;">{{ 'TESTRESULT.TEXT_RESULT_HEADER_WITHOUT_TESTKIT' | translate }}</p>

        <button class="result-btn rounded" style="width:100%; margin-top:0px;"
          (click)="resultSubmit('negative')">{{ 'TESTRESULT.NEGATIV' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded" style="width:100%; margin-top:0px;"
          (click)="resultSubmit('invalid')">{{ 'TESTRESULT.INVALID' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded" style="width:100%; margin-top:0px;"
          (click)="resultSubmit('IgG')">{{ 'TESTRESULT.POSITIV_IGG' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded" style="width:100%; margin-top:0px;"
          (click)="resultSubmit('IgM')">{{ 'TESTRESULT.POSITIV_IGM' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded" style="width:100%; margin-top:0px;"
          (click)="resultSubmit('IgG+IgM')">{{ 'TESTRESULT.POSITIV_BOTH' | translate }}</button>
      </div>
    </div>

    <div [hidden]="!showResultCheckContainer">
      <div class="devider" style="width:100%;"></div>
      <div class="center">
        <p>{{ 'TESTRESULT.TEXT_RESULT_REPEAT' | translate }}</p>

        <button class="result-btn rounded" style="width:100%; margin-top:0px;"
          (click)="resultCheckSubmit('negative')">{{ 'TESTRESULT.NEGATIV' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded" style="width:100%; margin-top:0px;"
          (click)="resultCheckSubmit('invalid')">{{ 'TESTRESULT.INVALID' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded" style="width:100%; margin-top:0px;"
          (click)="resultCheckSubmit('IgG')">{{ 'TESTRESULT.POSITIV_IGG' | translate }}</button>
      </div>
       <div class="center">
         <button class="result-btn rounded" style="width:100%; margin-top:0px;"
           (click)="resultCheckSubmit('IgM')">{{ 'TESTRESULT.POSITIV_IGM' | translate }}</button>
       </div>
      <div class="center">
        <button class="result-btn rounded" style="width:100%; margin-top:0px;"
          (click)="resultCheckSubmit('IgG+IgM')">{{ 'TESTRESULT.POSITIV_BOTH' | translate }}</button>
      </div>
    </div>
  </div>

</form>
