import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cwa-vcard-import',
  templateUrl: './cwa-vcard-import.component.html',
  styleUrls: ['./cwa-vcard-import.component.css']
})
export class CWAvCardImportComponent implements OnInit {

  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild('vcardinput') vcardinput!: ElementRef;

  form!: FormGroup;
  errorMessage = '';

  importVO: VCardImportVO = {
    'firstname': '',
    'lastname': '',
    'birthday': '',
    'email': '',
    'phone': '',
    'address': '',
    'zip': '',
    'city': ''
  };

  constructor(private fb: FormBuilder, private translate: TranslateService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      vcarddata: ['', [Validators.required]]
    });

    setTimeout(() => {
      this.vcardinput.nativeElement.focus();
    }, 1);
  }

  inputChanged(inVal: string):void {
    if (inVal.substr(0,11) == 'BEGIN:VCARD' && inVal.substr(-9,9) == 'END:VCARD') {
      this.errorMessage = '';
      let tmpArr:string[] = inVal.split('\n');
      tmpArr.forEach((val) => {
        let splitArr = val.split(':');
        if (splitArr.length == 2) {
          switch (splitArr[0]) {
            case 'N':
              let valSplit = splitArr[1].split(';');
              if (valSplit.length > 0 && valSplit[0].trim() != '') this.importVO.lastname = valSplit[0]; 
              if (valSplit.length > 1 && valSplit[1].trim() != '') this.importVO.firstname = valSplit[1];
              break;
            case 'BDAY':
              let bday:string = splitArr[1];
              this.importVO.birthday = bday.substr(6,2)+'.'+bday.substr(4,2)+'.'+bday.substr(0,4); 
              break;
            default:
              let keyArr:string[] = splitArr[0].split(/\b(?:;type|;TYPE)\b/);
              if (keyArr.length > 0) {
                switch (keyArr[0]) {
                  case 'EMAIL':
                    this.importVO.email = splitArr[1];
                    break;
                  case 'TEL':
                    this.importVO.phone = splitArr[1];
                    break;
                  case 'ADR':
                    let valSplit = splitArr[1].split(';');
                    if (valSplit.length > 2) this.importVO.address = valSplit[2];
                    if (valSplit.length > 3) this.importVO.city = valSplit[3];
                    if (valSplit.length > 5) this.importVO.zip = valSplit[5];
                    break;
                }
              }
          }
        }
      }); 
      this.submitFinished.emit(this.importVO);
    } else {
      this.form.controls['vcarddata'].setErrors({'incorrect': true});
      if (inVal.trim() == '') this.errorMessage = '';
      else this.translate.get('COMPANYCHECKIN.INVALID_CWA_VCARD_DATA').subscribe((text: string) => { this.errorMessage = text; });
    } 
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    this.submitFinished.emit(this.importVO);
  }
}


interface VCardImportVO {
  firstname: string;
  lastname: string;
  birthday: string;
  email: string;
  phone: string;
  address: string;
  zip: string;
  city: string;
}