import { Component, OnInit } from '@angular/core';
import { NgxRolesService } from 'ngx-permissions';
import { PERMISSIONS_BY_ROLES, USER_ROLES } from './roles';
import { AuthenticationService } from './authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'mcs-intern-frontend';

  constructor(private authenticationService: AuthenticationService, private rolesService: NgxRolesService) {}

  ngOnInit(): void {
    this.setUserRole(this.authenticationService.getLoggedInUser().role);
  }

  public setUserRole(role: USER_ROLES): void {
    this.rolesService.addRoleWithPermissions(role, PERMISSIONS_BY_ROLES[role] as string[]);
  }
}
