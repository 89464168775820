import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../authentication.service';
import { UserService } from '../user.service';
import { faKey, faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import {Person} from '../models/person';


@Component({
  selector: 'app-ordermgmt',
  templateUrl: './ordermgmt.component.html',
  styleUrls: ['./ordermgmt.component.css']
})
export class OrdermgmtComponent implements OnInit {
  faTrash = faTrashAlt;
  faEdit = faEdit;
  faEncrypt = faKey;
  faPlus = faPlus;

  public selectedOrder: {} | undefined;
  public showEditOrderModal = false;

  @ViewChild('textInput') textInput!: ElementRef;

  showEntryContainer = true;
  showResultContainer = false;
  showResetContainer = false;
  showAddNewTestUserPCR = false;
  selectedPerson: Person | null = null;

  isCompanyOrder = false;

  orderIDEntry = new FormControl();
  orderID = '';

  privateKeyUploaded = false;

  errorMessage = '';
  privateKey = '';
  result: any[] = [];

  resetEmail = '';
  resetPw = '';
  resetOrders = '';

  isTestkit = false;

  constructor(private userService: UserService, private translate: TranslateService, private authenticationService: AuthenticationService) {
    this.privateKeyUploaded = this.authenticationService.getPrivateKey() !== '' ? true : false;
    if (this.privateKeyUploaded) { this.privateKey = this.authenticationService.getPrivateKey(); }
  }

  ngOnInit(): void {
    this.reset();
  }

  search(orderOrTestkit: string): void {

    this.isTestkit = orderOrTestkit === 'testkit';

    // check if valid
    if (!this.isTestkit && (!this.orderIDEntry.value.match('^[0-9]{5,9}$') || this.orderIDEntry.value === '')) {
      this.showError(Errors.ValidationOrder);
      return;

    } else if (this.isTestkit && (!this.orderIDEntry.value.match('^[A-Z0-9]{8,12}$') || this.orderIDEntry.value === '')) {
      this.showError(Errors.ValidationTestkit);
      return;

    } else {
      this.errorMessage = '';
    }

    this.changeVisibility(true);
    this.getOrderInfo(this.isTestkit);
  }

  encrypt(orderID: string, testType: string): void {
    this.userService.reencryptTest(orderID, this.privateKey, testType)
      .subscribe(
        response => {
          this.errorMessage = '';
          alert('Bestellung wurde erfolgreich neu verschlüsselt.');
        },
        errorResponse => {
          this.showError(Errors.Errorcode, errorResponse.message);
        }
      );
  }

  back(): void {
    this.reset();
  }

  private getOrderInfo(isTestID: boolean): void {
    this.userService.getTeststatus(this.orderIDEntry.value, isTestID, this.privateKey)
      .subscribe(
        response => {
          this.errorMessage = '';
          this.result = response.data;
          if (!isTestID){
            this.orderID = this.orderIDEntry.value;
          } else {
            this.orderID = this.result.length > 0 ? this.result[0].order_id : 'not found';
          }

          this.isCompanyOrder = this.result[0].company_order;

          this.changeVisibility(false, true);
        },
        error => {
          this.showError(error.status, error.message);
        }
      );
  }

  resetTestuser(): void {

    this.changeVisibility(true);
    this.userService.resetTestuser(this.orderID, this.privateKey)
      .subscribe(
        response => {
          this.errorMessage = '';
          this.resetEmail = response.data.email;
          this.resetPw = response.data.password;
          this.resetOrders = response.data.reencrypted_orders.join(', ');
          this.changeVisibility(false, false, true);
        },
        error => {
          this.showError(Errors.Errorcode, error.message);
        }
      );
  }

  editEmail(event: any): void {
    // TODO: change email BE call here
    event.target.blur();
  }

  private reset(): void {
    this.changeVisibility(true);
    this.orderIDEntry.setValue('');
    this.orderID = '';
    this.resetEmail = '';
    this.resetPw = '';
    this.resetOrders = '';
    this.result = [];
    setTimeout(() => {
      this.textInput.nativeElement.focus();
    }, 0);
  }

  private changeVisibility(entry = false, result = false, reset = false): void {
    this.showEntryContainer = entry;
    this.showResultContainer = result;
    this.showResetContainer = reset;
  }

  private showError(type: Errors, message: any = null): void {
    switch (type) {
      case Errors.Errorcode:
        if (message != null) {
          this.errorMessage = message!;
        }
        break;

      case Errors.ValidationOrder:
        this.translate.get('ORDERMGMT.TEXT_ERROR_VALIDATION_ORDER').subscribe((text: string) => {
          this.errorMessage = text;
        });
        break;

      case Errors.ValidationTestkit:
        this.translate.get('ORDERMGMT.TEXT_ERROR_VALIDATION_TESTKIT').subscribe((text: string) => {
          this.errorMessage = text;
        });
        break;

      case Errors.NotFound:
        this.translate.get('ORDERMGMT.NOT_FOUND').subscribe((text: string) => {
          this.errorMessage = text;
        });
        break;

      case Errors.Gone:
        this.translate.get('ORDERMGMT.TESTRESULT_GONE').subscribe((text: string) => {
          this.errorMessage = text;
        });
        break;

      default:
        if (message) {
          this.errorMessage = message;
        } else {
          this.translate.get('ORDERMGMT.GENERIC_ERROR').subscribe((text: string) => {
            this.errorMessage = text;
          });
        }

    }
  }

  public onEditOrder(event: Event, order: any): void {
    event.stopPropagation();
    this.selectedOrder = order;
    this.showEditOrderModal = true;
  }

  public onAddKVOrder(event: Event, order: any): void {
    event.stopPropagation();

    const userSpi = order.status === 'done' ? order.encrypted_spi.testuser_pi : order.testuser_pi;

    this.selectedPerson = {
      firstname: userSpi.order_firstname,
      lastname: userSpi.order_lastname,
      birthday: userSpi.additional_address_line1,
      email: userSpi.email,
      phone: userSpi.phone,
      test_type: null,
      country: userSpi.country === 'GERMANY' ? 'Deutschland' : (userSpi.country ?? 'Deutschland'),
      zip: userSpi.zipcode,
      city: userSpi.city,
      address: userSpi.street,
      idcardno: userSpi.idcardno,
      test_location: '',
      test_time: '',
      sex: userSpi.salutation,
      job_function: '',
      company_id: order.company_id,
      company_order_id: order.company_order_id,
    };
    this.showAddNewTestUserPCR = true;
  }

  public addNewKVTestUserFinished(item: any): void {
    this.showAddNewTestUserPCR = false;
    setTimeout(() => alert('KV Test wurde angelegt'));
  }

  public orderEditFinished(valArr: any): void {
    const updatedVO = valArr[0];
    const encFlag = valArr[1];
    const testId = valArr[2];

    const editedResultIndex = this.result.findIndex(item => item.test_id === testId);

    if (encFlag) {
      this.result[editedResultIndex].encrypted_spi.testuser_pi.email = updatedVO.email;
      this.result[editedResultIndex].encrypted_spi.testuser_pi.salutation = updatedVO.salutation;
      this.result[editedResultIndex].encrypted_spi.testuser_pi.order_lastname = updatedVO.order_lastname;
      this.result[editedResultIndex].encrypted_spi.testuser_pi.order_firstname = updatedVO.order_firstname;
      this.result[editedResultIndex].encrypted_spi.testuser_pi.idcardno = updatedVO.idcardno;
      this.result[editedResultIndex].encrypted_spi.testuser_pi.phone = updatedVO.phone;
      this.result[editedResultIndex].encrypted_spi.testuser_pi.street = updatedVO.street;
      this.result[editedResultIndex].encrypted_spi.testuser_pi.zipcode = updatedVO.zipcode;
      this.result[editedResultIndex].encrypted_spi.testuser_pi.city = updatedVO.city;
      this.result[editedResultIndex].encrypted_spi.testuser_pi.additional_address_line1 = updatedVO.additional_address_line1;
      this.result[editedResultIndex].encrypted_spi.testresult = updatedVO.result;
    } else {
      this.result[editedResultIndex].testuser_pi.email = updatedVO.email;
      this.result[editedResultIndex].testuser_pi.salutation = updatedVO.salutation;
      this.result[editedResultIndex].testuser_pi.order_firstname = updatedVO.order_firstname;
      this.result[editedResultIndex].testuser_pi.order_lastname = updatedVO.order_lastname;
      this.result[editedResultIndex].testuser_pi.idcardno = updatedVO.idcardno;
      this.result[editedResultIndex].testuser_pi.phone = updatedVO.phone;
      this.result[editedResultIndex].testuser_pi.street = updatedVO.street;
      this.result[editedResultIndex].testuser_pi.zipcode = updatedVO.zipcode;
      this.result[editedResultIndex].testuser_pi.city = updatedVO.city;
      this.result[editedResultIndex].testuser_pi.additional_address_line1 = updatedVO.additional_address_line1;
    }

    this.showEditOrderModal = false;
  }
}

enum Errors {
  Errorcode,
  ValidationOrder,
  ValidationTestkit,
  NotFound = 404,
  Gone = 410
}
