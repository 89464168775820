<div class="wrapper">
  <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
    <span class="navbar-brand">[MCS Intern] v{{appVersion}}</span>
  </nav>

  <div class="d-flex justify-content-center mt-5">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'LOGIN.TITLE' | translate }}</h3>
      </div>
      <div class="card-body">
        <div *ngIf="error" class="alert alert-danger" role="alert">{{ error }}</div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="input-group form-group">
            <div class="input-group-prepend">
              <span class="input-group-text bg-dark"><fa-icon [icon]="faUser"></fa-icon></span>
            </div>
            <input type="text" formControlName="username" class="form-control" placeholder="{{ 'LOGIN.USERNAME' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
          </div>
          <div class="input-group form-group">
            <div class="input-group-prepend">
              <span class="input-group-text bg-dark"><fa-icon [icon]="faKey"></fa-icon></span>
            </div>
            <input
              type="password"
              formControlName="password"
              class="form-control"
              placeholder="{{ 'LOGIN.PASSWORD' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
          </div>
          <div class="form-group">
            <button class="btn float-right login_btn bg-dark">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
