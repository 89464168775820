import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'testTypeLabel'
})
export class TestTypeLabelPipe implements PipeTransform {

  private readonly langPrefix = 'TEST_TYPES';

  constructor(private translate: TranslateService) {}

  transform(value: string, ...args: unknown[]): Observable<string> {
    if (!value) {
      return of(value);
    }
    value = this.langPrefix + '.' + value.toUpperCase();
    return this.translate.get(value);
  }

}
