import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { mcsApiVersion } from './../../package.json';

@Injectable({
  providedIn: 'root'
})
export class VersionInterceptorService implements HttpInterceptor {
  private mcsApiVersion = mcsApiVersion;

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const req = this.addMCSApiVersionHeader(request);

    return next.handle(req);
  }

  private addMCSApiVersionHeader(request: HttpRequest<any>): HttpRequest<any> {
    const req = request.clone({
      setHeaders: {
        "mcs-api-version": this.mcsApiVersion
      }
    });
    return req;
  }
}
