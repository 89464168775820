import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from '../user.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';
import { CompanyService } from '../company.service';
import { Person } from '../models/person';
import { PERMISSIONS } from '../roles';
import {downloadFile} from '../helper';
import { SigningService } from '../signing.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.scss']
})
export class CheckinComponent implements OnInit {

  faSpinner = faSpinner;

  readonly COMPANY_ORDER_ID_THRESHOLD = 9000000;
  public readonly P = PERMISSIONS;

  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateUser: EventEmitter<any> = new EventEmitter<Person>();

  @ViewChild('orderInput') orderInput!: ElementRef;

  orderIDEntry = new FormControl();

  formControlEmail = new FormControl();
  formControlZip = new FormControl();
  formControlSearchLastname = new FormControl();
  serverErrorMessage = '';

  searchResults: any[] = [];

  showPrintPcrModal = false;
  showReasonSelect = false;
  showEntryContainer = true;
  showValidationError = false;
  showOverviewContainer = false;
  showTestkitContainer = false;
  showSummaryContainer = false;
  showThirdPartyConsentContainer = false;
  companyMode = true;
  prices = [];
  originalTestType = '';
  originalPrice = '';
  editMode = '';

  header = '';
  headerAddition = '';
  description = '';
  cancelButtonText = '';
  cancelButtonIsDisabled = false;
  userInstructionsText = '';
  idcardno = '';

  isCompanyUser = false;
  userId = '';
  consentGiven = true;
  firstname = '';
  lastname = '';
  email = '';
  phone = '';
  birth: any = undefined;
  birthString = '';
  street = '';
  zip = '';
  city = '';
  testTypes: TestType[] = [];
  payment = '';
  price = '';
  reason: string | null = '';
  originalReason = '';
  showCancelButton = false;
  nextTestkitId = '';
  companyTestUserId = '';
  isScUser = false;
  useAppCwa = false;
  useAppLuca = false;
  testtype: TestType & any = '';
  showUserPaymentModal = false;
  showUserReasonModal = false;
  showDigitalSigningModal = false;
  digitalSigningAvailable = false;
  hideAntigenSW = true;
  multipleOrderUse = false;
  lastTestKitId = '';
  reasons: any = [];
  errorMessage = '';

  signingBlob = "";
  signingStatus = "NONE";
  signingTimeout : any = null;

  @Input() consecutiveOrderIds = true;
  @Input() isWalkIn = false;
  @Input() modalMode = false;

  @Input() set orderId(id: string) {
    this.userService.getAccountInfoForOrder(id)
      .pipe(
        map(res => res.data)
      ).subscribe((data: AccountInformation) => {
      if (data) {
        this.orderIDEntry.setValue(id);
        this.fillSearchResult(data);
        this.companyMode = true;
      }
    }, errorResponse => this.showError(errorResponse));
  }

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private signingService: SigningService,
    private cdRef: ChangeDetectorRef,
    private authService: AuthenticationService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    ) {
    this.isScUser = this.authService.isLocationShoppingcenter();
    this.userService.getTestReasons().subscribe(response => {
      this.reasons = response.data;
    });
    this.signingService.isDigitalSigningAvailable().subscribe(response => {
      this.digitalSigningAvailable = true;
    },(error) => {
      this.digitalSigningAvailable = false;
    });
  }

  get currentReasonTitle(): string {
    return this.reasons.find((r: any) => r.label === this.reason)?.title;
  }

  ngOnInit(): void {
    this.reset();
    this.route.params.subscribe(params => {
      if (params.testOrderId) {
        this.orderId = params.testOrderId;
      }
    });
  }

  ngOnDestroy(): void {
    if(this.signingTimeout !== null) clearTimeout(this.signingTimeout);
  }

  testsDone(lastTestKitId: string): void {
    const isPcrLab = (this.testTypes.find(type => type.testType === TestTypes.PCR_LAB_FREE || type.testType === TestTypes.PCR_LAB_PAID));
    this.lastTestKitId = lastTestKitId;

    if (isPcrLab) {
      this.showPrintPcrModal = true;
    }

    if (this.companyMode && !isPcrLab) {
      this.submitFinished.emit(lastTestKitId);
      this.lastTestKitId = '';
    }
  }

  closeOEGDModal(print = false): void {
    if (print) {
      this.userService.downloadOEGD(this.orderIDEntry.value).subscribe(res => {
        downloadFile(res.body.data, 'OEGD', 'pdf');
      });
    }
    if (this.companyMode) {
      this.submitFinished.emit(this.lastTestKitId);
      this.lastTestKitId = '';
    }
    this.showPrintPcrModal = false;
  }

  onClickResult(id: any): void {
    this.formControlSearchLastname.reset();
    this.orderIDEntry.setValue(id);
    this.search();
  }

  searchUser(): void {
    this.companyMode = false;
    this.errorMessage = '';

    this.formControlSearchLastname.setValue(this.formControlSearchLastname?.value?.trim());
    this.searchTests();
  }

  search(): void {
    this.companyMode = false;
    this.errorMessage = '';

    this.orderIDEntry.setValue(this.orderIDEntry.value.trim());

    if (this.isScUser && this.orderIDEntry.value < 9000000) {
      this.showValidationError = true;
      return;
    }

    // check if valid
    if (this.orderIDEntry.errors?.pattern !== undefined || this.orderIDEntry.value === '') {
      this.showValidationError = true;
      return;
    } else {
      this.showValidationError = false;
    }

    this.searchResults = [];
    this.getAccountInformation();
  }

  searchTests(): void {
    this.searchResults = [];
    this.companyService.searchOrdersByLastname(this.formControlSearchLastname.value).subscribe((res: any) => {
      if (res.data?.results?.length) {
        this.searchResults = res.data?.results;
      } else {
        this.showError('NO_RESULTS_LASTNAME');
      }
    });
  }

  fillSearchResult(accountInformation: AccountInformation & any): void {
    this.multipleOrderUse = accountInformation.multiple_order_use;
    if (this.multipleOrderUse) {
      this.orderIDEntry.setValue(accountInformation.ordernumber.toString());
    }
    this.consecutiveOrderIds = accountInformation.consecutive_order_ids;
    this.isWalkIn = accountInformation.is_walk_in ?? false;
    this.useAppCwa = accountInformation.use_app_cwa;
    this.useAppLuca = accountInformation.use_app_luca;
    this.companyTestUserId = accountInformation.company_testuser_id;
    this.isCompanyUser = +this.orderIDEntry.value >= this.COMPANY_ORDER_ID_THRESHOLD;

    this.firstname = accountInformation.firstname;
    this.lastname = accountInformation.lastname;
    this.email = accountInformation.email;
    this.reason = accountInformation.reason;
    this.originalReason = accountInformation.reason;
    this.phone = accountInformation.phone;
    if (accountInformation.birthday != null && accountInformation.birthday !== '0000-00-00') {
      this.birth = new Date(accountInformation.birthday);
      if (this.isValidDate(this.birth)) {
        this.birthString = this.birth.toLocaleDateString('de-DE');
      } else {
        this.birth = null;
      }
    }

    this.city = accountInformation.city;
    this.zip = accountInformation.zip;
    this.street = accountInformation.street;
    this.idcardno = accountInformation.idcardno;
    this.payment = accountInformation.price;
    this.testTypes = accountInformation.testTypes;
    this.consentGiven = this.isCompanyUser && ('consent_given' in accountInformation) ? accountInformation.consent_given : true;
    this.userId = this.isCompanyUser && ('testuserId' in accountInformation) ? accountInformation.testuserId : true;

    if (this.isCompanyUser) {
      this.originalTestType = accountInformation.testTypes.testType;
      this.originalPrice = accountInformation.price;
      this.prices = accountInformation.prices;
      const typeArr = [];
      typeArr.push(accountInformation.testTypes);
      this.testTypes = typeArr;
      this.nextTestkitId = accountInformation.nextTestkitId;
    }

    this.testtype = this.testTypes[0]!.testType;

    this.showReasonSelect = [TestTypes.ANTIGEN_FREE, TestTypes.ANTIGEN_PAID, TestTypes.ANTIGEN_PAID_REDUCED].includes(this.testtype);

    if (this.testTypes[0]!.testType === TestTypes.ANTIGEN || !this.isScUser) {
      this.hideAntigenSW = false;
    }

    this.changeVisibility(false, true);
  }

  isValidDate(d: any): boolean {
    return d instanceof Date && !isNaN(d.getTime());
  }

  editBirthday(): void {
    this.errorMessage = '';
    try {
      const dateTemp = moment(this.birthString, 'DD.MM.YYYY').toDate();
      if (isNaN(dateTemp.valueOf())) {
        throw new Error();
      }
      this.birth = dateTemp;
      this.editMode = 'edited';
    } catch (err: any) {
      this.translate.get('REGISTRATION.DATE_INVALID').subscribe((text: string) => {
        this.errorMessage = text;
      });
    }
  }

  addConfirmation(signature: string): void {
    this.userService.saveTestUserConsent(this.userId, signature).subscribe(
      (response: any) => {
        this.consentGiven = true;
        this.changeVisibility(false, false, false, true);
      }, (errorResponse: any) => {
        this.translate.get('REGISTRATION.CONSENT_GIVEN_FAILED').subscribe((text: string) => {
          this.errorMessage = text;
        });
      });
  }

  selectReason(): void {
    if (this.reason !== this.originalReason) {
      this.editMode = 'edited';
    }

    this.testtype = this.reasons.find((r: any) => r.label === this.reason).test_type;
  }

  selectTestType(): void {
    this.editMode = 'edited';

    if (this.testtype !== this.originalTestType) {
      const price: any = this.prices.find((item: any) => item.test_type === this.testtype);
      this.payment = price ? price.price : '0';
    } else {
      this.payment = this.originalPrice;
    }

    if (this.testtype.includes('free')) {
      this.payment = '0';
    }

    this.showReasonSelect = [TestTypes.ANTIGEN_FREE, TestTypes.ANTIGEN_PAID, TestTypes.ANTIGEN_PAID_REDUCED].includes(this.testtype);

    if (this.testtype === TestTypes.ANTIGEN_PAID) {
      this.reason = this.reasons.find((r: any) => r.test_type === this.testtype)?.label;
    }

    if (this.showReasonSelect) {
      if (this.reason === null && this.originalReason) {
        this.reason = this.originalReason;
      } else {
        setTimeout(() => {
          this.reason = (document.querySelector('#reason-select') as HTMLInputElement)?.value;

        }, 10);
      }
    } else {
      this.reason = null;
    }
  }

  resetSigning(): void {
    this.loadDigitalSigning();
  }

  confirmAndEdit(skipPaidCheck = false, skipReasonCheck = false, skipDigitalSigning = false): void {
    const isPaidTestType = (parseInt(this.payment, 10) > 0) && this.isScUser;
    const isAntigenFreeOrReduced = [TestTypes.ANTIGEN_FREE, TestTypes.ANTIGEN_PAID_REDUCED].includes(this.testtype);

    if ((isAntigenFreeOrReduced || this.testtype === TestTypes.ANTIGEN_PAID) && !this.reason) {
      this.showError('VALIDATION_ERROR_REASON');
      return;
    }

    this.showValidationError = false;

    if (isPaidTestType && !skipPaidCheck) {
      this.showUserPaymentModal = true;
      return;
    }

    this.showUserPaymentModal = false;

    if (isAntigenFreeOrReduced && !skipReasonCheck) {
      this.showUserReasonModal = true;
      return;
    }

    this.showUserReasonModal = false;

    if (this.isCompanyUser && this.testtype !== this.originalTestType) {
      this.testTypes[0]!.testType = this.testtype;
    }

    const date = moment(this.birth).format('YYYY-MM-DD');

    if (date === 'Invalid date') {
      this.showError('VALIDATION_ERROR_DATE');
      return;
    }

    if (!this.formControlEmail.valid) {
      this.showError('VALIDATION_ERROR_EMAIL');
      return;
    }

    if (!this.formControlZip.valid) {
      this.showError('VALIDATION_ERROR_ZIP');
      return;
    }

    if (this.testtype !== TestTypes.ANTIGEN_FREE && this.testtype !== TestTypes.ANTIGEN_PAID_REDUCED && this.testtype !== TestTypes.ANTIGEN_PAID) {
      this.reason = '';
    }

    const updated: Person & any = {
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      phone: this.phone,
      zip: this.zip,
      city: this.city,
      address: this.street,
      idcardno: this.idcardno,
      birthday: date,
      test_type: this.testtype,
      reason: this.reason,
    };

    this.updateUser.emit(updated);

    this.userService.updateOrder(
      this.orderIDEntry.value,
      this.firstname,
      this.lastname,
      this.email,
      this.phone,
      this.zip,
      this.city,
      this.street,
      this.idcardno,
      date,
      +this.companyTestUserId,
      this.originalTestType,
      this.originalTestType !== this.testtype ? this.testtype : '',
      this.reason,
      this.originalReason
    )
      .subscribe(
        response => {
          this.errorMessage = '';
          this.confirm(true, true);
        },
        errorResponse => {
          this.showError(errorResponse);
        }
      );
  }

  confirm(skipPaidCheck = false, skipReasonCheck = false, skipDigitalSigning = false): void {
    const isPaidTestType = (parseInt(this.payment, 10) > 0) && this.isScUser;
    const isAntigenFreeOrReduced = [TestTypes.ANTIGEN_FREE, TestTypes.ANTIGEN_PAID_REDUCED].includes(this.testtype);

    if ((isAntigenFreeOrReduced || this.testtype === TestTypes.ANTIGEN_PAID) && !this.reason) {
      this.showError('VALIDATION_ERROR_REASON');
      return;
    }

    if (isPaidTestType && !skipPaidCheck) {
      this.showUserPaymentModal = true;
      return;
    }

    this.showUserPaymentModal = false;

    if (isAntigenFreeOrReduced && !skipReasonCheck) {
      this.showUserReasonModal = true;
      return;
    }

    this.showUserReasonModal = false;

    if (isAntigenFreeOrReduced && !skipDigitalSigning && this.digitalSigningAvailable) {
      this.showDigitalSigningModal = true;
      this.loadDigitalSigning();
      return;
    }

    this.showDigitalSigningModal = false;

    this.changeVisibility(false, false, true);
  }

  loadDigitalSigning(reset = true): void {
    if(reset) {
      this.signingBlob = "";
      this.signingStatus = "NONE";
    }
    this.signingService.postSigningStatus({"orderId": this.orderIDEntry.value, "reset": reset}).subscribe((response) => {
      this.signingBlob = response.data.signing_blob;
      this.signingStatus = response.data.signing_status;
      if(this.signingStatus !== "DONE") {
        this.signingTimeout = setTimeout(() => {
          this.loadDigitalSigning(false);
        }, 3000);
      }
    });
  }

  cancelSigning(): void {
    if(this.signingTimeout !== null) clearTimeout(this.signingTimeout);
    this.showDigitalSigningModal = false;
  }

  skipSigning(): void {
    if(this.signingTimeout !== null) clearTimeout(this.signingTimeout);
    this.signingService.deleteSigningStatus().subscribe((response) => {
      this.confirm(true, true, true);
    }, (error) => {
      this.confirm(true, true, true);
    })
  }

  saveSigning(): void {
    if(this.signingTimeout !== null) clearTimeout(this.signingTimeout);
    this.signingService.postSigningSave(this.orderIDEntry.value).subscribe((response) => {
      this.signingBlob = "";
      this.signingStatus = "NONE";
      this.confirm(true, true, true);
    }, (error) => {
      this.loadDigitalSigning();
    });
  }

  cancel(): void {
    this.reset();
    this.changeVisibility(true);
  }

  public changeVisibility(entry = false, overview = false, consent = false, testkit = false, summary = false): void {
    this.showEntryContainer = entry;
    this.showOverviewContainer = overview;
    this.showTestkitContainer = testkit;
    this.showSummaryContainer = summary;
    this.showThirdPartyConsentContainer = consent;

    if (entry) {
      this.translate.get('REGISTRATION.HEADER_FIRST').subscribe((text: string) => {
        this.header = text;
      });
      this.translate.get('REGISTRATION.TEXT_HEADER_ENTRY').subscribe((text: string) => {
        this.description = text;
      });
    }

    if (overview) {
      this.translate.get('REGISTRATION.HEADER_SECOND').subscribe((text: string) => {
        this.header = text;
      });
      this.translate.get('REGISTRATION.TEXT_HEADER_CONFIRMATION').subscribe((text: string) => {
        this.description = text;
      });
      this.showCancelButton = true;
    }

    if (testkit) {
      if (!this.consentGiven && this.isCompanyUser && !this.isWalkIn) {
        this.translate.get('COMPANYCHECKIN.TITLE_CONSENT').subscribe((text: string) => {
          this.header = text;
        });

        this.translate.get('COMPANYCHECKIN.GIVE_CONSENT').subscribe((text: string) => {
          this.description = text;
        });
      } else {
        this.translate.get('REGISTRATION.HEADER_THIRD').subscribe((text: string) => {
          this.header = text;
          this.description = '';
        });
      }
    }

    if (consent) {
      this.translate.get('REGISTRATION.HEADER_THIRD_PARTY_CONSENT').subscribe((text: string) => {
        this.header = text;
      });
      this.translate.get('REGISTRATION.TEXT_HEADER_THIRD_PARTY_CONSENT').subscribe((text: string) => {
        this.description = text;
      });
      this.translate.get('REGISTRATION.BTN_OK').subscribe((text: string) => {
        this.cancelButtonText = text;
      });
      this.showCancelButton = true;
    }

    if (summary) {
      this.translate.get('REGISTRATION.HEADER_FOURTH').subscribe((text: string) => {
        this.header = text;
      });
      this.translate.get('REGISTRATION.TEXT_HEADER_SUMMARY').subscribe((text: string) => {
        this.description = text;
      });
      this.translate.get('REGISTRATION.BTN_OK').subscribe((text: string) => {
        this.cancelButtonText = text;
      });
      this.showCancelButton = true;
    }
    this.cdRef.detectChanges();
  }

  public setCancelButtonText(text?: string): void {
    this.cancelButtonText = text ? text : 'REGISTRATION.BTN_CANCEL';
  }

  public setCancelButton(show: boolean): void {
    this.showCancelButton = show;
    this.cdRef.detectChanges();
  }

  private reset(): void {
    this.userInstructionsText = '';
    this.header = '';
    this.description = '';
    this.serverErrorMessage = '';
    this.editMode = '';
    this.orderIDEntry.setValue('');
    this.showCancelButton = false;

    this.setCancelButtonText();

    setTimeout(() => {
      this.orderInput.nativeElement.focus();
    }, 0);

    this.changeVisibility(true, false);
    this.errorMessage = '';
  }

  public confirmThirdPartyConsent(): void {
    this.changeVisibility(false, false, false, true);
  }

  private getAccountInformation(): any {
    this.userService.getAccountInfoForOrder(this.orderIDEntry.value)
      .subscribe(
        response => {
          this.errorMessage = '';
          this.fillSearchResult(response.data);
        },
        errorResponse => {
          this.showError(errorResponse);
        }
      );
  }

  public showError(errorResponse: any): void {

    const error = errorResponse.error?.error?.description;
    if (errorResponse === 'VALIDATION_ERROR_DATE') {
      this.translate.get('REGISTRATION.VALIDATION_ERROR_DATE').subscribe((text: string) => {
        this.errorMessage = text;
      });
    } else if (errorResponse === 'VALIDATION_ERROR_ZIP') {
      this.translate.get('REGISTRATION.VALIDATION_ERROR_ZIP').subscribe((text: string) => {
        this.errorMessage = text;
      });
    }else if (errorResponse === 'VALIDATION_ERROR_REASON') {
      this.translate.get('REGISTRATION.VALIDATION_ERROR_REASON').subscribe((text: string) => {
        this.errorMessage = text;
      });
    } else if (errorResponse === 'NO_RESULTS_LASTNAME') {
      this.translate.get('REGISTRATION.NO_RESULTS_LASTNAME').subscribe((text: string) => {
        this.errorMessage = text;
      });
    } else if (errorResponse === 'VALIDATION_ERROR_EMAIL') {
      this.translate.get('REGISTRATION.VALIDATION_ERROR_EMAIL').subscribe((text: string) => {
        this.errorMessage = text;
      });
    } else if (error === 'SW_ORDER_ALREADY_DONE') {
      this.translate.get('REGISTRATION.SW_ORDER_ALREADY_DONE').subscribe((text: string) => {
        this.errorMessage = text;
      });
    } else if (error === 'SW_ORDER_NOT_FOUND') {
      this.translate.get('REGISTRATION.SW_ORDER_NOT_FOUND').subscribe((text: string) => {
        this.errorMessage = text;
      });
    } else if (error === 'SWORDER_ALREADY_CHECKEDIN') {
      this.translate.get('REGISTRATION.SWORDER_ALREADY_CHECKEDIN').subscribe((text: string) => {
        this.errorMessage = text;
      });
    } else if (error?.includes('ALREADY_HAD_ANTIGEN_FREE')) {
      const errArr = error.split(' ');

      if (errArr.length < 4) {
        return;
      }

      errArr.shift();
      let date = errArr.pop();
      const name = errArr.join(' ');

      const formatDate = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
      date = formatDate !== 'Invalid date' ? formatDate : date;

      this.translate.get('REGISTRATION.ALREADY_HAD_ANTIGEN_FREE', { name }).subscribe((text: string) => {
        this.errorMessage = text;
      });
    } else if (error === 'COMPANY_TEST_NO_CHECKIN_PERMISSION') {
      this.translate.get('COMPANYCHECKIN.COMPANY_TEST_NO_CHECKIN_PERMISSION',
        {companyName: errorResponse.error.data.company_name, companyId: errorResponse.error.data.company_id})
        .subscribe((text: string) => this.errorMessage = text);
    } else {
      this.translate.get('REGISTRATION.UNKNOWN_ERROR').subscribe((text: string) => {
        this.errorMessage = text;
      });
      if (error?.length) {
        this.serverErrorMessage = error;
      }
    }
  }
}

interface AccountInformation {
  id: string;
  odernumber: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  birthday: string;
  zip: string;
  city: string;
  idcardno: string;
  street: string;
  paymentState: string;
  price: string;
  testDate: string;
  testTime: string;
  testTypes: TestType[] | any;
  consent_given: boolean;
  testuserId?: any;
  nextTestkitId?: any;
  company_testuser_id?: any;
  is_walk_in?: boolean;
  use_app_cwa: boolean;
  use_app_luca: boolean;
  consecutive_order_ids: boolean;
}

export interface TestType {
  testType: TestTypes;
  testDate: string;
  testTime: string;
  done: boolean;
}

export enum TestTypes {
  ANTIBODY = 'antibody',
  PCR_EXPRESS = 'pcr-exp',
  PCR_STANDARD = 'pcr-std',
  PCR_LAB_FREE = 'pcr-lab-free',
  PCR_LAB_PAID = 'pcr-lab-paid',
  ANTIGEN = 'antigen',
  ANTIGEN_FREE = 'antigen-free',
  ANTIGEN_PAID = 'antigen-paid',
  ANTIGEN_PAID_REDUCED = 'antigen-paid-reduced',
  ANTIGEN_CARD = 'antigen-card',
  ANTIGEN_CARD_USE = 'antigen-card-use',
  ANTIGEN_CARD_TEAM_PAID = 'antigen-team-paid',
  ANTIGEN_CARD_TEAM_FREE = 'antigen-team-free',
}
