import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../user.service';

@Component({
  selector: 'app-testresult-exp',
  templateUrl: './testresult-exp.component.html',
  styleUrls: ['./testresult-exp.component.css']
})
export class TestresultExpComponent implements OnInit {

  @Input() isModal = false;
  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();

  readonly allowedFileTypes = ['text/csv', 'application/vnd.ms-excel'];

  csvIsNewPCR = false;

  showUploadContainer = true;
  showResultContainer = false;
  sendBtnEnabled = false;

  pcrResults: PcrResult[] = [];

  healyzerApiAvailable = false;
  checkedInPcrTests: Array<any> = [];
  refreshTimeoutId: any = null;

  description = '';
  errorMessage = '';
  serverError = '';

  sucessfullyDone = false;

  constructor(private spinner: NgxSpinnerService, private translate: TranslateService, private userService: UserService) { }

  ngOnInit(): void {
    this.reset();
    this.loadOpenPCRTests();
  }

  ngOnDestroy(): void {
    clearTimeout(this.refreshTimeoutId);
  }

  loadOpenPCRTests(): void {
    this.spinner.show();
    this.userService.getCheckedInPCRTests()
    .subscribe(
      response => {
        this.spinner.hide();
        this.healyzerApiAvailable = response["data"]["healyzerApi"];
        this.checkedInPcrTests = response["data"]["openPCRTests"];
        this.refreshTimeoutId = setTimeout(()=>{this.loadOpenPCRTests()}, 30000);
      },
      errorResponse => {
        this.spinner.hide();
        this.errorMessage = "LOAD_OPEN_PCR_TESTS_FAILED";
        this.serverError = errorResponse;
        this.refreshTimeoutId = setTimeout(()=>{this.loadOpenPCRTests()}, 30000);
      }
    );
  }

  timeConverter(UNIX_timestamp: number){
    var a = new Date(UNIX_timestamp * 1000);
    const time = moment(a).format('YYYY-MM-DD HH:mm:ss');
    return time;
  }

  loadHealyzerTests(): void {
    this.spinner.show();
    this.userService.getHealyzerTests(this.checkedInPcrTests)
    .subscribe(
      response => {
        this.spinner.hide();
        for (const tubeCode in response["data"]) {
          const tubeCodeResult = response["data"][tubeCode];

          if(tubeCodeResult["status"] === "PENDING" || tubeCodeResult["status"] === "ABORTED" || tubeCodeResult["status"] === "NOT_FOUND") continue;

          let gene1 = tubeCodeResult["fam_ct_value"];
          let gene2 = tubeCodeResult["cy5_ct_value"];

          var pcrResult: PcrResult = {
            toSend: false,
            isNewPcr: true,
            serialno: "Healyzer API",
            testkit: tubeCode,
            results: [
              gene1 >= 40 ? 'Neg.' : 'Pos.',
              gene2 >= 40 ? 'Neg.' : 'Pos.',
            ],
            result: tubeCodeResult["status"].toLowerCase(),
            values: [
              gene1 >= 40 ? '∞' : Number(gene1).toFixed(2).toString(),
              gene2 >= 40 ? '∞' : Number(gene2).toFixed(2).toString(),
              tubeCodeResult["ct_value"]
            ],
            timestamp: this.timeConverter(tubeCodeResult["testing_time"])
          };

          this.pcrResults.push(pcrResult);
        }

        if (this.pcrResults.length > 0) {
          this.changeVisibility(false, true);
        } else {
          this.showError(Errors.Errorcode);
        }
      },
      errorResponse => {
        this.spinner.hide();
        this.errorMessage = "LOAD_HEALYZER_TESTS_FAILED";
        this.serverError = errorResponse;
      }
    );
  }

  handleFileInput($event: any): void {
    const files = $event.target.files;

    if (files && files.length > 0) {
      const file: File = files.item(0)!;

      if (!this.allowedFileTypes.includes(file.type)) {
        this.showError(Errors.FileType);
        return;
      } else {
        this.errorMessage = '';
      }

      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const raw: string = reader.result as string;
        const lines = raw.split('\n');
        const splitBy = ',';

        if(lines[0].split(splitBy)[0].trim() === "Test Name") {
          this.proceedOldPcr(lines);
        } else if(lines[0].split(splitBy)[0].trim() === "Serial Number") {
          this.proceedNewPcr(lines);
        } else {
          this.showError(Errors.FileType);
          return;
        }
      };
    }
  }

  proceedOldPcr(lines: string[]): void {
    this.csvIsNewPCR = false;
    const splitBy = ',';
    var serialno = '';
    var testkit = '';
    var results: string[] = [];
    var result = '';
    var timestamp = '';
    var valuesArr: string[] = [];
    lines.forEach(element => {
      if (element.includes('Test Name')) {
        serialno = element.split(splitBy)[1].trim();

      } else if (element.includes('Sample Info.')) {
        testkit = element.split(splitBy)[1].trim();

      } else if (element.includes('Result')) {
        if (element.includes('��')) results = element.split(splitBy)[1].split('��');
        else results = element.split(splitBy)[1].split(';');

        if (results[0].includes('Pos') || results[1].includes('Pos')) {
          result = 'positive';
        } else if (results[0].includes('Neg') && results[1].includes('Neg')) {
          result = 'negative';
        } else {
          result = 'invalid';
        }

      } else if (element.includes('Test Time')) {
        timestamp = element.split(splitBy)[1].trim();

      } else if (element.includes('Auto-Ct')) {
        const values = element.split(splitBy);
        values.shift();
        values.forEach(value => {
            const i = parseFloat(value).toString();
            valuesArr.push(i == 'NaN' ? '\u221e' : i);
        });
      }
    });

    const pcrResult: PcrResult = {
      toSend: false,
      isNewPcr: false,
      serialno: serialno,
      testkit: testkit,
      results: results,
      result: result,
      timestamp: timestamp,
      values: valuesArr
    }

    this.pcrResults.push(pcrResult);

    if (this.hasValidResult()) {
      this.changeVisibility(false, true);
    } else {
      this.showError(Errors.Errorcode);
    }
  };

  proceedNewPcr(lines: string[]): void {
    this.csvIsNewPCR = true;
    const splitBy = ',';
    lines.splice(0, 1);
    var i = 0;
    for (let element of lines) {
      if(i > 19) break;
      const elementArr = element.split(splitBy);
      if(elementArr.length === 14) {
        var pcrResult: PcrResult = {
          toSend: false,
          isNewPcr: true,
          serialno: elementArr[0].trim() + "-" + elementArr[1].trim(),
          testkit: elementArr[3].trim(),
          results: [
            elementArr[5].trim() === 'NoCt' ? 'Neg.' : 'Pos.',
            elementArr[8].trim() === 'NoCt' ? 'Neg.' : 'Pos.'
          ],
          result: elementArr[4].trim().toLowerCase(),
          values: [
            elementArr[5].trim() === 'NoCt' ? '∞' : elementArr[5].trim(),
            elementArr[8].trim() === 'NoCt' ? '∞' : elementArr[8].trim(),
            elementArr[6].trim() === 'NoCt' ? '∞' : elementArr[6].trim(),
          ],
          timestamp: elementArr[9].trim()
        };

        if(pcrResult.result === "Need retest".trim().toLowerCase())
          pcrResult.result = "invalid";

        this.pcrResults.push(pcrResult);
        i++;
      }
    };

    if (this.pcrResults.length > 0) {
      this.changeVisibility(false, true);
    } else {
      this.showError(Errors.Errorcode);
    }
  };

  public changeSendTo(event: Event, pcrResult: PcrResult): void {
    event.stopPropagation();
    pcrResult.toSend = !pcrResult.toSend;
    this.sendBtnEnabled = false;
    for(var i = 0; i < this.pcrResults.length; i++) {
      if(this.pcrResults[i].toSend) this.sendBtnEnabled = true;
    }
  }

  async submit() {
    this.sendBtnEnabled = false;
    for(var i = 0; i < this.pcrResults.length; i++) {
      const pcrResult = this.pcrResults[i];
      pcrResult.sentErrorMsg = undefined;

      if(pcrResult.sent && !pcrResult.sentSucessfully) {
        pcrResult.sent = false;
      }

      if(!pcrResult.toSend) continue;
      pcrResult.toSend = false;

      this.spinner.show();

      var gene_results = {};

      if(pcrResult.isNewPcr) {
        gene_results = {
          "SARS-CoV-2-Gen ORF1ab": {
            result: pcrResult.results[0],
            ct: pcrResult.values[0],
          },
          "SARS-CoV-2-Gen E": {
            result: pcrResult.results[1],
            ct: pcrResult.values[1]
          },
          "Internal_Control": {
            ct: pcrResult.values[2]
          }
        };
      } else {
        gene_results = {
          "2019nCoV_RNA": {
            result: pcrResult.results[0],
            ct: pcrResult.values[0],
          },
          "CoV_N_gene": {
            result: pcrResult.results[1],
            ct: pcrResult.values[1]
          },
          "Internal_Control": {
            ct: pcrResult.values[2]
          }
        };
      }

      const body = {
        isNewPcr: pcrResult.isNewPcr,
        result: pcrResult.result,
        timestamp: pcrResult.timestamp,
        gene_results: gene_results
      };

      await this.submitResult(this.pcrResults[i], body);
    }

    var allDone = true;
    for(var i = 0; i < this.pcrResults.length; i++) {
      const pcrResult = this.pcrResults[i];
      if(pcrResult.sentSucessfully !== true) allDone = false;
    }
    if(allDone) {
      this.sucessfullyDone = true;
      this.translate.get('TESTRESULT.TEXT_SUCCESS').subscribe((text: string) => {
        this.description = text;
      });
      this.changeVisibility();
    }
    this.loadOpenPCRTests();
  };

  async submitResult(pcrResult: PcrResult, body: any) {
    return new Promise((resolve, reject) => {
      this.spinner.hide();
      pcrResult.sent = true;
      this.userService.submitResult(pcrResult.testkit, body)
      .subscribe(
        response => {
          this.spinner.hide();
          pcrResult.sentSucessfully = true;
          resolve(1);
        },
        errorResponse => {
          pcrResult.sentSucessfully = false;
          this.spinner.hide();
          this.returnError(errorResponse, pcrResult);
          //ignore error and continue
          resolve(1);
        }
      );
    });
  };

  cancel(): void {
    this.reset();
  }

  private hasValidResult(): boolean {
    return this.pcrResults[0] && this.pcrResults[0].testkit != '' && this.pcrResults[0].timestamp != '' && this.pcrResults[0].values.length >= 3 && (this.pcrResults[0].results.includes('Retest') || this.pcrResults[0].results.length == 2);
  }

  private reset() {
    this.sendBtnEnabled = false;
    this.pcrResults = [];

    this.errorMessage = '';

    this.changeVisibility(true);
    this.spinner.hide();
    this.errorMessage = '';
    this.sucessfullyDone = false;
  }

  private changeVisibility(upload = false, result = false) {
    this.showUploadContainer = upload;
    this.showResultContainer = result;

    if (upload) {
      this.translate.get('TESTRESULT-EXP.TEXT_UPLOAD_HEADER').subscribe((text: string) => {
        this.description = text;
      });
    }
    else if (result) {
      this.translate.get('TESTRESULT-EXP.TEXT_RESULT_HEADER').subscribe((text: string) => {
        this.description = text;
        });
    }
  }

  private showError(type: Errors, errorResponse: any = null): void {
    switch(type) {
      case Errors.FileType:
        this.translate.get('TESTRESULT-EXP.TEXT_ERROR_FILETYPE').subscribe((text: string) => {
          this.errorMessage = text;
        });
        break;

      case Errors.Errorcode:
        this.errorMessage = 'Datei kann nicht gelesen werden';
        break;
    }
  }

  private returnError(errorResponse: any, pcrResult: PcrResult): void {
    if (errorResponse.status === 404) {
      this.translate.get('TESTRESULT.MCS_ORDER_NOT_FOUND').subscribe((text: string) => {
        pcrResult.sentErrorMsg = text;
      });
    } else if (errorResponse.status === 500 && (errorResponse.error?.error?.description?.includes('SMTP')
      || errorResponse?.error?.error?.description?.toLowerCase().includes('invalid address'))) {
      this.translate.get('TESTRESULT.SMTP_ERROR').subscribe((text: string) => {
        pcrResult.sentErrorMsg = text;
      });
      this.serverError = errorResponse.error?.error?.description;
    } else if (errorResponse.status === 500 && errorResponse.error?.error?.type && errorResponse.error?.error?.description) {
      pcrResult.sentErrorMsg = errorResponse.error?.error?.type + ": " + errorResponse.error?.error?.description;
    } else {
      this.translate.get('TESTRESULT-EXP.TEXT_ERROR_CSV').subscribe((text: string) => {
        pcrResult.sentErrorMsg = text;
      });
    }
  }
}

export enum Errors {
  FileType,
  Errorcode
}

export interface PcrResult {
  toSend: boolean,
  isNewPcr: boolean,
  serialno: string,
  testkit: string,
  result: string,
  results: string[],
  timestamp: string,
  values: string[],
  sentErrorMsg?: string,
  sent?: boolean,
  sentSucessfully?: boolean
}
