<div class="wrapper">
  <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
    <span class="navbar-brand">[MCS Intern]</span>
  </nav>

  <div class="d-flex justify-content-center mt-5">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'PRIVKEY.TITLE' | translate }}</h3>
      </div>
      <div class="card-body">
        <div *ngIf="error" class="alert alert-danger" role="alert">{{ error }}</div>
        <form (ngSubmit)="skip()">
          <div class="input-group form-group">
            <label class="custom-file-label" for="customFile">{{ 'PRIVKEY.INPUT' | translate }}</label>
            <input type="file" class="custom-file-input" id="customFile" (change)="uploadPrivkey($event)">
          </div>
          <div class="form-group">
            Wenn Sie diesen Schritt überspringen, können Sie die Testergebnisse nicht herunterladen oder neu verschlüsseln.
          </div>
          <div class="form-group" *ngIf="!hideSkip">
            <button class="btn float-right login_btn bg-dark">{{ 'PRIVKEY.SKIPBTN' | translate }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
