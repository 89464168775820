export const testUserCSVMapUpload = [
  {
    key: 'firstname',
    label: 'Vorname',
  },
  {
    key: 'lastname',
    label: 'Nachname'
  },
  {
    key: 'sex',
    label: 'Geschlecht'
  },
  {
    key: 'birthday',
    label: 'Geburtsdatum'
  },
  {
    key: 'idcardno',
    label: 'Passnummer'
  },
  {
    key: 'email',
    label: 'E-Mail'
  },
  {
    key: 'phone',
    label: 'Telefon'
  },
  {
    key: 'job_function',
    label: 'Job/Position'
  },
  {
    key: 'address',
    label: 'Adresse'
  },
  {
    key: 'zip',
    label: 'PLZ'
  },
  {
    key: 'city',
    label: 'Stadt'
  },
  {
    key: 'test_type',
    label: 'Test-Typ'
  },
  {
    key: 'test_time',
    label: 'Testzeit'
  },
  {
    key: 'test_location',
    label: 'Testort'
  }
];

export const testUserCSVMap = [
  {
    key: 'order_id',
    label: 'Bestellnummer'
  },
  {
    key: 'firstname',
    label: 'Vorname',
  },
  {
    key: 'lastname',
    label: 'Nachname'
  },
  {
    key: 'sex',
    label: 'Geschlecht'
  },
  {
    key: 'birthday',
    label: 'Geburtsdatum'
  },
  {
    key: 'idcardno',
    label: 'Passnummer'
  },
  {
    key: 'email',
    label: 'E-Mail'
  },
  {
    key: 'phone',
    label: 'Telefon'
  },
  {
    key: 'job_function',
    label: 'Job/Position'
  },
  {
    key: 'address',
    label: 'Adresse'
  },
  {
    key: 'zip',
    label: 'PLZ'
  },
  {
    key: 'city',
    label: 'Stadt'
  },
  {
    key: 'test_type',
    label: 'Test-Typ'
  },
  {
    key: 'test_type_id',
    label: 'Test-ID'
  },
  {
    key: 'reason_descr',
    label: 'Grund'
  },
  {
    key: 'test_location',
    label: 'Testort'
  },
  {
    key: 'checkin_date',
    label: 'Testdatum'
  },
  {
    key: 'checkin_time',
    label: 'Testzeit'
  },
  {
    key: 'test_result',
    label: 'Testresultat'
  },
  {
    key: 'mitteilungsweg',
    label: 'MItteilungsweg'
  },
  {
    key: 'confirmation',
    label: 'Testbestätigung'
  },
  {
    key: 'test_received',
    label: 'Test abgeholt'
  },
  {
    key: 'payment',
    label: 'Zahlungsart'
  }
];

export const testUserBillingCSVMap = [
  {
    key: 'order_id',
    label: 'Bestellnummer'
  },
  {
    key: 'zip',
    label: 'PLZ'
  },
  {
    key: 'city',
    label: 'Stadt'
  },
  {
    key: 'test_type',
    label: 'Test-Typ'
  },
  {
    key: 'reason_descr',
    label: 'Grund'
  },
  {
    key: 'checkin_date',
    label: 'Testdatum'
  },
  {
    key: 'company_name',
    label: 'Firma/Shopware'
  },
  {
    key: 'billing_price',
    label: 'Brutto VK'
  },
  {
    key: 'total_price',
    label: 'Summe'
  },
];


export default function exportTestuserData(
  patientsArray: any, date: string | null, fileName = 'data.csv', company: string = '', showCompanyInfo = false, save = true, billingMode = false): any {
  const csvPatients = patientsArray.map((data: any) => {
    data['test_type'] = data['test_type'] == 'antigen-free' ? 'Bürgertest' : data['test_type'];
    data['test_type'] = data['test_type'] == 'antigen-paid-reduced' ? 'antigen-reduced' : data['test_type'];
    data['test_type'] = data['test_type'] == 'pcr-std' ? 'PCR Premium' : data['test_type'];
    data['test_type'] = data['test_type'] == 'pcr-exp' ? 'PCR Express' : data['test_type'];
    data['test_type'] = data['test_type'] == 'pcr-lab-free' ? 'PCR Labor KV' : data['test_type'];
    data['test_type'] = data['test_type'] == 'pcr-lab-paid' ? 'PCR Labor Paid' : data['test_type'];
    data['test_type'] = data['test_type'] == 'antigen-card-use' ? 'Antigen-einlösen' : data['test_type'];
    data['mitteilungsweg'] = parseInt(data['use_app_cwa']) >= 1 ? 'Digital + CWA' : 'Digital';
    data['test_result'] = data['test_result'] == '' ? 'n/a' : data['test_result'];
    const { status, test_user_id, testkit_id, is_archive, consent_given, testresult, ...patient} = data;

    return patient;
  });

  const replacer = (key: any, value: any) => value === null ? '' : value;
  let header: any[] = [];

  if (billingMode) {
    header = testUserBillingCSVMap.map(item => item.key);
  } else {
    header = testUserCSVMap.map(item => item.key);
  }

  if (showCompanyInfo && !billingMode) {
    header.push('company_name');
  }

  const checkFormat = (key: any, value: any) => {
    if (value) {
      if (key !== 'test_type' && key !== 'reason_descr') value = value.toString().replace(/\s/g, '');
      if (['mr', 'ms', 'd'].includes(value.toLowerCase())) {
        switch (value.toLowerCase()) {
          case 'mr' :
            return  'm';
          case 'ms' :
            return  'w';
          default:
            return 'd';
        }
      }
      if (key == 'phone') {
        value = 'tel: ' + value;
      }
      if (key == 'total_price') {
        value = value + ' €';
      }
    } else {
      value = '';
    }

    if (key == 'confirmation') {
      value = value ? 'Ja' : 'Nein';
    }

    if (key == 'test_received') {
      value = value ? 'Ja' : 'Nein';
    }

    return value;
  };

  const csv = csvPatients.map((row: any) => header.map(
    (key: string) => JSON.stringify(checkFormat(key,row[key]) +  '', replacer)).join(';')
  );

  const headerLine = (billingMode ? testUserBillingCSVMap : testUserCSVMap).map(item => item.label);
  if (showCompanyInfo && !billingMode) {
    headerLine.push('Firma/Shopware');
  }

  csv.unshift(headerLine.join(';'));
  if (date) {
    csv.unshift(`${company};${date};Gesamt: ${csvPatients.length}`);
  }
  const csvArray = csv.join('\r\n');
  const blob = new Blob(['\ufeff' + csvArray], {type: 'text/csv;charset=utf-8' });

  if (save) {
    saveAs(blob, fileName);
  } else {
    return { blob, fileName };
  }
}
