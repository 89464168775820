import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TestType } from '../../checkin.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { EnrichedTestType } from '../testkit-form/testkit-form.component';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'app-test-type-item',
  templateUrl: './test-type-item.component.html',
  styleUrls: ['./test-type-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestTypeItemComponent implements OnInit {

  private readonly MAX_TIME_MIN_DIFF = 180; // maximum time difference to show warning in FE
  private _data: TestType | any = {};

  public check: IconDefinition = faCheck;
  public date = '';
  public type = '';
  public time = '';
  public done = false;
  public testDateIsToday = false;
  public testTimeIsLate = false;

  @Input() set data(value: EnrichedTestType) {
    this._data = value;
    this.date = value.testDate;
    this.time = value.testTime;
    this.done = value.done;

    this.translate.get('TEST_TYPES.' + value.testType.toUpperCase()).subscribe((text: string) => {
      this.type = text;
    });

    this.testDateIsToday = moment(this.date, 'DD.MM.YYYY').isSame(moment(), 'day');

    const timeDiff = moment.duration(moment(new Date()).diff(moment(this.time, 'h:mm'))).asMinutes();
    this.testTimeIsLate = !this.testDateIsToday
      || timeDiff > this.MAX_TIME_MIN_DIFF // before
      || timeDiff * -1 > this.MAX_TIME_MIN_DIFF; // after
  }

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

}
