<div class="wrapper">
  <h1>{{ 'INFOPOINT.ADD_TEST_TITLE' | translate }}</h1><br>
  <app-alert *ngIf="message" [message]="message" [type]="'success'"></app-alert>

  <app-modal *ngIf="showOrderQRCode" (closeClicked)="showOrderQRCode = false">
    <app-qr-code [orderId]="orderId" [printLink]="true"
                 [checkinLink]="!!P.CHECKIN_AFTER_ADD_COMPANY_TEST"></app-qr-code>
  </app-modal>

  <app-testuser-edit [addMode]="true" [companyId]="company.id" (submitFinished)="testAdded($event)"></app-testuser-edit>
</div>

