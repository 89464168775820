<div class="wrapper v-scroll-wrapper">
  <div class="v-scroll-top">
    <div [class.title-desc-container]="showEmployeeListContainer">
      <h1>{{ 'COMPANY.TITLE' | translate }}</h1>

      <div class="order-breadcrumb">
        <h4 *ngIf="selectedCompany">{{ selectedCompany.name }}</h4>
        <h4 *ngIf="selectedOrder"> ➞ {{ selectedOrder.name }}</h4>
      </div>

      <div *ngIf="infoMessage">
        <b>{{ infoMessage }}</b><br>
      </div>

      <app-alert *ngIf="errorMessage" message="{{ errorMessage | translate }}"></app-alert>
    </div>

  </div>
  <div class="v-scroll-middle">

    <!-- Company List Container -->

    <div [hidden]="!showCompanyListContainer">
      <div class="center">
        <p>{{ 'COMPANY.TEXT_HEADER_COMPANY' | translate }}</p>
      </div>

      <div class="btn-container">
        <button class="custom-btn rounded add-btn" (click)="showAddCompanyDialog = true">+</button>

        <div class="input-btn-wrap">
          <input #lastnameSearchGlobalInput type="text" class="rounded" [placeholder]="'COMPANY.SEARCH_LASTNAME_ORDER_ID' | translate" (keyup.enter)="searchTestuserLastname(lastnameSearchGlobalInput.value)"/>
          <button class="custom-btn rounded icon-only-btn" style="width: auto;" (click)="searchTestuserLastname(lastnameSearchGlobalInput.value)"><fa-icon [icon]="faSearch">
          </fa-icon>
          </button>
        </div>

        <div class="input-btn-wrap">
          <input #companySearchInput type="text" class="rounded" [placeholder]="'Firmensuche'" (keyup.enter)="searchCompany()"/>
          <button class="custom-btn rounded icon-only-btn" style="width: auto;" ><fa-icon [icon]="faSearch" (click)="searchCompany()">
          </fa-icon>
          </button>
        </div>

        <button *ngxPermissionsOnly="P.ARCHIVE_COMPANY_ORDERS" class="custom-btn rounded" style="width: auto;" (click)="switchArchiveCompanies()"><fa-icon [icon]="faFolderOpen"></fa-icon> {{ archiveCompanyBtnLabel | translate }}</button>


      </div>



      <app-company-table *ngIf="companies"
        [companies]="companies"
        (selectOrder)="onSelectCompany($event)"
        (deleteCompany)="deleteCompany($event)"
        (archiveCompany)="archiveCompany($event)"
        (unArchiveCompany)="unArchiveCompany($event)"
        [companySearchString]="companySearchString"
        [showArchiveAction]="archiveCompanyBtnLabel === 'Archiv anzeigen'"
        [showUnArchiveAction]="archiveCompanyBtnLabel !== 'Archiv anzeigen'"
      ></app-company-table>
    </div>

    <!-- Company Entry Container -->

    <app-modal *ngIf="showAddCompanyDialog" (closeClicked)="showAddCompanyDialog = false">
      <app-company-add (submitFinished)="addCompany($event)"></app-company-add>
    </app-modal>

    <!-- Order List Container -->

    <div [hidden]="!showOrderListContainer">
      <div class="center">
        <p>{{ 'COMPANY.TEXT_HEADER_ORDER' | translate }}{{selectedCompany ? selectedCompany.name : ""}}.</p>
      </div>

      <div class="btn-container">
        <button class="custom-btn rounded add-btn" (click)="openAddOrderModal()">+</button>
        <button class="custom-btn rounded" *ngxPermissionsOnly="P.ARCHIVE_COMPANY_ORDERS" style="width: auto;" (click)="switchArchive()"><fa-icon [icon]="faFolderOpen"></fa-icon> {{ archiveBtnLabel | translate }}</button>

        <div class="input-btn-wrap" *ngxPermissionsOnly="P.DOWNLOAD_CSV_TESTUSER_DATA">
          <input #testDateInput type="text" class="rounded" placeholder="DD.MM.YYYY" (keyup.enter)="downloadCsvTestuserData(testDateInput.value)"/>
          <button class="custom-btn rounded" style="width: auto;" (click)="downloadCsvTestuserData(testDateInput.value)"><fa-icon [icon]="faFileCsv">
          </fa-icon> {{ exportCSVtestuserDataBtnLabel | translate }}
          </button>
        </div>

        <div class="input-btn-wrap">
          <input #lastnameSearchInput type="text" class="rounded" [placeholder]="'COMPANY.SEARCH_LASTNAME_ORDER_ID' | translate" (keyup.enter)="searchTestuserLastname(lastnameSearchInput.value)"/>
          <button class="custom-btn rounded icon-only-btn" style="width: auto;" (click)="searchTestuserLastname(lastnameSearchInput.value)"><fa-icon [icon]="faSearch">
          </fa-icon>
          </button>
        </div>

      </div>

      <app-company-order-table
        *ngIf="showOrderListContainer"
        [company]="selectedCompany!"
        [allowArchive]="true"
        [showActions]="!archiveIsActive"
        [companyOrders]="activeCompanyOrders"
        [archiveIsActive]="archiveIsActive"
        (archiveCompanyOrder)="archiveCompanyOrder($event)"
        (deleteCompanyOrder)="deleteCompanyOrder($event)"
        (selectOrder)="onSelectOrder($event)"
      ></app-company-order-table>
    </div>

    <!-- Order Entry Container -->

    <app-modal *ngIf="showAddOrderDialog" (closeClicked)="showAddOrderDialog = false">
      <app-company-order-add [company]="selectedCompany" (submitFinished)="addOrder($event)"></app-company-order-add>
    </app-modal>

    <!-- Search Lastname Container -->

    <div *ngIf="showSearchResultsContainer && foundPatients.length">
      <app-testuser-table
        (escape)="back()"
        [patients]="foundPatients"
        [showCompanyAndTestTime]="true"
        [disableCheckIn]="true"
        [allowArchive]="false"
      ></app-testuser-table>
    </div>

    <!-- Add Entry Container -->

    <div [hidden]="!showAddEmployeeContainer">
      <app-testuser-edit *ngIf="showAddEmployeeContainer" [addMode]="true" [orderId]="selectedOrder?.id" [companyId]="selectedCompany?.id" (submitFinished)="userAdded()"></app-testuser-edit>
      <br>
    </div>

    <ng-container *ngIf="showEmployeeListContainer">
      <app-testuser-table
        (escape)="back()"
        #testUserTable *ngIf="showEmployeeListContainer"
        (clickAddUser)="addEmployee()"
        (clickUploadCSV)="showUploadDialog= true"
        [showAddButtons]="true"
        [allowArchive]="true"
        [disableCheckIn]="true"
        [selectedCompany]="selectedCompany"
        [selectedOrder]="selectedOrder">
      </app-testuser-table>
    </ng-container>

  </div>

  <!-- Upload Modal -->

  <app-modal *ngIf="showUploadDialog" (closeClicked)="showUploadDialog = false">
    <app-add-company-testuser-csv [selectedOrder]="selectedOrder" [companyId]="selectedCompany?.id!" (done)="csvUploadDone()" (errorEmitter)="csvError($event)"></app-add-company-testuser-csv>
  </app-modal>

  <div class="v-scroll-bottom">
    <app-back-button *ngIf="!showCompanyListContainer" (click)="back()"></app-back-button>
  </div>
</div>
