import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genderLabel'
})
export class GenderLabelPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {

    if (!value) {
      return 'unbekannt';
    }

    value = value.toString().toLowerCase();

    if (value === 'mr') {
      return 'M';
    }

    if (value === 'ms') {
      return 'W';
    }

    if (value === 'ms') {
      return 'W';
    }

    return null;
  }

}
