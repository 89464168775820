<div class="wrapper v-scroll-wrapper">
  <div class="v-scroll-top">
    <div class="container-h-legend">
      <div>
        <h1>{{ 'COMPANYCHECKIN.TITLE' | translate }}</h1>

        <div *ngIf="infoMessage">
          <b>{{ infoMessage }}</b><br>
        </div>

        <!-- Error Message -->

        <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>

        <!-- Header -->

        <div class="center no-margin">

          <div class="order-breadcrumb" *ngIf="!showSearchResultsContainer">
            <h4 *ngIf="selectedCompany">{{ selectedCompany.name }}</h4>
            <h4 *ngIf="selectedOrder"> ➞ {{ selectedOrder.name }}</h4>
          </div>

          <div class="btn-container">
            <div class="input-btn-wrap" *ngIf="showOrderListContainer">
              <input #lastnameSearchInput type="text" class="rounded" [placeholder]="'COMPANY.SEARCH_LASTNAME_ORDER_ID' | translate" (keyup.enter)="searchTestuserLastname(lastnameSearchInput.value)"/>
              <button class="custom-btn rounded icon-only-btn" style="width: auto;" (click)="searchTestuserLastname(lastnameSearchInput.value)"><fa-icon [icon]="faSearch">
              </fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Company List Container -->

    <div [hidden]="!showCompanyListContainer">
      <div class="center">
        <p>{{ 'COMPANY.TEXT_HEADER_COMPANY' | translate }}</p>
      </div>

      <div class="input-btn-wrap">
        <input #companySearchInput type="text" class="rounded" [placeholder]="'Firmensuche'" (keyup.enter)="searchCompany()"/>
        <button class="custom-btn rounded icon-only-btn" style="width: auto;" ><fa-icon [icon]="faSearch" (click)="searchCompany()">
        </fa-icon>
        </button>
      </div>

      <div class="center">
        <app-company-table
          [companies]="companies"
          (selectOrder)="onSelectCompany($event)"
          [hideActions]="true"
          [companySearchString]="companySearchString"
        ></app-company-table>
      </div>
    </div>

    <!-- Order List Container -->

    <app-company-order-table
      *ngIf="showOrderListContainer"
      [showActions]="false"
      [companyOrders]="selectedCompany?.orders!"
      (selectOrder)="onSelectOrder($event)"
    ></app-company-order-table>

  </div>
  <div class="v-scroll-middle">
    <!-- Search Lastname Container -->

    <div *ngIf="showSearchResultsContainer && foundPatients" style="height: 100%;">
      <app-testuser-table
        [patients]="foundPatients"
        [allowArchive]="false"
        [showExport]="false"
        [allowAddNewTestPCR]="true"
        (escape)="cancel()"
      ></app-testuser-table>
    </div>

    <!-- Employee List Container -->

    <app-testuser-table
      *ngIf="showPatientListContainer"
      [showExport]="true"
      [selectedCompany]="selectedCompany"
      [selectedOrder]="selectedOrder"
      [showSearchBar]="true"
      [allowAddNewTestPCR]="true"
      (escape)="cancel()"
    ></app-testuser-table>

  </div>

  <app-back-button *ngIf="!showCompanyListContainer && !(companyService.currentCompany?.id && showOrderListContainer)" (click)="cancel()"></app-back-button>
</div>

