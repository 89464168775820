import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '../../../models/company';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faTrash, faEdit, faChevronUp, faFolderOpen, faFolder } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-table',
  templateUrl: './company-table.component.html',
  styleUrls: ['./company-table.component.css']
})
export class CompanyTableComponent implements OnInit {

  public faTrash: IconDefinition = faTrash;
  public faChevronUp: IconDefinition = faChevronUp;
  public faFolderOpen: IconDefinition = faFolderOpen;
  public faFolder: IconDefinition = faFolder;
  public faEdit: IconDefinition = faEdit;

  public selectedCompany: Company | undefined;
  public showEditCompanyModal = false;

  @Input() companies: Company[] = [];
  @Input() hideActions = false;
  @Input() showArchiveAction = false;
  @Input() showUnArchiveAction = false;

  @Input() set companySearchString(value: string) {
    this._companySearchString = value;
    this.cdr.detectChanges();
  }

  @Output() selectOrder: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteCompany: EventEmitter<any> = new EventEmitter<any>();
  @Output() archiveCompany: EventEmitter<any> = new EventEmitter<any>();
  @Output() unArchiveCompany: EventEmitter<any> = new EventEmitter<any>();

  private currentOrder: any = null;
  private element: ElementRef;

  private _companySearchString = '';
  private userMessage = '';

  sortState = {
    role: true,
    id: true,
  };

  constructor(elem: ElementRef, private translate: TranslateService, private cdr: ChangeDetectorRef) {
    this.element = elem;
  }

  ngOnInit(): void {
  }

  public filterCompanies(companies: Company[]): Company[] {
    if (!this._companySearchString) {
      return this.companies;
    }
    return companies.filter(item => item.name?.toLowerCase().includes(this._companySearchString.toLowerCase()));
  }

  public onSelectCompany(event: Event, company: Company): void {
    event.stopPropagation();
    this.selectOrder.emit(company);
  }

  public onDeleteCompany(event: Event, company: Company): void {
    event.stopPropagation();
    this.deleteCompany.emit(company);
  }

  public onArchiveCompany(event: Event, company: Company): void {
    event.stopPropagation();
    this.archiveCompany.emit(company);
  }

  public onUnArchiveCompany(event: Event, company: Company): void {
    event.stopPropagation();
    this.unArchiveCompany.emit(company);
  }

  public onEditCompany(event: Event, company: Company): void {
    event.stopPropagation();
    this.selectedCompany = company;
    this.showEditCompanyModal = true;
  }

  companyEditFinished(company: Company): void {
    this.translate.get('COMPANY.EDIT_SUCCESS').subscribe((success: string) => {
      this.userMessage = success;
    });

    const selectedCompanyIndex = this.companies?.findIndex(company => company === this.selectedCompany);
    this.companies![selectedCompanyIndex!] = company;

    this.showEditCompanyModal = false;
  }

  public sortList(key: keyof Company, elem: HTMLSpanElement): void {
    this.element.nativeElement.querySelectorAll('.header span').forEach((element: Element) => element.classList.remove('up', 'down', 'active'));
    this.currentOrder = { key, elem };
    // @ts-ignore
    const compare = this.sortState[key] ? 1 : -1;
    elem.classList.add(compare < 1 ? 'up' : 'down', 'active');
    this.companies?.sort((a, b) => {
      if (a[key]! < b[key]!) { return compare; }
      if (a[key]! > b[key]!) { return -compare; }
      return 0;
    });
    // @ts-ignore
    this.sortState[key] = !this.sortState[key];
  }

}
