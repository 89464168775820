<app-alert [autoMode]="true"></app-alert>

<div class="center">
  <p>{{ 'COMPANY.TEXT_HEADER_COMPANY_ENTRY' | translate }}</p>
</div>
<form (input)="validateForm()">
  <div class="center">
    <input #inputCompanyName type="text" class="rounded"
           (keyup.enter)="inputCompanyOegdName.focus()"
           placeholder="{{ 'COMPANY.COMPANY_NAME' | translate }} *"/>
  </div>

  <div class="center half">
    <input #inputCompanyOegdName type="text" class="rounded"
           (keyup.enter)="inputCompanyTestcenterId.focus()"
           placeholder="{{ 'COMPANY.COMPANY_OEGD_NAME' | translate }}"/>
  </div>

  <div class="center half">
    <input #inputCompanyTestcenterId type="text" class="rounded"
           (keyup.enter)="inputCompanyStreet.focus()"
           placeholder="{{ 'COMPANY.COMPANY_TID' | translate }}"/>
  </div>

  <div class="center">
    <input #inputCompanyStreet type="text" class="rounded"
           (keyup.enter)="inputCompanyPostalCode.focus()"
           placeholder="{{ 'COMPANY.COMPANY_STREET' | translate }}"/>
  </div>

  <div class="center company-add__zip">
    <input #inputCompanyPostalCode type="text" class="rounded"
           (keyup.enter)="inputCompanyCity.focus()"
           placeholder="{{ 'COMPANY.COMPANY_POSTCODE' | translate }}"/>
  </div>

  <div class="center company-add__city">
    <input #inputCompanyCity type="text" class="rounded"
           (keyup.enter)="inputCompanyDescription.focus()"
           placeholder="{{ 'COMPANY.COMPANY_CITY' | translate }}"/>
  </div>
  <div class="center">
    <textarea #inputCompanyDescription name="description" class="rounded" (input)="descriptionChanged(inputCompanyDescription.value)" placeholder="{{ 'COMPANY.COMPANY_DESCRIPTION' | translate }} *" maxlength="21000" style="text-align:center; height:200px;"></textarea>
  </div>
  <div class="center">
    <input #inputCompanyUserName name="username" type="text" class="rounded" (keyup.enter)="inputCompanyFirstname.focus()"
           placeholder="{{ 'COMPANY.COMPANY_CONTACT' | translate }} {{ 'COMPANY.COMPANY_USERNAME' | translate }} *"/>
  </div>
  <div class="center">
    <input #inputCompanyFirstname name="firstname" type="text" class="rounded" (keyup.enter)="inputCompanyLastname.focus()"
           placeholder="{{ 'COMPANY.COMPANY_CONTACT' | translate }} {{ 'COMPANY.COMPANY_FIRSTNAME' | translate }}"/>
  </div>
  <div class="center">
    <input #inputCompanyLastname name="lastname" type="text" class="rounded" (keyup.enter)="inputCompanyWalkIn.focus()"
           placeholder="{{ 'COMPANY.COMPANY_CONTACT' | translate }} {{ 'COMPANY.COMPANY_LASTNAME' | translate }}"/>
  </div>
  <label class="center">
    <input #inputCompanyWalkIn name="walk-in" type="checkbox" [(ngModel)]="companyIsWalkIn" (keyup.enter)="inputCompanySkipFreeCheck.focus()"/>
    <span>{{ 'COMPANY.IS_WALK_IN_COMPANY' | translate }}</span>
  </label>
  <label class="center">
    <input #inputCompanySkipFreeCheck name="skip-free-check" type="checkbox" [(ngModel)]="companySkipFreeCheck"/>
    <span>{{ 'COMPANY.SKIP_FREE_CHECK' | translate }}</span>
  </label>
  <label class="center">
    <input #inputMultipleOrderUseAllowed name="skip-free-check" type="checkbox" [(ngModel)]="companyMultipleOrderUseAllowed"/>
    <span>{{ 'COMPANY.MULTIPLE_ORDER_USE_ALLOWED' | translate }}</span>
  </label>
  <div class="center">
    <button class="custom-btn rounded" (click)="postNewCompanyEntry()" [disabled]="!formIsValid">
      {{ 'COMPANY.BTN_NEW_COMPANY' | translate }}
    </button>
  </div>
</form>
