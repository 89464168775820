<app-alert *ngIf="errorMessage" [message]="errorMessage" [type]="'danger'"></app-alert>

<form class="row g-3" [formGroup]="form">
  <div class="col-md-12">
    <label class="form-label">{{ 'COMPANYCHECKIN.START_CWA_VCARD_SCAN' | translate }}</label>
    <textarea #vcardinput class="form-control" required formControlName="vcarddata" (input)="inputChanged(vcardinput.value)" style="height:200px; font-size:12px;"></textarea>
  </div>
</form>

<div class="col-12">
  <button [disabled]="!form.valid" (click)="save()" class="custom-btn rounded">{{ 'COMPANYCHECKIN.BTN_SUBMIT' | translate }}</button>
</div>