<app-alert *ngIf="errorMessage" [message]="errorMessage" [type]="'danger'"></app-alert>

<form class="row g-3" [formGroup]="form">
  <div class="col-md-12">
    <label class="form-label">{{ 'COMPANY.ORDER_NAME' | translate }}</label>
    <input type="text" class="form-control" required formControlName="name">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANY.ORDER_DATE' | translate }}</label>
    <input type="text" class="form-control" formControlName="date">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANY.ORDER_MAX_USERS' | translate }}</label>
    <input type="text" class="form-control" formControlName="max_users">
  </div>

  <div class="col-md-12">
    <label class="center"><input type="checkbox" formControlName="consecutive_order_ids"/><span>{{ 'COMPANY.CONSECUTIVE_ORDER_IDS_LABEL' | translate }}</span></label>
  </div>

  <div class="col-md-12">
    <label class="center"><input type="checkbox" formControlName="is_walk_in"/><span>{{ 'COMPANY.IS_WALK_IN_LABEL' | translate }}</span></label>
  </div>

  <div class="col-md-12">
    <label class="center"><input type="checkbox" formControlName="selfcheckin"/><span>{{ 'COMPANY.SELF_CHECK_LABEL' | translate }}</span></label>
  </div>

  <div class="col-md-12">
    <label class="center"><input type="checkbox" formControlName="minimal_required_fields"/><span>Reduzierte Pflichtfelder im SelfCheck</span></label>
  </div>

  <div class="col-12">
    <button [disabled]="!form.valid" (click)="save()" class="custom-btn rounded">{{ 'COMPANYCHECKIN.BTN_SUBMIT' | translate }}</button>
  </div>
</form>
