<div class="wrapper">
  <h1>{{ 'UTILIZATION.TITLE' | translate }}</h1>

  <!-- Error Message -->

  <div class="alert alert-danger" role="alert" [hidden]="!showBackendError">
    {{ errorMessage }}
  </div>

  <!-- Order List Container -->
  <div class="center">
    <ul class="header">
      <li>
        <span class="long">{{ 'UTILIZATION.NAME' | translate }}</span>
        <span class="normal">{{ 'UTILIZATION.INSTOCK' | translate }}</span>
        <span class="normal">{{ 'UTILIZATION.SALES' | translate }}</span>
        <span class="normal">{{ 'UTILIZATION.SLOTS' | translate }}</span>
        <span class="normal">{{ 'UTILIZATION.ORDERS' | translate }}</span>
      </li>
    </ul>

    <div class="devider"></div>

    <ul class="list">
      <li *ngFor="let util of utilizationData">
        <span class="item long">{{util.name}}</span>
        <span class="item normal">{{util.instock}}</span>
        <span class="item normal">{{util.sales}}</span>
        <span class="item normal">{{util.slots}}</span>
        <span class="item normal">{{util.orders}}</span>
      </li>
    </ul>
  </div>
</div>
