
<form class="row g-3" [formGroup]="form">

  <div class="col-md-6">
    <label class="form-label">Username / E-Mail <span class="req">*</span></label>
    <input type="text" class="form-control" formControlName="username">
  </div>

  <div class="col-md-6">
    <label class="form-label">Company</label>
    <select class="form-control" formControlName="company_id">
      <option [value]="null">-</option>
      <option *ngFor="let company of companies" [value]="company.id">{{ company.name }}</option>
    </select>
  </div>

  <div class="col-md-6">
    <label class="form-label">Vorname</label>
    <input type="text" class="form-control" formControlName="firstname">
  </div>

  <div class="col-md-6">
    <label class="form-label">Nachname</label>
    <input type="text" class="form-control" formControlName="lastname">
  </div>

  <div class="col-md-6">
    <label class="form-label">Rolle <span class="req">*</span></label>
    <select class="form-control" formControlName="role">
      <option value="1">superadmin</option>
      <option value="2">superadmin_readonly</option>
      <option value="3">admin</option>
      <option value="4">tz_reception</option>
      <option value="5">doctor</option>
      <option value="6">company_ipad</option>
      <option value="7">company</option>
      <option value="8">sc_manager</option>
      <option value="9">sc_infopoint</option>
      <option value="10">sc_reception</option>
      <option value="11">sc_reception_infopoint</option>
    </select>
  </div>

  <div class="col-md-6">
    <label class="form-label">Location</label>
    <select class="form-control" formControlName="location">
      <option [value]="null">-</option>
      <option *ngFor="let location of locations" [value]="location.id">{{ location.name }}</option>
    </select>
  </div>

  <div class="col-md-6">
    <label class="form-label"><ng-container *ngIf="editMode">Neues </ng-container>Passwort <ng-container *ngIf="editMode">(optional)</ng-container> <span class="req" *ngIf="!editMode">*</span></label>
    <input type="password" autocomplete="new-password" [value]="null" class="form-control" formControlName="password">
  </div>

  <div class="col-md-12"></div>

  <div class="col-md-6">
    <button *ngIf="editMode" (click)="delete()" class="custom-btn rounded alert-warning cancel-btn">Benutzer löschen</button>
  </div>

  <div class="col-md-6">
    <button [disabled]="!form.valid" (click)="save()" class="custom-btn rounded">{{ (editMode ? 'speichern' : 'Benutzer anlegen') }}</button>
  </div>

</form>
