import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { SharedService } from '../shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit, OnDestroy {

  @HostBinding('class.is-collapsed') isCollapsed = false;

  public faArrowCircleLeft: IconDefinition = faArrowCircleLeft;
  public sharedSubscription: Subscription | undefined;

  constructor( private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedSubscription = this.sharedService.menuIsCollapsedAsObservable.subscribe(isCollapsed => this.isCollapsed = isCollapsed);
  }

  ngOnDestroy(): void {
    if (this.sharedSubscription) {
      this.sharedSubscription.unsubscribe();
    }
  }

}
