import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorMCSResponse } from '../http-error-interceptor.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private readonly _menuIsCollapsedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _locationIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private readonly _lastErrorSubject: BehaviorSubject<T> = new BehaviorSubject<T>(null);

  constructor() {}

  get menuIsCollapsed(): boolean {
    return this._menuIsCollapsedSubject.getValue();
  }

  set menuIsCollapsed(val: boolean) {
    this._menuIsCollapsedSubject.next(val);
  }

  get menuIsCollapsedAsObservable(): Observable<boolean> {
    return this._menuIsCollapsedSubject.asObservable();
  }

  get locationId(): string {
    return this._locationIdSubject.getValue();
  }

  set locationId(val: string) {
    this._locationIdSubject.next(val);
  }

  get lastError(): T {
    return this._lastErrorSubject.getValue();
  }

  set lastError(val: T) {
    this._lastErrorSubject.next(val);
  }

  get lastErrorAsObservable(): Observable<T> {
    return this._lastErrorSubject
      .asObservable()
      .pipe(filter(val => val !== null));
  }
}

type T = HttpErrorMCSResponse | null;
