import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Company } from '../models/company';
import { Order } from '../models/order';
import { UserService } from '../user.service';
import { Errors } from '../testresult-exp/testresult-exp.component';
import { CompanyService } from '../company.service';
import { AuthenticationService } from '../authentication.service';
import { TestuserTableComponent } from '../company-checkin/components/testuser-table/testuser-table.component';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faFolderOpen, faSearch, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs/operators';
import { Person } from '../models/person';
import { PERMISSIONS } from '../roles';
import * as moment from 'moment';
import exportTestuserData from '../download/csvHelper';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-company-admin',
  templateUrl: './company-admin.component.html',
  styleUrls: ['./company-admin.component.css']
})
export class CompanyAdminComponent implements OnInit {

  @ViewChild(TestuserTableComponent) testuserTableComponent!: TestuserTableComponent;
  @ViewChild('testDateInput') testDateInput!: ElementRef;
  @ViewChild('testSearchInput') testSearchInput!: ElementRef;
  @ViewChild('testSearchFilterInput') testSearchFilterInput!: ElementRef;

  public faSearch: IconDefinition = faSearch;
  public faFileCsv: IconDefinition = faFileCsv;

  public P = PERMISSIONS;

  infoMessage = '';
  company: Company = {};
  orders: Order[] = [];
  selectedOrder: Order | null = null;
  showUploadDialog = false;
  foundPatients: Person[] | any;

  showAddOrderDialog = false;

  showSearchResultsContainer = false;
  showOrderListContainer = true;
  showEmployeeListContainer = false;
  showAddContainer = false;
  showValidationError = false;
  canAddTestuser = false;
  showExportResults = false;

  sucessfullyDone = false;

  description = '';
  errorMessage = '';

  privateKey = '';

  archiveBtnLabel = 'COMPANY.SHOW_ARCHIVE_LABEL';
  showArchive = false;

  constructor(
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private userService: UserService,
    private companyService: CompanyService,
    private authenticationService: AuthenticationService,
    private permissions: NgxPermissionsService,
  ) {
    if (this.authenticationService.getPrivateKey() !== '') { this.privateKey = this.authenticationService.getPrivateKey(); }
  }

  public faFolderOpen: IconDefinition = faFolderOpen;

  ngOnInit(): void {
    this.reset();
    this.company = this.companyService.currentCompany;
    this.loadCompanyOrders();
    this.canAddTestuser = !!this.permissions.getPermission(this.P.CAN_ADD_EMPLOYEE);
    this.showExportResults = !!this.permissions.getPermission(this.P.CAN_EXPORT_EMPLOYEE_RESULTS);
  }

  loadCompanyOrders(): any {
    this.companyService.getCompanyOrders().subscribe(response => {
      this.orders = response.data;
    });
  }

  downloadCsvTestuserData(dateString: string): void {
    const date = moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD');
    this.companyService.getCompanyTestuserDataByTestdate(date, this.company?.id)
      .subscribe((response: any) => {
          if (response.status === 204) {
            this.errorMessage = '';
            this.translate.get('DOWNLOAD.NO_RESULTS_CSV').subscribe((text: string) => this.errorMessage = text);
          } else {
            exportTestuserData(response.body.data, date, 'personendaten.csv', this.company?.name);
          }
        },
        errorResponse => this.errorMessage = errorResponse.message
      );
  }

  switchArchive(): void {
    this.archiveBtnLabel = this.archiveBtnLabel === 'COMPANY.SHOW_ARCHIVE_LABEL' ? 'COMPANY.HIDE_ARCHIVE_LABEL' : 'COMPANY.SHOW_ARCHIVE_LABEL';
    this.showArchive = !this.showArchive;
    this.companyService.getCompanyOrders(this.showArchive).pipe(
      map(res => res.data),
      map(orders => this.showArchive ? orders.filter((order: Order) => order.is_archive) : orders)
    ).subscribe(orders => this.orders = orders);
  }

  searchTestuserLastname(): void {

    const search = this.testSearchInput.nativeElement.value;
    const filter = this.testSearchFilterInput.nativeElement.value;

    this.errorMessage = '';
    this.companyService.getCompanyOrderTestsBySearch(search, filter, this.company.id, this.privateKey).subscribe((res: any) => {
      if (res.data?.length) {
        this.description = '';
        this.changeVisibility(false, false, false, true);
        this.foundPatients = res.data;
        this.showSearchResultsContainer = true;
        this.translate.get('COMPANY.SEARCH_FOR', { search }).subscribe((text: string) => {
          this.infoMessage = text;
        });
      } else {
        this.noSearchResults();
      }
    }, () => this.noSearchResults());
  }

  private noSearchResults(): void {
    this.changeVisibility(true, false, false, false);
    this.foundPatients = [];
    this.translate.get('COMPANY.NO_RESULTS').subscribe((text: string) => {
      this.errorMessage = text;
    });
    this.showOrderListContainer = true;
  }

  addOrder(newOrder: Order): void {
    // @ts-ignore
    this.selectedCompanyOrders?.push(newOrder);
    this.orders.push(newOrder);
    this.showAddOrderDialog = false;
  }

  csvUploadDone(): void {
    setTimeout(() => this.testuserTableComponent?.ngOnInit());
    this.showUploadDialog = false;
  }

  csvError(errors: any): void {
    if (!errors) {
      this.errorMessage = '';
      return;
    }

    this.showError(errors);
    this.showUploadDialog = false;
  }

  onSelectOrder(order: Order): void {
    this.selectedOrder = order;
    this.changeVisibility(false, true);
  }

  addEmployee(): void {
    this.changeVisibility(false, false, true);
  }

  userAdded(): void {
    this.changeVisibility(false, true);
    this.refresh();
  }

  refresh(): void {
    setTimeout(() => this.testuserTableComponent?.ngOnInit());
  }

  cancel(): void {
    this.infoMessage = '';
    if (this.showEmployeeListContainer) {
      this.changeVisibility();
    } else if (this.showAddContainer) {
      this.changeVisibility(false, true);
    } else if (this.showSearchResultsContainer) {
      this.loadCompanyOrders();
      this.changeVisibility(true, false);
    }
  }

  private reset(): void {
    this.infoMessage = '';
    this.sucessfullyDone = false;
    this.errorMessage = '';

    this.changeVisibility();
    this.spinner.hide();
    this.errorMessage = '';
  }

  private changeVisibility(order = true, employee = false, add = false, search = false): void {
    this.showOrderListContainer = order;
    this.showEmployeeListContainer = employee;
    this.showAddContainer = add;
    this.showSearchResultsContainer = search;

    if (order) {
      this.loadCompanyOrders();
      this.translate.get('COMPANYADMIN.TEXT_ORDER_HEADER').subscribe((text: string) => {
        this.description = text;
      });
    } else if (employee) {
      this.translate.get('COMPANYADMIN.TEXT_EMPLOYEE_HEADER').subscribe((text: string) => {
        this.description = text;
      });
    } else if (add) {
      this.translate.get('COMPANYADMIN.TEXT_NUMBER_HEADER').subscribe((text: string) => {
        this.description = text;
      });
    } else if (search) {
      this.description = '';
    }
  }

  private showError(errorResponse: any): void {

    if (errorResponse === Errors.FileType) {
      this.translate.get('TESTRESULT-EXP.TEXT_ERROR_FILETYPE').subscribe((text: string) => {
        this.errorMessage = text;
      });
    } else if (errorResponse.error.error.description === 'MCS_ORDER_NOT_FOUND') {
      this.translate.get('TESTRESULT.MCS_ORDER_NOT_FOUND').subscribe((text: string) => {
        this.errorMessage = text;
      });
    } else {
      this.translate.get('TESTRESULT.UNKNOWN_ERROR').subscribe((text: string) => {
        this.errorMessage = text;
      });
    }
  }
}

