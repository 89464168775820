import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Order } from '../../../models/order';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faTrash, faFolderOpen, faChevronUp, faEdit } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Company } from '../../../models/company';

@Component({
  selector: 'app-company-order-table',
  templateUrl: './company-order-table.component.html',
  styleUrls: ['./company-order-table.component.scss']
})
export class CompanyOrderTableComponent implements OnInit, AfterViewInit {

  public faTrash: IconDefinition = faTrash;
  public faEdit: IconDefinition = faEdit;
  public faFolderOpen: IconDefinition = faFolderOpen;
  public faChevronUp: IconDefinition = faChevronUp;

  public selectedOrder: Order | undefined;

  public showEditOrderModal = false;

  private element: ElementRef;
  private currentOrder: any = null;

  private userMessage = '';

  sortState = {
    name: true,
    date: true,
    id: true,
  };

  @Input() companyOrders: Order[] | [] | undefined  = [];
  @Input() company: Company | any;
  @Input() archiveIsActive = false;
  @Input() showActions = false;
  @Input() showId = true;
  @Input() allowArchive = false;

  @Output() selectOrder: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteCompanyOrder: EventEmitter<any> = new EventEmitter<any>();
  @Output() archiveCompanyOrder: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('orderDateLabel') orderDateLabel!: ElementRef;

  constructor(elem: ElementRef, private translate: TranslateService) {
    this.element = elem;
  }

  ngAfterViewInit(): void {
    // this.sortList('date', this.orderDateLabel.nativeElement);
  }

  ngOnInit(): void {
  }

  public onDeleteCompanyOrder(event: Event, order: Order): void {
    event.stopPropagation();
    this.deleteCompanyOrder.emit(order);
  }

  public onArchiveCompanyOrder(event: Event, order: Order): void {
    event.stopPropagation();
    this.archiveCompanyOrder.emit(order);
  }

  public onSelectOrder(event: Event, order: Order): void {
    event.stopPropagation();
    this.selectOrder.emit(order);
  }

  public onEditCompanyOrder(event: Event, order: Order): void {
    event.stopPropagation();
    this.selectedOrder = order;
    this.showEditOrderModal = true;
  }

  companyEditFinished(order: Order): void {
    this.translate.get('COMPANY.EDIT_USER_SUCCESS').subscribe((success: string) => {
      this.userMessage = success;
    });

    const selectedOrderIndex = this.companyOrders?.findIndex(company => company === this.selectedOrder);
    this.companyOrders![selectedOrderIndex!] = order;

    this.showEditOrderModal = false;
  }

  public sortList(key: keyof Order, elem: HTMLSpanElement | any): void {
    this.element.nativeElement.querySelectorAll('.normal').forEach((element: Element) => element.classList.remove('up', 'down', 'active'));
    this.currentOrder = { key, elem };
    // @ts-ignore
    const compare = this.sortState[key] ? 1 : -1;
    if (elem) {
      elem.classList.add(compare < 1 ? 'up' : 'down', 'active');
    }
    this.companyOrders?.sort((a, b) => {
      if (a[key]! < b[key]!) { return compare; }
      if (a[key]! > b[key]!) { return -compare; }
      return 0;
    });
    // @ts-ignore
    this.sortState[key] = !this.sortState[key];
  }
}
