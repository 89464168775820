import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTestKitNo'
})
export class FormatTestKitNoPipe implements PipeTransform {

  transform(testKitNo: string, ...args: any[]): unknown {

    if (!testKitNo) {
      return '-';
    }
    const companyId = args[0];
    const orderId = args[1];
    const testKitNoStart = testKitNo.toString().slice(0, 6);
    const testKitNoEnd = testKitNo.toString().slice(6);

    if (companyId.toString().slice(0, 3) + orderId.toString().slice(0, 3) === testKitNoStart) {
      return `<span>${testKitNoStart} <b>${testKitNoEnd}</b></span>`;
    }

    return `<span>${testKitNo}</span>`;
  }

}
