<div class="wrapper">
  <h1>{{ 'COMPANYADMIN.TITLE' | translate }}</h1>

  <h4 *ngIf="selectedOrder && !showOrderListContainer">Auftrag: {{ selectedOrder?.name }}</h4>

  <div *ngIf="infoMessage">
    <b>{{ infoMessage }}</b><br>
  </div>

  <!-- Error Message -->

  <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <!-- Header -->

  <div class="center no-margin">
    <p *ngIf='!this.sucessfullyDone'>{{ description }}</p>
    <p class="alert alert-success" role="alert" *ngIf='this.sucessfullyDone'>{{ description }}</p>
  </div>

  <div class="btn-container">

    <ng-container *ngIf="showOrderListContainer">
      <div class="">
        <button class="custom-btn rounded add-btn" (click)="showAddOrderDialog = true">+</button>
      </div>

      <div class="input-btn-wrap" *ngxPermissionsOnly="P.DOWNLOAD_CSV_TESTUSER_DATA">
        <input #testDateInput type="text" class="rounded" placeholder="DD.MM.YYYY" (keyup.enter)="downloadCsvTestuserData(testDateInput.value)"/>
        <button class="custom-btn rounded" style="width: auto;" (click)="downloadCsvTestuserData(testDateInput.value)"><fa-icon [icon]="faFileCsv">
        </fa-icon> {{ 'COMPANY.EXPORT_TESTUSER_CSV' | translate }}
        </button>
      </div>

      <div class="">
        <button *ngxPermissionsOnly="P.ARCHIVE_COMPANY_ORDERS" class="custom-btn rounded" style="width: 100%;" (click)="switchArchive()"><fa-icon [icon]="faFolderOpen"></fa-icon> {{ archiveBtnLabel | translate }}</button>
      </div>

    </ng-container>

    <div class="input-btn-wrap" *ngIf="showOrderListContainer || showSearchResultsContainer" [class.search-bar-result-fix]="showSearchResultsContainer">
      <input #testSearchInput type="text" class="rounded" [placeholder]="'INFOPOINT.SEARCH' | translate" (keyup.enter)="searchTestuserLastname()"/>
      <select class="button-group-select" #testSearchFilterInput>
        <option value="lastname">Nachname</option>
        <option value="orderid">Bestellung</option>
        <option value="testkitno">Testkitnummer</option>
      </select>
      <button class="custom-btn rounded icon-only-btn" style="width: auto;" (click)="searchTestuserLastname()">
        <fa-icon [icon]="faSearch"></fa-icon>
      </button>
    </div>

  </div>

  <!-- Order List Container -->

  <div [hidden]="!showOrderListContainer">
    <app-company-order-table
      *ngIf="showOrderListContainer"
      [showId]="false"
      [showActions]="false"
      [companyOrders]="orders"
      (selectOrder)="onSelectOrder($event)"
    ></app-company-order-table>
  </div>

  <!-- Search Lastname Container -->

  <div *ngIf="showSearchResultsContainer && foundPatients">
    <app-testuser-table
      (escape)="cancel()"
      [patients]="foundPatients"
      [allowArchive]="false"
      [showExport]="false"
      [disableCheckIn]="true"
    ></app-testuser-table>
  </div>

  <!-- Employee List Container -->

  <div [hidden]="!showEmployeeListContainer">
    <app-testuser-table
      *ngIf="showEmployeeListContainer"
      (clickAddUser)="addEmployee()"
      (clickUploadCSV)="showUploadDialog = true"
      [showAddButtons]="canAddTestuser"
      [autoRefresh]="true"
      [showExport]="showExportResults"
      [selectedCompany]="company"
      [selectedOrder]="selectedOrder"
      (escape)="cancel()"
    ></app-testuser-table>
  </div>

  <!-- Order Entry Container -->

  <app-modal *ngIf="showAddOrderDialog" (closeClicked)="showAddOrderDialog = false">
    <app-company-order-add [company]="company" (submitFinished)="addOrder($event)"></app-company-order-add>
  </app-modal>

  <!-- Add Entry Container -->

  <div [hidden]="!showAddContainer">
   <app-testuser-edit *ngIf="showAddContainer" [addMode]="true" [orderId]="selectedOrder?.id" [companyId]="selectedOrder?.company_id" (submitFinished)="userAdded()"></app-testuser-edit>
    <br>
  </div>

  <!-- Upload Modal -->

  <app-modal *ngIf="showUploadDialog" (closeClicked)="showUploadDialog = false">
    <app-add-company-testuser-csv [selectedOrder]="selectedOrder" (done)="csvUploadDone()" (errorEmitter)="csvError($event)"></app-add-company-testuser-csv>
  </app-modal>

  <app-back-button *ngIf="!showOrderListContainer" (click)="cancel()"></app-back-button>
</div>
