import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../user.service';
import { CompanyService } from '../../company.service';
import { AuthenticationService } from '../../authentication.service';
import { Person } from '../../models/person';
import { Company } from '../../models/company';

@Component({
  selector: 'app-infopoint-search',
  templateUrl: './infopoint-search.component.html',
  styleUrls: ['./infopoint-search.component.scss']
})
export class InfopointSearchComponent implements OnInit, AfterViewInit {

  @ViewChild('searchInput') searchInput!: ElementRef;

  public faSearch: IconDefinition = faSearch;
  foundPatients: Person[] | any = [];
  company: Company = {};
  privateKey = '';

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private companyService: CompanyService,
    private authenticationService: AuthenticationService,
  ) {
    if (this.authenticationService.getPrivateKey() !== '') { this.privateKey = this.authenticationService.getPrivateKey(); }
  }

  errorMessage = '';

  ngOnInit(): void {
    this.company = this.companyService.currentCompany;
  }

  ngAfterViewInit(): void {
    this.searchInput.nativeElement.focus();
  }

  searchTestuserLastname(search: string): void {
    this.errorMessage = '';

    this.companyService.getCompanyOrderTestsBySearch(search, null, this.company.id, this.privateKey).subscribe((res: any) => {
      if (res.data.length) {
        this.foundPatients = res.data;
      }  else {
        this.foundPatients = [];
        this.translate.get('INFOPOINT.NO_RESULTS').subscribe((translation) => this.errorMessage = translation);
      }
    });
  }

}
