import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { Person } from './models/person';
import { Company } from './models/company';
import { AuthenticatedUser } from './authenticated-user';
import { UserService } from './user.service';
import { AuthenticationService } from './authentication.service';
import { Order } from './models/order';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends ApiService {

  constructor(http: HttpClient, private userService: UserService) {
    super(http);
  }

  get currentCompany(): Company {
    const user = JSON.parse(sessionStorage.getItem('user') || '{}');
    return {
      id: user.companyId,
      name: user.companyName,
      isWalkIn: user.companyIsWalkIn,
      uuid: user.companyUuid,
    };
  }

  public getApplianceList(companyId = this.currentCompany.id ): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/verification/getApplianceList', { companyId });
  }

  public createPassportVerification(applianceId = this.currentCompany.id ): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/verification/create', { applianceId });
  }

  public getPassportVerificationStatus(verificationId: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/verification/status/' + verificationId);
  }

  public getVerificationResult(verificationId: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/verification/result/' + verificationId);
  }

  public searchOrdersByLastname(search: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/searchTests', { search });
  }

  public getCompanies(includeArchivedOrders = false, includeArchivedCompanies = false): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company', { includeArchivedOrders, includeArchivedCompanies });
  }

  public getAllCompanies(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/company/all');
  }

  public deleteCompany(companyId: any): Observable<any> {
    return this.http.delete<any>(this.baseUrl + '/company/' + companyId);
  }

  public archiveCompany(companyId: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/archive/' + companyId, {});
  }

  public unArchiveCompany(companyId: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/unarchive/' + companyId, {});
  }

  public deleteCompanyOrder(companyOrderId: any): Observable<any> {
    return this.http.delete<any>(this.baseUrl + '/company/order/' + companyOrderId);
  }

  public archiveCompanyOrder(companyId: any, companyOrderId: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/archiveOrder/' + companyId + '/' + companyOrderId, {});
  }

  public deleteCompanyTestUser(companyId: any, companyOrderId: any, orderId: any): Observable<any> {
    return this.http.delete<any>(this.baseUrl + '/company/test/' + companyId + '/' + companyOrderId + '/' + orderId);
  }

  public getCompanyOrders(includeArchives = false): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company', { includeArchivedOrders: includeArchives });
  }

  public getFreeCheckOrders(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/company/freeCheck');
  }

  public resetFreeCheckOrder(id: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl + '/company/freeCheck/' + id);
  }

  public getCompanyTestuserDataByTestdate(date: string, companyId: any, privateKey: any = null, filter = 'sc', billingMode = false): Observable<any> {

    const query = filter ? '?filter=' + filter : '';

    return this.http.post<any>(this.baseUrl + '/company/order/testuserData/' + date + query, {
      company_id: companyId,
      private_key: privateKey,
      billing_mode: billingMode,
    }, { observe: 'response' });
  }

  public getOrder(id: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/company/' + id.trim() + '/tests');
  }

  public getTestresultPdf(testType: any, companyOrderId: any, orderId: any, privateKey: any): Observable<any>  {
    return this.http.post<any>(this.baseUrl + '/company/getTestresultPdf', {
      test_type: testType,
      company_order_id: companyOrderId,
      private_key: privateKey,
      order_id: orderId,
    });
  }

  public updateTestresultReceivedStatus(orderId: any): Observable<any>  {
    return this.http.post<any>(this.baseUrl + '/company/updateTestreceivedStatus', {
      order_id: orderId
    });
  }

  public getInvoicePdf(orderId: any, companyId: any): Observable<any>  {
    return this.http.post<any>(this.baseUrl + '/company/invoice/' + orderId, {
      companyId,
    });
  }

  public resendTestresult(
    privateKey: any,
    companyOrderId: any,
    orderId: any,
    birthday: any,
    sex: any,
    email: any,
    phone: any,
    idCarNo: any,
    address: any,
    zip: any,
    country: any,
    city: any,
    firstname: any,
    lastname: any,
  ): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/resendTestresult', {
      private_key: privateKey,
      company_order_id: companyOrderId,
      order_id: orderId,
      birthday,
      salutation: sex,
      email,
      phone,
      idcardno: idCarNo,
      address,
      zip,
      country,
      city,
      firstname,
      lastname,
    });
  }

  public editCompanyEmployee(person: Person, pk: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/editEmployee/' + person.test_user_id.trim(), {
      firstname: person.firstname,
      lastname: person.lastname,
      birthday: person.birthday,
      email: person.email,
      phone: person.phone,
      idcardno: person.idcardno,
      address: person.address,
      zip: person.zip,
      country: person.country,
      city: person.city,
      sex: person.sex,
      private_key: pk,
      reason: person.reason
    });
  }

  public editCompanyOrder(order: Order): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/editOrder/' + order.id, {
      name: order.name,
      date: order.date,
      max_users: order.selfcheckin_max_users,
      consecutive_order_ids: order.consecutive_order_ids,
      self_checkin: order.selfcheckin,
      is_walkin: order.is_walk_in,
      company_id: order.company_id,
      minimal_required_fields: order.minimal_required_fields
    });
  }

  public editCompany(company: Company): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/edit/' + company.id, {
      name: company.name,
      name_oegd: company.name_oegd,
      testcenter_id: company.testcenter_id,
      description: company.description,
      street: company.street,
      postalCode: company.postalCode,
      city: company.city,
      isWalkIn: company.isWalkIn,
      multipleOrderUseAllowed: company.multipleOrderUseAllowed,
    });
  }

  public addCompanyEmployee(person: Person | any, companyId: any, orderId: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/addEmployee', {
      company_id: companyId,
      company_order_id: orderId ?? null,
      firstname: person.firstname,
      lastname: person.lastname,
      sex: person.sex,
      job_function: person.job_function,
      birthday: person.birthday,
      email: person.email,
      test_type: person.test_type,
      idcardno: person.idcardno,
      address: person.address,
      phone: person.phone,
      zip: person.zip,
      country: person.country,
      city: person.city,
      test_time: person.test_time,
      test_location: person.test_location,
      reason: person.reason,
    });
  }

  public uploadCompanyEmployees(people: any[], companyId: any, orderId: any): Observable<any> {

    return this.http.post<any>(this.baseUrl + '/company/uploadEmployees', {
      company_id: companyId,
      company_order_id: orderId ?? null,
      data: people
    }, {
      headers: { nospinner: '' }
    });
  }

  public addCompanyOrder(
    id: number, name: string,
    date: string,
    selfCheckin: boolean,
    selfCheckinMaxUsers: number,
    isWalkIn: boolean,
    hasConsecutiveOrderIds: boolean,
    minimalRequiredFields: boolean
  ): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/addOrder', {
      company_id: id,
      name,
      date,
      self_checkin: selfCheckin,
      self_checkin_max_users: selfCheckinMaxUsers,
      is_walkin: isWalkIn,
      consecutive_order_ids: hasConsecutiveOrderIds,
      minimal_required_fields: minimalRequiredFields,
    });
  }

  public addCompany(
    name: string,
    oegdName: string,
    testcenterId: string,
    street: string,
    postalCode: string,
    city: string,
    description: string,
    isWalkIn: boolean,
    skipFreeCheck: boolean,
    contact: any,
    multipleOrderUseAllowed: boolean,
  ): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/add', {
      name,
      oegd_name: oegdName,
      testcenter_id: testcenterId,
      street,
      postal_code: postalCode,
      city,
      description,
      is_walk_in: isWalkIn,
      skip_free_check: skipFreeCheck,
      contact,
      multiple_order_use_allowed: multipleOrderUseAllowed,
    });
  }

  public getCompanyOrderTests(orderId: any, companyId: any, privateKey: any = null): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/' + companyId + '/tests', {
      company_order_id: orderId,
      private_key: privateKey,
    });
  }

  public getCompanyOrderTestsBySearch(search: any, filter: any, companyId: any, privateKey: any = null): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/' + companyId + '/tests', {
      private_key: privateKey,
      filter,
      search
    });
  }

  public getTestUserConsent(companyId: any, orderId: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/privacy', {
      company_id: companyId, order_id: orderId
    });
  }

  public exportResults(companyId: any, orderId: any, privateKey: any = null): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/' + orderId + '/export', {
      company_id: companyId,
      private_key: privateKey,
    });
  }

  public getStatisticsByDate(dateFrom: string, dateTo: string, statisticsType: string, statisticsBase: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/statistics', {
      date_from: dateFrom,
      date_to: dateTo,
      statistics_type: statisticsType,
      statistics_base: statisticsBase
    }, { observe: 'response' });
  }

  public downloadStatisticsByDate(dateFrom: string, dateTo: string, statisticsType: string, statisticsBase: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/downloadStatisticsPdf', {
      date_from: dateFrom,
      date_to: dateTo,
      statistics_type: statisticsType,
      statistics_base: statisticsBase
    }, { observe: 'response' });
  }

  public getFreeCheckOrdersBySearch(search: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/searchFreeChecks', {
      search
    });
  }
}
