<div class="wrapper">

  <h1>{{ 'FREE_CHECK_RESET.TITLE' | translate }}</h1>

  <app-alert *ngIf="successMessage" [message]="successMessage" [type]="'success'"></app-alert>
  <app-alert *ngIf="errorMessage" [message]="errorMessage"></app-alert>

  <div class="btn-container">
    <div class="input-btn-wrap">
      <input #lastnameSearchInput type="text" class="rounded" [placeholder]="'FREE_CHECK_RESET.SEARCH' | translate" (keyup.enter)="searchFreeCheckLastname(lastnameSearchInput.value)"/>
      <button class="custom-btn rounded" style="width: auto;" (click)="searchFreeCheckLastname(lastnameSearchInput.value)"><fa-icon [icon]="faSearch">
      </fa-icon>
      </button>
    </div>
  </div>

  <div class="center">
    <ul class="header">
      <li>
         <span class="normal">{{ 'COMPANYADMIN.EMPLOYEE_FIRST' | translate }}</span>
         <span class="normal">{{ 'COMPANYADMIN.EMPLOYEE_LAST' | translate }}</span>
         <span class="normal">{{ 'FREE_CHECK_RESET.BIRTH' | translate }}</span>
         <span class="normal">{{ 'FREE_CHECK_RESET.LAST_DATE' | translate }}</span>
      </li>
    </ul>

    <div class="divider"></div>

    <ul class="list">
      <li *ngFor="let person of filteredPersons" (click)="onSelectPerson(person)">
        <span class="item normal">{{person.firstname}}</span>
        <span class="item normal">{{person.lastname}}</span>
        <span class="item normal">{{person.birthday | date: 'dd.MM.yyyy'}}</span>
        <span class="item normal">{{person.last_test_date | date: 'dd.MM.yyyy' }}</span>
      </li>
    </ul>
  </div>

</div>
