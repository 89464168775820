<h3>Testergebnis versenden</h3>
<b>Daten kontrollieren und Resultat erneut zuschicken</b><br><br>

<app-alert *ngIf="error" [error]="error" [type]="'danger'"></app-alert>

<form class="row g-3" [formGroup]="form">
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYADMIN.EMPLOYEE_SEX' | translate }}</label>
    <select class="form-control" formControlName="sex">
      <option [ngValue]="null" disabled selected>{{ 'COMPANYADMIN.CHOOSE_LABEL' | translate }}</option>
      <option value="ms">weiblich</option>
      <option value="mr">männlich</option>
      <option value="d">divers</option>
    </select>
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_IDCARDNO' | translate }}</label>
    <input type="text" class="form-control" formControlName="idcardno">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_FIRST' | translate }}</label>
    <input type="text" class="form-control" formControlName="firstname">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_LAST' | translate }}</label>
    <input type="text" class="form-control" formControlName="lastname">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_BIRTH' | translate }}</label>
    <input type="text" class="form-control" required formControlName="birthday">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_EMAIL' | translate }}</label>
    <input type="text" class="form-control" formControlName="email">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYADMIN.EMPLOYEE_PHONE' | translate }}</label>
    <input type="text" class="form-control" formControlName="phone">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYADMIN.EMPLOYEE_ADDRESS' | translate }}</label>
    <input type="text" class="form-control" formControlName="address">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_ZIP' | translate }}</label>
    <input type="text" class="form-control" formControlName="zip">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_COUNTRY' | translate }}</label>
    <select class="form-control" formControlName="country">
      <option *ngFor="let country of countries" [value]="country.name">{{ country.name }}</option>
    </select>
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_CITY' | translate }}</label>
    <input type="text" class="form-control" formControlName="city">
  </div>

  <div class="col-12">
    <button [disabled]="!form.valid" class="custom-btn rounded" (click)="submit()">{{ 'TESTRESULT.BTN_SUBMIT' | translate }}</button>
  </div>
</form>
