import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'teststatus'
})
export class TeststatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    if (value === '0') {
      return 'nicht eingecheckt';
    }
    if (value === '1') {
      return 'eingecheckt';
    }
    if (value === '2') {
      return 'abgeschlossen';
    }

    return value;
  }

}
