<div class="wrapper">
  <h1>{{ 'INFOPOINT.SEARCH' | translate }}</h1><br>

  <app-alert *ngIf="errorMessage" [message]="errorMessage"></app-alert>

  <div class="btn-container">
    <div class="input-btn-wrap">
      <input #searchInput type="text" class="rounded" [placeholder]="'COMPANY.SEARCH_LASTNAME_ORDER_ID' | translate" (keyup.enter)="searchTestuserLastname(searchInput.value)"/>
      <button class="custom-btn rounded" style="width: auto;" (click)="searchTestuserLastname(searchInput.value)"><fa-icon [icon]="faSearch">
      </fa-icon>
      </button>
    </div>
  </div>

  <div *ngIf="foundPatients.length">
    <app-testuser-table
      [infopoint]="true"
      [patients]="foundPatients"
      [allowArchive]="false"
      [showExport]="false"
      [allowDelete]="false"
      [allowAddNewTestPCR]="true"
    ></app-testuser-table>
  </div>

</div>
