import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../company.service';
import { TranslateService } from '@ngx-translate/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-free-check-reset',
  templateUrl: './free-check-reset.component.html',
  styleUrls: ['./free-check-reset.component.css']
})
export class FreeCheckResetComponent implements OnInit {

  faSearch: IconDefinition = faSearch;
  errorMessage = '';
  successMessage = '';
  persons: FreeCheckPerson[] | [] = [];
  filteredPersons: FreeCheckPerson[] | [] = [];

  constructor(private companyService: CompanyService, private translate: TranslateService,) { }

  ngOnInit(): void {
    // this.companyService.getFreeCheckOrders().subscribe(
    //   (res: any) => {
    //     this.persons = res.data;
    //     this.filteredPersons = this.persons;
    //   },
    //   (error: any) => this.errorMessage = error.error.error.description ?? 'Error');
  }

  searchFreeCheckLastname(search: string): void {
    this.errorMessage = '';
    this.companyService.getFreeCheckOrdersBySearch(search).subscribe((res: any) => {
      this.persons = res.data;
      this.filteredPersons = this.persons;
      if (!this.filteredPersons.length) {
        this.translate.get('FREE_CHECK_RESET.NOT_FOUND').subscribe((text: string) => {
          this.errorMessage = text;
        });
      }
    },
    (error: any) => this.errorMessage = error.error.error.description ?? 'Error');
  }

  onSelectPerson(person: FreeCheckPerson): void {
    this.reset();

    this.translate.get('FREE_CHECK_RESET.RESET_PERSON').subscribe((text: string) => {
      if (confirm(text)) {
        this.companyService.resetFreeCheckOrder(person.id).subscribe(
          (res: any) => this.translate.get('FREE_CHECK_RESET.SUCCESS').subscribe((success: string) => {
            this.successMessage = success;
            this.filteredPersons = this.filteredPersons.filter(item => item.id !== person.id);
          }),
          (error: any) => this.errorMessage = error.error.error.description ?? 'Error'
        );
      }
    });
  }

  reset(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }
}

export interface FreeCheckPerson {
  id: number;
  firstname: string;
  lastname: string;
  birthday: string;
  last_test_date: string;
}
