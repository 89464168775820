import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../user.service';
import * as moment from 'moment';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.css']
})
export class OrderEditComponent implements OnInit {
  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();
  @Input() activeTest: any;
  @Input() pk: any;

  form!: FormGroup;
  errorMessage = '';
  errorMessageResult = '';
  resultEditor = false;
  showResultContainer = false;
  showResultCheckContainer = false;
  showSummaryContainer = false;
  showResultEditButton = false;

  formErrors = {
    additional_address_line1: false
  };

  orderVO = {
    order_firstname: '',
    order_lastname: '',
    additional_address_line1: '',
    idcardno: '',
    email: '',
    phone: '',
    street: '',
    zipcode: '',
    city: '',
    salutation: '',
    result: ''
  };

  updatedOrderVO = {
    order_firstname: '',
    order_lastname: '',
    additional_address_line1: '',
    idcardno: '',
    email: '',
    phone: '',
    street: '',
    zipcode: '',
    city: '',
    salutation: '',
    result: ''
  };

  private result = '';
  summaryTestKit = '';
  summaryResult = '';
  summaryType = '';
  type = '';

  constructor(private fb: FormBuilder, private translate: TranslateService, private userService: UserService) { }

  ngOnInit(): void {
    this.orderVO = this.activeTest.encrypted_spi ? this.activeTest.encrypted_spi.testuser_pi : this.activeTest.testuser_pi;
    this.type = this.activeTest.test_type;
    if (this.type != 'pcr-exp' && this.type != 'pcr-std' && this.activeTest.status == 'done') this.showResultEditButton = true;
    let oldResult = null;
    if (this.activeTest.encrypted_spi) oldResult = (this.type == 'pcr-exp' || this.type == 'pcr-std') ? this.activeTest.encrypted_spi.testresult.result : this.activeTest.encrypted_spi.testresult;
    this.orderVO.additional_address_line1 = moment(this.orderVO.additional_address_line1, 'YYYY-MM-DD').format('DD.MM.YYYY');
    this.form = this.fb.group({
      salutation: [this.orderVO.salutation ?? 'd', [Validators.required]],
      firstname: [this.orderVO.order_firstname, [Validators.required]],
      lastname: [this.orderVO.order_lastname, [Validators.required]],
      idcardno: [this.orderVO.idcardno],
      email: [this.orderVO.email, [Validators.required]],
      phone: [this.orderVO.phone, [Validators.required]],
      street: [this.orderVO.street, [Validators.required]],
      zipcode: [this.orderVO.zipcode, [Validators.required]],
      city: [this.orderVO.city, [Validators.required]],
      additional_address_line1: [this.orderVO.additional_address_line1], //used for birthday
      type: this.type,
      result: oldResult
    });
  }



  save(): void {
    this.updatedOrderVO.salutation = (this.form.controls.salutation.value != this.orderVO.salutation) ? this.form.controls.salutation.value : null;
    this.updatedOrderVO.order_firstname = (this.form.controls.firstname.value != this.orderVO.order_firstname) ? this.form.controls.firstname.value : null;
    this.updatedOrderVO.order_lastname = (this.form.controls.lastname.value != this.orderVO.order_lastname) ? this.form.controls.lastname.value : null;
    this.updatedOrderVO.idcardno = (this.form.controls.idcardno.value != this.orderVO.idcardno) ? this.form.controls.idcardno.value : null;
    this.updatedOrderVO.email = (this.form.controls.email.value != this.orderVO.email) ? this.form.controls.email.value : null;
    this.updatedOrderVO.phone = (this.form.controls.phone.value != this.orderVO.phone) ? this.form.controls.phone.value : null;
    this.updatedOrderVO.street = (this.form.controls.street.value != this.orderVO.street) ? this.form.controls.street.value : null;
    this.updatedOrderVO.zipcode = (this.form.controls.zipcode.value != this.orderVO.zipcode) ? this.form.controls.zipcode.value : null;
    this.updatedOrderVO.city = (this.form.controls.city.value != this.orderVO.city) ? this.form.controls.city.value : null;
    this.updatedOrderVO.additional_address_line1 = (this.form.controls.additional_address_line1.value != this.orderVO.additional_address_line1) ? this.form.controls.additional_address_line1.value : null;
    this.updatedOrderVO.result = (this.form.controls.result.value != this.orderVO.result) ? this.form.controls.result.value : null;

    let submit = true;

    const submitData = JSON.parse(JSON.stringify(this.updatedOrderVO));

    if (this.updatedOrderVO.additional_address_line1 !== null) {
      if (!moment(this.updatedOrderVO.additional_address_line1, 'DD.MM.YYYY', 'de', true).isValid())
      {
        this.formErrors.additional_address_line1 = true;
        submit = false;
      } else {
        submitData.additional_address_line1 = moment(submitData.additional_address_line1, 'DD.MM.YYYY').format('YYYY-MM-DD');
      }
    }

    if (submit) {
      if (this.activeTest.encrypted_spi){
        this.activeTest.encrypted_spi.testuser_pi = submitData;
        // this.activeTest.encrypted_spi.testresult = this.result;
      } else {
        this.activeTest.testuser_pi = submitData;
      }

      const data = this.activeTest.encrypted_spi ? this.activeTest.encrypted_spi : this.activeTest.testuser_pi;
      const encrypted = this.activeTest.encrypted_spi ? true : false;
      const testtype = this.activeTest.test_type;

      this.userService.updateTestuser(this.activeTest.order_id, data, encrypted, this.activeTest.test_id, this.pk, testtype, this.activeTest.company_order, this.activeTest.employee_id)
        .subscribe((res: any) => {
            if (this.updatedOrderVO.salutation !== null) this.orderVO.salutation = this.updatedOrderVO.salutation;
            if (this.updatedOrderVO.order_firstname !== null) this.orderVO.order_firstname = this.updatedOrderVO.order_firstname;
            if (this.updatedOrderVO.order_lastname !== null) this.orderVO.order_lastname = this.updatedOrderVO.order_lastname;
            if (this.updatedOrderVO.idcardno !== null) this.orderVO.idcardno = this.updatedOrderVO.idcardno;
            if (this.updatedOrderVO.email !== null) this.orderVO.email = this.updatedOrderVO.email;
            if (this.updatedOrderVO.phone !== null) this.orderVO.phone = this.updatedOrderVO.phone;
            if (this.updatedOrderVO.street !== null) this.orderVO.street = this.updatedOrderVO.street;
            if (this.updatedOrderVO.zipcode !== null) this.orderVO.zipcode = this.updatedOrderVO.zipcode;
            if (this.updatedOrderVO.city !== null) this.orderVO.city = this.updatedOrderVO.city;
            if (this.updatedOrderVO.additional_address_line1 !== null) this.orderVO.additional_address_line1 = this.updatedOrderVO.additional_address_line1;
            if (this.updatedOrderVO.result !== null) this.orderVO.result = this.updatedOrderVO.result;

            this.orderVO.additional_address_line1 = moment(this.orderVO.additional_address_line1, 'DD.MM.YYYY').format('YYYY-MM-DD');
            let encFlag = (this.activeTest.encrypted_spi) ? true : false;
            this.submitFinished.emit([this.orderVO, encFlag, this.activeTest.test_id]);
        });
    }
  }

  showResultEditor(): void {
    this.errorMessageResult = '';
    this.showResultContainer = true;
  }

  resultSubmit(result: string): void {
    this.result = result;
    this.errorMessageResult = '';
    this.changeVisibility(false, true, true, false);
  }

  resultCheckSubmit(result: string): void {
    if (this.result === result) {
      this.summaryResult = this.result;
      this.changeVisibility(false, false, false, false);
      this.form.controls.result.setValue(this.result);
    } else {
      this.changeVisibility(false, false);
      this.translate.get('TESTRESULT.TEXT_RESULT_ERROR').subscribe((text: string) => {
        this.errorMessageResult = text;
      });
    }
  }

  private changeVisibility(testKit = false, result = false, resultCheck = false, summary = false): void {
    this.showResultContainer = result;
    this.showResultCheckContainer = resultCheck;
    this.showSummaryContainer = summary;

    if (testKit) {
      //ignore
    } else if (result) {
      // testkit type translation
      let translation = '';
      switch (this.type) {
        case 'antibody':
          translation = 'TEST_TYPES.ANTIBODY';
          break;
        case 'antigen-free':
        case 'antigen':
          translation = 'TEST_TYPES.ANTIGEN';
          break;
        default:
          return;
      }
      this.translate.get(translation).subscribe((text: string) => {
        this.summaryType = text;
      });
    } else if (summary) {
      //ignore
    }
  }
}
