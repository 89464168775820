import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../authentication.service';
import { PERMISSIONS_BY_ROLES } from '../roles';
import { NgxRolesService } from 'ngx-permissions';

import { version } from './../../../package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  appVersion = version;

  private readonly STORAGE_LOCATION_ID_KEY = 'current_location_id';

  faKey = faKey;
  faUser = faUser;

  loginForm: FormGroup;

  submitted = false;
  error = '';

  constructor(
    private rolesService: NgxRolesService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private translate: TranslateService) {
      this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
      });
    }

  ngOnInit(): void {
  }

  get f(): any { return this.loginForm.controls; }

  onSubmit(): void {
    this.error = '';
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.spinner.show();

    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .subscribe(
        () => {
          localStorage.removeItem(this.STORAGE_LOCATION_ID_KEY);
          this.spinner.hide();
          const privkey = this.authenticationService.getLoggedInUser().privkey;
          const role = this.authenticationService.getLoggedInUser().role;
          this.rolesService.flushRolesAndPermissions();
          this.rolesService.addRoleWithPermissions(role, PERMISSIONS_BY_ROLES[role] as string[]);
          if (this.authenticationService.getLoggedInUser().privkey === 0) {
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/login/privkey', {privkey}]);
          }
        },
        errorResponse => {
          const error = errorResponse.error.error.description;
          if (errorResponse.status === 401) {
            this.translate.get('LOGIN.CREDENTIALS_ERROR').subscribe((translation) => this.error = translation, (err) => this.error = error);
          } else {
            this.error = error;
          }
          this.spinner.hide();
        });
  }
}
