import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import localeDe from '@angular/common/locales/de';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptorService } from './auth-interceptor.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilizationComponent } from './utilization/utilization.component';
import { LoadingInterceptor } from './loading-interceptor.service';
import { VersionInterceptorService } from './version-interceptor.service';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { registerLocaleData } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LocationInterceptorService } from './location-interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QrCodeModule } from 'ng-qrcode';
import { HttpErrorInterceptor } from './http-error-interceptor.service';
import { UsermgmtComponent } from './usermgmt/usermgmt/usermgmt.component';
import { SharedModule } from './shared-module/shared-module.module';
import { UserEditComponent } from './usermgmt/user-edit/user-edit.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeDe);

@NgModule({
    declarations: [
        AppComponent,
        UtilizationComponent,
        UsermgmtComponent,
        UserEditComponent,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxPermissionsModule.forRoot(),
    QrCodeModule,
    AngularStickyThingsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule
  ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: LocationInterceptorService, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: VersionInterceptorService, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: 'de'},
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
