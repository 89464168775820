import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Person } from '../../../models/person';
import { CompanyService } from '../../../company.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AuthenticationService } from '../../../authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mapSexToSalutation } from '../../../helper';
import * as COUNTRIES from '../testuser-edit/countries.json';

@Component({
  selector: 'app-resend-result',
  templateUrl: './resend-result.component.html',
  styleUrls: ['./resend-result.component.css']
})
export class ResendResultComponent implements OnInit {

  public countries: any = (COUNTRIES as any).default;

  privateKey: any = '';
  error: any = null;
  form!: FormGroup;

  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();

  @Input() patient: Person | any;
  @Input() companyOderId: any;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.submitFinished.emit();
  }

  constructor(
    private companyService: CompanyService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
  ) {
    if (this.authenticationService.getPrivateKey() !== '') {
      this.privateKey = this.authenticationService.getPrivateKey();
    }
  }

  ngOnInit(): void {

    let birthday: any = moment(this.patient.birthday).format('DD.MM.yyyy');
    birthday = (birthday === 'Invalid date') ? this.patient.birthday : birthday;

    this.form = this.fb.group({
      birthday: [birthday, [Validators.required, Validators.pattern(/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/)]],
      email: [this.patient.email, [Validators.required, Validators.email]],
      zip: [this.patient.zip, [Validators.pattern('^[0-9]*$')]],
      city: [this.patient.city],
      country: [this.patient.country],
      firstname: [this.patient.firstname],
      lastname: [this.patient.lastname],
      address: [this.patient.address],
      idcardno: [this.patient.idcardno],
      phone: [this.patient.phone],
      sex: [mapSexToSalutation(this.patient?.sex ?? '') ?? ''],
    });
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }

    const birthday = moment(this.form.controls.birthday.value, 'DD.MM.YYYY').utc(true).toISOString() ?? '';
    this.error = null;

    this.companyService.resendTestresult(
      this.privateKey,
      this.companyOderId,
      this.patient.order_id,
      birthday,
      this.form.controls.sex.value,
      this.form.controls.email.value,
      this.form.controls.phone.value,
      this.form.controls.idcardno.value,
      this.form.controls.address.value,
      this.form.controls.zip.value,
      this.form.controls.country.value,
      this.form.controls.city.value,
      this.form.controls.firstname.value,
      this.form.controls.lastname.value,
    ).subscribe(response => {
      this.submitFinished.emit();
    }, error => {
      this.error = error;
    });
  }

}
