import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../user.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-testresult',
  templateUrl: './testresult.component.html',
  styleUrls: ['./testresult.component.css']
})
export class TestresultComponent implements OnInit {

  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('textInput') textInput!: ElementRef;
  @ViewChild('text') resultText!: ElementRef;

  showTestKistContainer = true;
  showResultContainer = false;
  showResultCheckContainer = false;
  showSummaryContainer = false;
  sucessfullyDone = false;

  testKitNumber = new FormControl();

  private result = '';
  description = '';
  errorMessage = '';
  serverErrorMessage = '';
  type = '';

  summaryTestKit = '';
  summaryResult = '';
  summaryType = '';

  @Input() isModal = false;

  @Input() set testKitId(id: any) {
    this.summaryTestKit = id;
    this.testKitNumber.setValue(id.trim());
  }

  @Input() set testType(type: string) {
    this.type = type;
    this.changeVisibility(false, true);
  }

  constructor(private spinner: NgxSpinnerService, private translate: TranslateService, private userService: UserService) { }

  ngOnInit(): void {
    if (!this.isModal) {
      this.reset();
    }
  }

  testKitSubmit(): void {
    this.testKitNumber.setValue(this.testKitNumber.value.trim());

    // check if valid
    if (this.testKitNumber.errors?.pattern !== undefined || this.testKitNumber.value === '') {
      this.showError(Errors.Validation);
      return;
    } else {
      this.errorMessage = '';
    }

    // get type of testkit from database
    this.checkTestkit(this.testKitNumber.value);
  }

  resultSubmit(result: string): void {
    this.result = result;
    this.errorMessage = '';
    this.changeVisibility(false, true, true, false);
  }

  resultCheckSubmit(result: string): void {
    if (this.result === result) {
      this.summaryResult = this.result;
      this.changeVisibility(false, false, false, true);
    } else {
      this.changeVisibility(false, true);
      this.translate.get('TESTRESULT.TEXT_RESULT_ERROR').subscribe((text: string) => {
        this.errorMessage = text;
      });
    }
  }

  submit(): void {
    this.userService.submitResult(this.testKitNumber.value, this.result)
      .subscribe(
        response => {
          this.errorMessage = '';
          this.sucessfullyDone = true;
          this.changeVisibility(false, false, false, false);
          this.translate.get('TESTRESULT.TEXT_SUCCESS').subscribe((text: string) => {
            this.description = text;
            this.submitFinished.emit({ testkit_id: this.testKitNumber.value, testresult: this.result} );
          });
        },
        errorResponse => {
          this.serverErrorMessage = errorResponse.error.error.description ?? errorResponse.message;
          this.showError(Errors.Errorcode, errorResponse);
        }
      );
  }

  cancel(): void {
    this.submitFinished.emit(false);
    this.reset();
  }

  private checkTestkit(id: string): void {
    this.userService.getTestkitInfo(id)
      .subscribe(
        response => {
          if (
            response.data.test_type.includes('antigen') ||
            response.data.test_type === 'antibody') {

            this.type = response.data.test_type;
            this.changeVisibility(false, true);
            this.errorMessage = '';

          } else if (response.data.test_type === 'pcr-exp' || response.data.test_type === 'pcr-std') {
            this.showError(Errors.PCR_Express, response.data.test_type);

          } else {
            this.showError(Errors.Errorcode, null);
          }
        },
        errorResponse => {
          this.showError(Errors.Errorcode, errorResponse);
        }
      );
  }

  private reset(): void {
    this.sucessfullyDone = false;
    this.testKitNumber.setValue('');
    this.result = '';
    this.errorMessage = '';
    this.type = '';
    this.summaryTestKit = '';
    this.summaryResult = '';
    this.summaryType = '';

    this.changeVisibility(true);
    this.spinner.hide();
    this.errorMessage = '';

    setTimeout(() => {
      this.textInput.nativeElement.focus();
    }, 0);
  }

  private changeVisibility(testKit = false, result = false, resultCheck = false, summary = false): void {
    this.showTestKistContainer = testKit;
    this.showResultContainer = result;
    this.showResultCheckContainer = resultCheck;
    this.showSummaryContainer = summary;

    if (testKit) {
      this.translate.get('TESTRESULT.TEXT_NUMBER_HEADER').subscribe((text: string) => {
        this.description = text;
      });

    } else if (result) {
      this.summaryTestKit = this.testKitNumber.value;

      // testkit type translation
      let translation = '';

      if (this.type.includes('antigen')) {
        translation = 'TEST_TYPES.ANTIGEN';
      } else if (this.type === 'antibody') {
        translation = 'TEST_TYPES.ANTIBODY';
      }

      this.translate.get(translation).subscribe((text: string) => {
        this.summaryType = text;
      });

      // description
      this.translate.get('TESTRESULT.TEXT_RESULT_HEADER').subscribe((text: string) => {
        this.description = text;
      });

    } else if (summary) {
      this.translate.get('TESTRESULT.TEXT_SUMMARY_HEADER').subscribe((text: string) => {
        this.description = text;
      });
    }
  }

  private showError(type: Errors, errorResponse: any = null): void {
    switch (type) {
      case Errors.PCR_Express:
        this.translate.get('TESTRESULT.PCR_EXP_ERROR').subscribe((text: string) => {
          this.errorMessage = text;
        });
        break;

      case Errors.Validation:
        this.translate.get('TESTMGMT.TEXT_ERROR_VALIDATION').subscribe((text: string) => {
          this.errorMessage = text;
        });
        break;

      case Errors.Errorcode:
        if (errorResponse.error?.error?.description === 'TEST_HAS_STATUS_2') {
          this.translate.get('TESTRESULT.TEST_HAS_STATUS_2').subscribe((text: string) => {
            this.errorMessage = text;
          });
        } else if (errorResponse.status === 404) {
          this.translate.get('TESTRESULT.MCS_ORDER_NOT_FOUND').subscribe((text: string) => {
            this.errorMessage = text;
          });
        } else if (errorResponse.error?.error?.description === 'COMPANY_TEST_NO_PERMISSION') {
          this.translate.get('TESTRESULT.COMPANY_TEST_NO_PERMISSION',
            { companyName: errorResponse.error.data.company_name, companyId: errorResponse.error.data.company_id })
            .subscribe((text: string) => this.errorMessage = text);
        } else {
          this.translate.get('TESTRESULT.UNKNOWN_ERROR').subscribe((text: string) => {
            this.errorMessage = text;
          });
        }
        break;
    }
  }
}

enum Errors {
  PCR_Express,
  Validation,
  Errorcode
}
