import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/**
 * Define the chunk method in the prototype of an array
 * that returns an array with arrays of the given size.
 *
 * @param chunkSize {Integer} Size of every group
 */
 Object.defineProperty(Array.prototype, 'chunk', {
  value: function(chunkSize : number){
      var temporal = [];
      
      for (var i = 0; i < this.length; i+= chunkSize){
          temporal.push(this.slice(i,i+chunkSize));
      }
              
      return temporal;
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
