import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AuthenticationService } from '../authentication.service';
import { CompanyService } from '../company.service';
import { Company } from '../models/company';
import * as FileSaver from 'file-saver';
import {PERMISSIONS} from '../roles';
import {NgxPermissionsService} from 'ngx-permissions';
import {UserService} from '../user.service';
import {tap} from 'rxjs/operators';


@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();

  showValidationError = false;

  errorMessage = '';
  serverErrorMessage = '';
  location = '';

  isCompanyUser = false;
  companyId: number | undefined;

  stats: Stat[] | any[] | any = [];
  days: string[] | [] = [];
  daystats: string[] | [] = [];

  totalCntArr: number[] | number[] = [];

  total_antigen = 0;
  total_antigen_free = 0;
  total_antigen_paid = 0;
  total_antigen_paid_reduced = 0;
  total_pcr_free = 0;
  total_pcr_paid = 0;
  total_pcr_std = 0;
  total_pcr_exp = 0;

  total_antigen_card = 0;
  total_antigen_card_use = 0;
  total_antigen_team_free = 0;
  total_antigen_team_paid = 0;

  total = 0;
  totalReasonsCount: any = [];
  totalReasons = null;

  companies: Company[] | [] = [];

  statisticsTypeCompanies = false;
  statisticsTypeHours = false;
  statisticsTypeDays = false;
  statisticsTypeReasons = false;

  public isShoppingcenter = false;

  today: number = Date.now();

  public readonly P = PERMISSIONS;
  public reasons: any;

  constructor(
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    private userService: UserService,
    private authService: AuthenticationService,
    private permissions: NgxPermissionsService,
  ) {
    this.location = this.authenticationService.getLoggedInUser().location;

    if (this.permissions.getPermission(this.P.READ_STATISTICS_ALL_COMPANIES)) {
      this.companyService.getCompanies().subscribe((res: any) => this.companies = res.data);
    } else {
      const user = this.authenticationService.getLoggedInUser();
      this.companies = [ { id: user.companyId, name: user.companyName }];
    }

    this.isShoppingcenter = this.authService.isLocationShoppingcenter();
  }

  ngOnInit(): void {
    this.reset();
  }

  validateDate(dateString: string): any {
    this.errorMessage = '';

    try {
      const dateTemp = moment(dateString, 'DD.MM.YYYY');
      if (isNaN(dateTemp.toDate().valueOf())) {
        throw new Error();
      }
      this.showValidationError = false;
      return dateTemp.format('YYYY-MM-DD');
    } catch (err: any) {
      this.showValidationError = true;
      return false;
    }
  }

  generateStatistics(dateFrom: string, dateTo: string, statisticsType: string, statisticsBase: string): void {
    switch (statisticsType) {
      case 'companies': this.statisticsTypeCompanies = true; this.statisticsTypeHours = false; this.statisticsTypeDays = false; this.statisticsTypeReasons = false; break;
      case 'hours': this.statisticsTypeHours = true; this.statisticsTypeCompanies = false; this.statisticsTypeDays = false; this.statisticsTypeReasons = false; break;
      case 'days': this.statisticsTypeDays = false; this.statisticsTypeCompanies = false; this.statisticsTypeHours = false; this.statisticsTypeReasons = false; break;
      case 'reasons': this.statisticsTypeReasons = true; this.statisticsTypeCompanies = false; this.statisticsTypeHours = false; this.statisticsTypeDays = false; break;
    }

    const dateTempFrom = (dateFrom != '') ? this.validateDate(dateFrom) : '';
    const dateTempTo = (dateTo != '') ? this.validateDate(dateTo) : '';
    if (dateTempFrom !== false && dateTempTo !== false) {
      if (statisticsType == 'days') { this.createStatisticsForDays(dateTempFrom, dateTempTo, statisticsType, statisticsBase); }
      else if (statisticsType == 'reasons') { this.createStatisticsForReasons(dateTempFrom, dateTempTo, statisticsType, statisticsBase); }
      else { this.createStatistics(dateTempFrom, dateTempTo, statisticsType, statisticsBase); }
    }
  }

  private daysBetween(from: string, to: string): any {
    const myDateFrom: Date = moment(from, 'YYYY-MM-DD').toDate();
    const myDateTo: Date = moment(to, 'YYYY-MM-DD').toDate();

    const fromDate = moment(myDateFrom).startOf('day');
    const toDate = moment(myDateTo).endOf('day');

    const span = moment.duration(toDate.diff(fromDate)).asDays();
    const days = [];
    for (let i = 0; i <= span; i++) {
      days.push(moment(fromDate).add(i, 'day').startOf('day').format('DD.MM.YYYY'));
    }
    return days;
  }

  private createStatistics(dateStringFrom: string, dateStringTo: string, statisticsType: string, statisticsBase: string): void {
    this.companyService.getStatisticsByDate(dateStringFrom, dateStringTo, statisticsType, statisticsBase)
      .subscribe((response: any) => {
        if (response.status === 204) {
          this.reset();
        } else {
          const resArr = response.body.data;
          this.total_antigen = 0;
          this.total_antigen_free = 0;
          this.total_pcr_free = 0;
          this.total_pcr_paid = 0;
          this.total_pcr_std = 0;
          this.total_pcr_exp = 0;

          this.total_antigen_paid = 0;
          this.total_antigen_card = 0;
          this.total_antigen_card_use = 0;
          this.total_antigen_team_free = 0;
          this.total_antigen_team_paid = 0;

          this.total  = 0;
          resArr.forEach((statRow: any) => {
            this.total_antigen += parseInt(statRow.num_antigen);
            this.total_antigen_free += parseInt(statRow.num_antigen_free);
            this.total_pcr_free += parseInt(statRow.num_pcr_free);
            this.total_pcr_paid += parseInt(statRow.num_pcr_paid);
            this.total_pcr_std += parseInt(statRow.num_pcr_std);
            this.total_pcr_exp += parseInt(statRow.num_pcr_exp);

            this.total_antigen_card += parseInt(statRow.num_antigen_card);
            this.total_antigen_paid += parseInt(statRow.num_antigen_paid);
            this.total_antigen_paid_reduced += parseInt(statRow.num_antigen_paid_reduced);
            this.total_antigen_card_use += parseInt(statRow.num_antigen_card_use);
            this.total_antigen_team_free += parseInt(statRow.num_antigen_team_free);
            this.total_antigen_team_paid += parseInt(statRow.num_antigen_team_paid);

            this.total += parseInt(statRow.num_total);
          });

          this.stats = resArr;
          this.submitFinished.emit();
        }
      },
        errorResponse => this.serverErrorMessage = errorResponse.message
      );
  }

  private createStatisticsForReasons(dateStringFrom: string, dateStringTo: string, statisticsType: string, statisticsBase: string): void {
    this.stats = [];
    this.total  = 0;
    this.totalReasonsCount = []
    this.userService.getTestReasons().pipe(
      tap(res => {
        this.totalReasons = res.data.length;
        this.reasons = res.data
      }),
      tap((res) => {
        this.companyService.getStatisticsByDate(dateStringFrom, dateStringTo, statisticsType, statisticsBase)
          .subscribe((response: any) => {
              if (response.status === 204) {
                this.reset();
              } else {
                const resArr = response.body.data;

                resArr.forEach((statRow: any) => {

                  if (statRow[2] > 0) {
                    this.stats.push({
                      company_name: statRow[0],
                      reasons: statRow[1],
                      total: statRow[2],
                    });
                  }

                  for (let i = 0; i < this.totalReasons!; i++) {
                    if (!this.totalReasonsCount[i]) {
                      this.totalReasonsCount[i] = 0;
                    }
                    this.totalReasonsCount[i] += parseInt(statRow[1][i]);
                  }

                  this.total += parseInt(statRow[2]);
                });

                this.submitFinished.emit();
              }
            },
            errorResponse => this.serverErrorMessage = errorResponse.message
          );
      })
    ).subscribe();

  }

  private createStatisticsForDays(dateStringFrom: string, dateStringTo: string, statisticsType: string, statisticsBase: string): void {
    this.companyService.getStatisticsByDate(dateStringFrom, dateStringTo, statisticsType, statisticsBase)
      .subscribe((response: any) => {
        if (response.status === 204) {
          this.reset();
        } else {
          const dayList = this.daysBetween(dateStringFrom, dateStringTo);
          this.days = dayList;
          this.daystats = response.body.data;
          let totalTotalCnt = 0;

          this.totalCntArr = [];
          if (this.daystats.length > 0) {
            for (let uu = 0; uu < this.daystats[0][1].length; uu++) {
              this.totalCntArr.push(0);
            }
          }

          this.daystats.forEach((stat: any) => {
            for (let oo = 0; oo < stat[1].length; oo++) {
              this.totalCntArr[oo] += parseInt(stat[1][oo]);
            }
            totalTotalCnt += parseInt(stat[2]);
          });

          this.totalCntArr.push(totalTotalCnt);

          // this.stats = resArr;
          this.statisticsTypeDays = true;

          this.submitFinished.emit();
        }
      },
        errorResponse => this.serverErrorMessage = errorResponse.message
      );
  }

  downloadStatisticsPDF(dateFrom: string, dateTo: string, statisticsType: string, statisticsBase: string): void {
    const dateTempFrom = (dateFrom != '') ? this.validateDate(dateFrom) : '';
    const dateTempTo = (dateTo != '') ? this.validateDate(dateTo) : '';
    if (dateTempFrom !== false && dateTempTo !== false) {
      this.companyService.downloadStatisticsByDate(dateTempFrom, dateTempTo, statisticsType, statisticsBase)
        .subscribe((response: any) => {
          if (response.status === 204) {
            this.reset();
          } else {
            this.downloadAsPDFFile(response.body.data, dateFrom, 'filter');
          }
      },
        errorResponse => this.serverErrorMessage = errorResponse.message
      );
    }
  }

  private downloadAsPDFFile(data: any, testDate: string, filter: string): void {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'application/pdf'});
    FileSaver.saveAs(blob, 'statistics_' + testDate + '.pdf');
  }

  private reset(): void {
    this.errorMessage = '';
    this.serverErrorMessage = '';
  }
}

export interface Stat {
  company_name: string;
  num_antigen: string;
  num_antigen_free: string;
  num_pcr_free: string;
  num_pcr_paid: string;
  num_pcr_std: string;
  num_pcr_exp: string;
  num_antigen_paid: string;
  num_antigen_paid_reduced: string;
  num_antigen_card: string;
  num_antigen_card_use: string;
  num_antigen_team_free: string;
  num_antigen_team_paid: string;
  num_total: string;
}

export interface Day {
  date: string;
}
