import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { SharedService } from './shared-module/shared.service';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private sharedService: SharedService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorMCSResponse | HttpErrorResponse) => {
          if (error.error?.error?.description) {
            this.sharedService.lastError = error;
          }
          return throwError(error);
        })
      );
  }
}

export interface HttpErrorMCSResponse extends HttpErrorResponse {
  error: {
    error?: {
      description: string;
    }
  };
}
