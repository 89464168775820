import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SigningService extends ApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  public isDigitalSigningAvailable(): Observable<any> {
    return this.http.get<any>(this.baseUrl + this.signingEndpoint + '/available');
  }

  public postSigningStatus(params: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.signingEndpoint + '/status', params, {
      headers: { nospinner: '' }
    });
  }

  public postSigningSave(orderId: String): Observable<any> {
    return this.http.post<any>(this.baseUrl + this.signingEndpoint + '/save', { "orderId": orderId });
  }

  public deleteSigningStatus(): Observable<any> {
    return this.http.delete<any>(this.baseUrl + this.signingEndpoint + '/delete');
  }
}
