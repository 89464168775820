import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Company } from '../models/company';
import { Order } from '../models/order';
import { UserService } from '../user.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../company.service';
import { Person } from '../models/person';
import * as moment from 'moment';
import { TestuserTableComponent } from '../company-checkin/components/testuser-table/testuser-table.component';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faTrash, faFolderOpen, faFileCsv, faSearch } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '../authentication.service';
import exportTestuserData from '../download/csvHelper';
import { PERMISSIONS } from '../roles';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-companymgmt',
  templateUrl: './companymgmt.component.html',
  styleUrls: ['./companymgmt.component.css']
})
export class CompanymgmtComponent implements OnInit {

  @ViewChild('testDateInput') testDateInput!: ElementRef;

  public faTrash: IconDefinition = faTrash;
  public faSearch: IconDefinition = faSearch;
  public faFileCsv: IconDefinition = faFileCsv;
  public faFolderOpen: IconDefinition = faFolderOpen;

  public P = PERMISSIONS;

  companies: Company[] = [];
  selectedCompany: Company | null = null;
  selectedOrder: Order | null = null;
  selectedEmployee: Person | null = null;
  orders: Order[] = [];

  infoMessage = '';

  @ViewChild('inputOrderName') inputOrderName!: ElementRef;
  @ViewChild('inputOrderDate') inputOrderDate!: ElementRef;
  @ViewChild('inputOrderPrice') inputOrderPrice!: ElementRef;
  @ViewChild('inputOrderSelfCheck') inputOrderSelfCheck!: ElementRef;
  @ViewChild('inputOrderMaxUser') inputOrderMaxUser!: ElementRef;
  @ViewChild('companySearchInput') companySearchInput!: ElementRef;

  @ViewChild('testUserTable') testUserTable!: TestuserTableComponent;

  showCompanyListContainer = true;
  showAddCompanyDialog = false;
  showOrderListContainer = false;
  showEmployeeListContainer = false;
  showSearchResultsContainer = false;
  showAddEmployeeContainer = false;
  showUploadDialog = false;
  selectedCompanyOrders: Order[] | [] | undefined = [];
  selectedCompanyArchiveOrders: Order[] | [] | undefined  = [];
  activeCompanyOrders: Order[] | [] | undefined  = [];
  archiveBtnLabel = 'COMPANY.SHOW_ARCHIVE_LABEL';
  archiveCompanyBtnLabel = 'Archiv anzeigen';
  exportCSVtestuserDataBtnLabel = 'COMPANY.EXPORT_TESTUSER_CSV';
  showTestResultDialog = false;
  showAddOrderDialog = false;
  archiveIsActive = false;
  privateKey = '';
  foundPatients: Person[] | any;
  companySearchString = '';

  showSpinner = false;
  errorMessage = '';

  constructor(
    private userService: UserService,
    private translate: TranslateService,
    private companyService: CompanyService,
    private authService: AuthenticationService,
    @Inject('Window') private window: Window
  ) {
    this.privateKey = this.authService.getPrivateKey() ?? '';
  }

  ngOnInit(): void {
    this.reset();
    this.loadCompanies().subscribe();
  }

  loadCompanies(archivedOrder = true, archivedCompanies = false, onlyArchivedCompanies = false): Observable<any> {
    return this.companyService.getCompanies(archivedOrder, archivedCompanies).pipe(
      tap(response => {
        this.companies = response.data;
        this.companies.forEach(company => {
          company.can_archive = company.orders?.filter(order => order.is_archive === false).length === 0;
        });
        if (onlyArchivedCompanies) {
          this.companies = this.companies.filter(company => company.is_archive);
        }
      }),
      tap(() => {
        if (this.selectedCompany) {
          this.selectedCompany = this.companies.find(company => company.id === this.selectedCompany?.id!) ?? null;
        }
      })
    );
  }

  searchCompany(): void {
    this.companySearchString = this.companySearchInput.nativeElement.value;
  }

  switchArchive(): void {
    this.archiveBtnLabel = this.archiveBtnLabel === 'COMPANY.SHOW_ARCHIVE_LABEL' ? 'COMPANY.HIDE_ARCHIVE_LABEL' : 'COMPANY.SHOW_ARCHIVE_LABEL';
    this.archiveIsActive = !this.archiveIsActive;
    this.activeCompanyOrders = this.archiveIsActive ? this.selectedCompanyArchiveOrders : this.selectedCompanyOrders;
  }

  switchArchiveCompanies(): void {
    this.archiveCompanyBtnLabel = this.archiveCompanyBtnLabel === 'Archiv anzeigen' ? 'Archiv ausblenden' : 'Archiv anzeigen';
    const showArchivedCompanies = this.archiveCompanyBtnLabel === 'Archiv ausblenden';
    if (showArchivedCompanies) {
      this.loadCompanies(true, true, true).subscribe();
    } else {
      this.loadCompanies(true).subscribe();
    }
  }

  deleteCompany(company: Company): void {
    this.errorMessage = '';
    this.companyService.deleteCompany(company?.id).subscribe(
      () => {
        this.companies = this.companies.filter(item => item.id !== company?.id);
      },
      (error: any) => {
        this.showError(error?.error?.error?.description ?? 'Error');
      }
    );
  }

  archiveCompany(company: Company): void {
    this.errorMessage = '';
    if (confirm(company.name + ' wirklich archivieren?')) {
      this.companyService.archiveCompany(company?.id).subscribe(
        () => {
          this.companies = this.companies.filter(item => item.id !== company?.id);
        },
        (error: any) => {
          this.showError(error?.error?.error?.description ?? 'Error');
        }
      );
    }
  }

  unArchiveCompany(company: Company): void {
    this.errorMessage = '';
    if (confirm(company.name + ': Archivierung rückgängig machen?')) {
      this.companyService.unArchiveCompany(company?.id).subscribe(
        () => {
          this.companies = this.companies.filter(item => item.id !== company?.id);
        },
        (error: any) => {
          this.showError(error?.error?.error?.description ?? 'Error');
        }
      );
    }
  }

  deleteCompanyOrder(order: Order): void {
    this.errorMessage = '';
    this.translate.get('COMPANY.DELETE_ORDER', { name: order.name }).subscribe((text: string) => {
      if (confirm(text)) {
        this.companyService.deleteCompanyOrder(order?.id).subscribe(
          () => {
            this.selectedCompanyOrders = this.selectedCompanyOrders!.filter((item: Order) => item.id !== order?.id);
            this.selectedCompanyArchiveOrders = this.selectedCompanyArchiveOrders!.filter((item: Order) => item.id !== order?.id);
            this.activeCompanyOrders = this.activeCompanyOrders!.filter((item: Order) => item.id !== order?.id);
            this.companies.find(item => item.id === this.selectedCompany?.id)!.orders =
              this.selectedCompanyOrders.filter(item => item.id !== order.id);
          },
          (error: any) => {
            this.showError(error?.error?.error?.description ?? 'Error');
          }
        );
      }
    });
  }

  archiveCompanyOrder(order: Order): void {
    this.translate.get('COMPANY.ARCHIVE_ORDER', { name: order.name }).subscribe((text: string) => {
      if (confirm(text)) {
        this.companyService.archiveCompanyOrder(this.selectedCompany?.id, order.id).subscribe(
          (response: any) => {
            this.selectedCompanyOrders = this.selectedCompanyOrders!.filter(item => item.id !== order?.id);
            this.activeCompanyOrders = this.activeCompanyOrders!.filter(item => item.id !== order?.id);
            // @ts-ignore
            this.selectedCompanyArchiveOrders.push(order);
          },
          (error: any) => {
            this.showError(error?.error?.error?.description ?? 'Error');
          }
        );
      }
    });
  }

  onSelectCompany(company: Company): void {
    this.selectedCompany = company;

    if (this.selectedCompany?.orders == null ) {
      this.selectedCompanyOrders = [];
      this.selectedCompanyArchiveOrders = [];
      this.selectedCompany.orders = [];
    } else {
      this.selectedCompanyOrders = company.orders?.filter(order => !order.is_archive);
      this.selectedCompanyArchiveOrders = company.orders?.filter(order => order.is_archive);
      this.activeCompanyOrders = this.selectedCompanyOrders;

      if (this.archiveIsActive) {
        this.switchArchive();
      }
    }

    this.resetVisibility();
    this.showOrderListContainer = true;
    setTimeout(() => this.testDateInput.nativeElement.value = moment(new Date()).format('DD.MM.YYYY'));
  }

  addCompany(company: Company): void {
    this.translate.get('REGISTRATION.NEW_COMPANY_ADDED').subscribe((text: string) => {
      this.infoMessage = text;
    });
    this.companies.push(company);
    this.showAddCompanyDialog = false;
  }

  addOrder(newOrder: Order): void {
    // @ts-ignore
    this.selectedCompanyOrders?.push(newOrder);
    if (this.archiveIsActive) {
      this.switchArchive();
    }
    this.companies.find(item => item.id === this.selectedCompany?.id)!.orders!.push(newOrder);
    this.showAddOrderDialog = false;
  }

  downloadCsvTestuserData(dateString: string): void {
    const date = moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD');
    this.companyService.getCompanyTestuserDataByTestdate(date, this.selectedCompany?.id, this.privateKey)
      .subscribe((response: any) => {
          if (response.status === 204) {
            this.errorMessage = '';
            this.translate.get('DOWNLOAD.NO_RESULTS_CSV').subscribe((text: string) => this.errorMessage = text);
          } else {
            exportTestuserData(response.body.data, date, 'personendaten.csv', this.selectedCompany?.name);
          }
        },
        errorResponse => this.errorMessage = errorResponse.message
      );
  }

  searchTestuserLastname(search: string): void {
    this.errorMessage = '';
    this.infoMessage = '';

    this.companyService.getCompanyOrderTestsBySearch(search, null, this.selectedCompany?.id, this.privateKey).subscribe((res: any) => {
      if (res.data.length) {
        this.resetVisibility();
        this.foundPatients = res.data;
        this.showSearchResultsContainer = true;
        this.translate.get('COMPANY.SEARCH_FOR', { search }).subscribe((text: string) => {
          this.infoMessage = text;
        });
      } else {
        this.resetVisibility();
        this.foundPatients = [];
        this.translate.get('COMPANY.NO_RESULTS').subscribe((text: string) => {
          this.errorMessage = text;
        });
        this.showOrderListContainer = true;
      }
    });
  }

  onSelectOrder(order: Order): void {
    this.resetVisibility();
    this.selectedOrder = order;
    this.showEmployeeListContainer = true;
  }

  openAddOrderModal(): void {
    this.showAddOrderDialog = true;
  }

  addEmployee(): void {
    this.resetVisibility();
    this.showAddEmployeeContainer = true;
  }

  userAdded(): void {
    this.resetVisibility();
    this.showEmployeeListContainer = true;
  }

  back(): void {
    this.errorMessage = '';
    this.loadCompanies().subscribe(() => {
      if (this.showOrderListContainer) {
        this.resetVisibility();
        this.showCompanyListContainer = true;
        this.selectedCompany = null;
      } else if (this.showEmployeeListContainer) {
        this.resetVisibility();
        this.showOrderListContainer = true;
        this.selectedOrder = null;
        this.onSelectCompany(this.selectedCompany!);
      } else if (this.showAddEmployeeContainer) {
        this.resetVisibility();
        this.showEmployeeListContainer = true;
      } else if (this.showSearchResultsContainer && !this.selectedCompany) {
        this.resetVisibility();
        this.showCompanyListContainer = true;
      } else {
        this.resetVisibility();
        this.showOrderListContainer = true;
      }
    });
  }

  private reset(): void {
    // this.resetAddCompanyFields();
    this.resetVisibility();
    this.showCompanyListContainer = true;
  }

  private resetVisibility(): void {
    this.window.scroll(0, 0);
    this.infoMessage = '';
    this.showCompanyListContainer = false;
    this.showSearchResultsContainer = false;
    this.showOrderListContainer = false;
    this.showEmployeeListContainer = false;
    this.showAddEmployeeContainer = false;
  }

  csvUploadDone(): void {
    this.testUserTable.ngOnInit();
    this.showUploadDialog = false;
  }

  csvError(errors: any): void {
    this.testUserTable.ngOnInit();
    if (!errors) {
      this.errorMessage = '';
      return;
    }

    this.showError(errors);
    this.showUploadDialog = false;
  }

  private showError(message: string): void {
    const errorCodes = ['COMPANY_NOT_EMPTY_ERROR', 'COMPANY_ORDER_NOT_EMPTY_ERROR'];

    if (errorCodes.includes(message)) {
      message = 'COMPANYCHECKIN.' + message;
    }

    this.errorMessage = message;
  }
}
