<div class="lang-switcher" style="display: none">
  <button (click)="switchLanguage('de')">DE</button> <button (click)="switchLanguage('en')">EN</button>
</div>

<div id="privacyContainer" #privacyContainer>
  <div [innerHTML]="activeTemplate"></div>
</div>

<div id="printContainer" #printContainer></div>

<signature-pad [options]="{  minWidth: 1, canvasWidth: 500, canvasHeight: 200}"
               (onEndEvent)="onDrawComplete()"></signature-pad>

<div class="center">
  <button [disabled]="!signature || signature == ''" class="custom-btn rounded"
          (click)="confirmSignature()">{{ 'REGISTRATION.BTN_CONFIRM' | translate }}</button>
</div>

<button class="cancel-btn rounded" (click)="resetSignaturePad()">{{ 'COMPANYCHECKIN.RESET' | translate }}</button><br>
<br>
