<div class="wrapper">
  <h1>{{ 'DOWNLOAD.TITLE' | translate }}</h1>
  <h4>Positivergebnisse</h4>
  <!-- Entry Container -->

  <app-alert *ngIf="errorMessage" [message]="errorMessage"></app-alert>

  <ng-container *ngIf="privateKeyUploaded">
    <ng-container *ngxPermissionsOnly="P.DOWNLOAD_POS_REPORT">
      <div class="center">
        <p>{{ 'DOWNLOAD.TEXT_HEADER' | translate }}</p>
        <input #dateInput type="text" class="rounded" placeholder="DD.MM.YYYY" (keyup.enter)="downloadResultsPdf(dateInput.value)"/>
      </div>

      <div class="center">
        <label>
          <span>Abrechnungsart</span>
          <select #filterInput class="rounded">
            <option value="all" selected>alle</option>
            <option value="paid">bezahlt</option>
            <option value="free">kostenlos (inkl. antigen-reduced)</option>
          </select>
        </label>
      </div>

      <div class="center" *ngxPermissionsOnly="P.CAN_SWITCH_COMPANY_DOWNLOAD_RESULTS">
        <label>
          <span>Shoppingcenter / Firmentestung</span>
          <select #companyInput class="rounded">
            <option value="" selected>alle</option>
            <option [value]="company.id" *ngFor="let company of companies">{{ company.name }}</option>
          </select>
        </label>
      </div>

      <span class="check">
        <label>

        <input type="checkbox" #zipCb>
           Zip-Archiv
        </label>
        <br><br>

      </span>

      <div class="center">
        <button class="custom-btn rounded" (click)="downloadResultsPdf(dateInput.value)">{{ 'DOWNLOAD.BTN_DOWNLOAD' | translate }}</button>
      </div>
    </ng-container>
  </ng-container>

  <div [hidden]="!showValidationError" class="center small-text">
    {{ 'DOWNLOAD.TEXT_ERROR_VALIDATION' | translate }}
  </div>

  <div class="testuser-csv-download" *ngxPermissionsOnly="P.DOWNLOAD_CSV_TESTUSER_DATA_COMPANY">
    <h4>CSV Personendaten (Testdatum)</h4>
    <div class="row">
      <div class="col-4">
        <span>Datum von</span><input #testDateInputFrom type="text" class="form-control rounded"  value="{{ today | date:'dd.MM.yyyy'}}" placeholder="DD.MM.YYYY" /><br>
      </div>
      <div class="col-4">
        <span>Datum bis</span><input #testDateInputTo type="text" class="form-control rounded"  value="{{ today | date:'dd.MM.yyyy'}}" placeholder="DD.MM.YYYY" />
      </div>
      <div class="col-4">
        <button class="custom-btn rounded month-btn" (click)="setCurrentMonth()">aktueller Monat</button>
      </div>
    </div>
    <div class="center">
      <label>
        <span>Shoppingcenter &amp; Firmenkunden / Alles</span>
        <select #csvFilterAllInput class="rounded">
          <option value="all" selected>Shoppingcenter &amp; Firmenkunden + Shopware</option>
          <option value="shopwalk">Shopware + Walk-In Firmen</option>
          <option value="sc">nur ShoppingCenter &amp; Firmenkunden</option>
          <option value="shopware">nur Shopware</option>
          <option value="walkin">nur Walk-In Firmen</option>
        </select>
      </label>
    </div>
    <div class="center">
      <label>
        <input type="checkbox" #zipCsvInput>
        eine CSV-Datei pro Company
      </label>
    </div>
    <div class="center">
      <label>
        <input type="checkbox" #billingModeInput (change)="changeBillingModeCb()">
        VK-Abrechnung
      </label>
    </div>
    <div class="center">
      <button class="custom-btn rounded" (click)="downloadTestuserDataCSV()">{{ 'DOWNLOAD.BTN_DOWNLOAD' | translate }}</button>
    </div>
  </div>

</div>
<div *ngIf="!privateKeyUploaded" class="wrapper">
  <h1>{{ 'DOWNLOAD.TITLE' | translate }}</h1>
  <p>{{ 'DOWNLOAD.NO_PRIVATE_KEY' | translate }}</p>
</div>
