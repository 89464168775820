<div class="wrapper">
  <h1>{{ 'TESTRESULT-EXP.TITLE' | translate }}</h1>

  <!-- Error Message -->

  <app-alert *ngIf="errorMessage" [message]="errorMessage" [serverMessage]="serverError"></app-alert>

  <!-- Header -->

  <div class="center no-margin">
    <p *ngIf='!this.sucessfullyDone'>{{ description }}</p>
    <p class="alert alert-success" role="alert" *ngIf='this.sucessfullyDone'>{{ description }}</p>
  </div>

  <!-- Upload -->

  <div *ngIf="showUploadContainer">
    <div class="input-group form-group custom-width">
      <label class="custom-file-label" for="customFile">{{ 'TESTRESULT-EXP.BTN_UPLOAD' | translate }}</label>
      <input type="file" class="custom-file-input" id="customFile" (change)="handleFileInput($event)">
    </div>
    <div class="center" *ngIf="healyzerApiAvailable && checkedInPcrTests.length > 0">
      <button class="custom-btn rounded" (click)="loadHealyzerTests()">Testergebnisse von Healyzer herunterladen</button>
    </div>
  </div>

  <!-- Open PCR Tests Container -->

  <h2>Offene PCR Tests</h2>
  <div>
    <div class="center">
      <ul class="header">
        <li>
          <span class="normal">Bestellnummer</span>
          <span class="normal">Testkitnummer</span>
          <span class="normal">Testtyp</span>
          <span class="normal">Checkin Zeit</span>
        </li>
      </ul>

      <div class="divider"></div>

      <ul class="list">
        <ng-container *ngIf="checkedInPcrTests.length < 1">
          <li>
            <span class="accent normal">Keine offenen PCR-Tests</span>
          </li>
        </ng-container>
        <ng-container *ngFor="let checkedInPcrTest of checkedInPcrTests">
          <li>
            <span class="accent normal">{{checkedInPcrTest.order_id}}</span>
            <span class="item normal">{{checkedInPcrTest.testkit_id}}</span>
            <span class="accent normal">{{checkedInPcrTest.test_type}}</span>
            <span class="item normal">{{checkedInPcrTest.checkin_date}}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <!-- Result Container -->

  <h2 *ngIf="showResultContainer">Verfügbare Ergebnisse</h2>
  <div *ngIf="showResultContainer">
    <div class="center">
      <ul class="header">
        <li>
          <span class="normal">Senden?</span>
          <span class="normal">{{ 'TESTRESULT-EXP.CSV_TESTKIT' | translate }}</span>
          <span class="normal">{{ 'TESTRESULT-EXP.CSV_TIMESTAMP' | translate }}</span>
          <span class="normal">{{ 'TESTRESULT-EXP.CSV_RESULT' | translate }}</span>
          <span class="normal">{{pcrResults[0].isNewPcr ? ('TESTRESULT-EXP.CSV_GENE1_NEW' | translate) : ('TESTRESULT-EXP.CSV_RNA' | translate)}}</span>
          <span class="normal">{{pcrResults[0].isNewPcr ? ('TESTRESULT-EXP.CSV_GENE2_NEW' | translate) : ('TESTRESULT-EXP.CSV_GENE' | translate)}}</span>
          <span class="normal">{{ 'TESTRESULT-EXP.CSV_INTERNAL' | translate }}</span>
          <span class="normal">{{ 'TESTRESULT-EXP.SERIAL_INTERNAL' | translate }}</span>
        </li>
      </ul>

      <div class="divider"></div>

      <ul class="list">
        <ng-container *ngFor="let pcrResult of pcrResults">
          <li (click)="changeSendTo($event, pcrResult)">
            <span class="normal" style="padding-left: 1em;">
              <input *ngIf="pcrResult.sent !== true || pcrResult.sentSucessfully !== true" type="checkbox" [checked]="pcrResult.toSend == true">
              <span *ngIf="pcrResult.sent == true && pcrResult.sentSucessfully == true" class="text-success">Erfolgreich gesendet</span>
            </span>
            <span class="accent normal">{{pcrResult.testkit}}</span>
            <span class="item normal">{{pcrResult.timestamp}}</span>
            <span class="accent normal">{{pcrResult.result | titlecase}}</span>
            <span class="item normal">{{pcrResult.values[0]}}</span>
            <span class="item normal">{{pcrResult.values[1]}}</span>
            <span class="item normal">{{pcrResult.values[2]}}</span>
            <span class="item normal">{{pcrResult.serialno}}</span>
          </li>
          <li *ngIf="pcrResult.sent == true && pcrResult.sentSucessfully == false" style="padding: 1em;">
            <span class="item text-danger">{{ pcrResult.sentErrorMsg }}</span>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="center">
      <button class="custom-btn rounded" (click)="submit()" [disabled]="!sendBtnEnabled">{{ 'TESTRESULT.BTN_SUBMIT' | translate }}</button>
    </div>
  </div>

  <!-- Cancel Message -->

  <div class="center" [hidden]="showUploadContainer">
    <button *ngIf='!this.sucessfullyDone' class="cancel-btn rounded"
      (click)="cancel()">{{ 'TESTRESULT.BTN_CANCEL' | translate }}</button>
    <button *ngIf='this.sucessfullyDone' class="cancel-btn rounded"
      (click)="cancel()">{{ 'TESTRESULT.BTN_OK' | translate }}</button>
  </div>

</div>
