import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LogoutComponent } from '../logout/logout.component';
import { LoginComponent } from '../login/login.component';
import { HomeComponent } from '../home/home.component';
import { TestresultComponent } from '../testresult/testresult.component';
import { TestresultExpComponent } from '../testresult-exp/testresult-exp.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrivkeyComponent } from '../privkey/privkey.component';
import { OrdermgmtComponent } from '../ordermgmt/ordermgmt.component';
import { DownloadComponent } from '../download/download.component';
import { CheckinComponent } from '../checkin/checkin.component';
import { CompanymgmtComponent } from '../companymgmt/companymgmt.component';
import { CompanyCheckInComponent } from '../company-checkin/company-checkin.component';
import { CompanyAdminComponent } from '../company-admin/company-admin.component';
import { TestTypeItemComponent } from '../checkin/components/test-type-item/test-type-item.component';
import { TestKitFormComponent } from '../checkin/components/testkit-form/testkit-form.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignatureComponent } from '../checkin/components/signature/signature.component';
import { TestresultPipe } from '../company-admin/testresult.pipe';
import { TeststatusPipe } from '../company-admin/teststatus.pipe';
import { FormatTestKitNoPipe } from '../company-admin/format-test-kit-no.pipe';
import { ResendResultComponent } from '../company-checkin/components/resend-result/resend-result.component';
import { TestuserTableComponent } from '../company-checkin/components/testuser-table/testuser-table.component';
import { AddCompanyTestuserCsvComponent } from '../company-admin/components/add-company-testuser-csv/add-company-testuser-csv.component';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TimePipe } from '../company-admin/time.pipe';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { AlertComponent } from './alert/alert.component';
import { CompanyOrderTableComponent } from '../companymgmt/components/company-order-table/company-order-table.component';
import { CompanyTableComponent } from '../companymgmt/components/company-table/company-table.component';
import { TestsReceivedComponent } from '../tests-received/tests-received.component';
import { TestuserEditComponent } from '../company-checkin/components/testuser-edit/testuser-edit.component';
import { CompanyOrderEditComponent } from '../companymgmt/components/company-order-edit/company-order-edit.component';
import { ModalComponent } from './modal/modal.component';
import { FreeCheckResetComponent } from '../free-check-reset/free-check-reset.component';
import { InfopointSearchComponent } from '../infopoint/infopoint-search/infopoint-search.component';
import { InfopointSelfcheckComponent } from '../infopoint/infopoint-selfcheck/infopoint-selfcheck.component';
import { AddTestInfopointComponent } from '../infopoint/add-test-infopoint/add-test-infopoint.component';
import { QrCodeComponent } from '../infopoint/qr-code/qr-code.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { StickyDirectiveModule } from 'ngx-sticky-directive';
import { StatisticsComponent } from '../statistics/statistics.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { CompanyEditComponent } from '../companymgmt/components/company-edit/company-edit.component';
import { ThirdPartyConsentComponent } from '../checkin/components/third-party-consent/third-party-consent.component';
import { VerificationComponent } from './verification/verification.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { GenderLabelPipe } from './gender-label.pipe';
import { CWAvCardImportComponent } from '../company-checkin/components/cwa-vcard-import/cwa-vcard-import.component';
import { TestTypeLabelPipe } from './test-type-label.pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CompanyOrderAddComponent } from '../companymgmt/components/company-order-add/company-order-add.component';
import { CompanyAddComponent } from '../companymgmt/components/company-add/company-add.component';
import { OrderEditComponent } from '../ordermgmt/components/order-edit/order-edit.component';
import { QrCodeModule } from 'ng-qrcode';
import { EditTestTypesComponent } from '../edit-test-types/edit-test-types.component';
import { OEGDSigningComponent } from '../oegdsigning/oegdsigning.component';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    PrivkeyComponent,
    HomeComponent,
    CheckinComponent,
    TestresultComponent,
    TestresultExpComponent,
    TestsReceivedComponent,
    OrdermgmtComponent,
    DownloadComponent,
    CompanymgmtComponent,
    CompanyCheckInComponent,
    CompanyAdminComponent,
    TestTypeItemComponent,
    TestKitFormComponent,
    SignatureComponent,
    TestresultPipe,
    TeststatusPipe,
    FormatTestKitNoPipe,
    ResendResultComponent,
    TestuserTableComponent,
    AddCompanyTestuserCsvComponent,
    TimePipe,
    AlertComponent,
    CompanyOrderTableComponent,
    CompanyTableComponent,
    TestuserEditComponent,
    CompanyOrderEditComponent,
    ModalComponent,
    FreeCheckResetComponent,
    InfopointSearchComponent,
    InfopointSelfcheckComponent,
    QrCodeComponent,
    AddTestInfopointComponent,
    StatisticsComponent,
    BackButtonComponent,
    CompanyEditComponent,
    ThirdPartyConsentComponent,
    VerificationComponent,
    LoadingSpinnerComponent,
    GenderLabelPipe,
    CWAvCardImportComponent,
    TestTypeLabelPipe,
    CompanyOrderAddComponent,
    CompanyAddComponent,
    OrderEditComponent,
    EditTestTypesComponent,
    OEGDSigningComponent,
  ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        SignaturePadModule,
        AngularStickyThingsModule,
        StickyDirectiveModule,
        NgxSpinnerModule,
        NgxCsvParserModule,
        VirtualScrollerModule,
        NgxPermissionsModule,
        QrCodeModule,
    ],
  providers: [
    { provide: 'Window',  useValue: window }
  ],
    exports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        FontAwesomeModule,
        ModalComponent
    ],
})
export class SharedModule { }
