<div class="alert alert-{{ type }}" role="alert" *ngIf="message">
  <p>
    {{ message }}
  </p>
  <ng-container *ngIf="serverMessage">
    <button *ngIf="!showMore" type="button" class="btn btn-outline-{{ type }}" (click)="showMore = true">Mehr Infos</button>
    <samp *ngIf="showMore">
      {{ serverMessage }}
    </samp>
  </ng-container>
</div>
