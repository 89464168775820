export enum USER_ROLES {
  SUPERADMIN = 'superadmin',
  SUPERADMIN_READONLY = 'superadmin_readonly',
  ADMIN = 'admin',
  TZ_RECEPTION = 'tz_reception',
  DOCTOR = 'doctor',
  COMPANY_IPAD = 'company_ipad',
  COMPANY = 'company',
  SC_MANAGER = 'sc_manager',
  SC_INFOPOINT = 'sc_infopoint',
  SC_RECEPTION = 'sc_reception',
  SC_RECEPTION_INFOPOINT = 'sc_reception_infopoint',
  SA_SIGNING = 'signing'
}

export enum PERMISSIONS {
  READ_STATISTICS = 'read_statistics',
  READ_STATISTICS_ALL_COMPANIES = 'read_statistics_all_companies',
  MANAGE_COMPANIES = 'manage_companies',
  DOWNLOAD_CSV_TESTUSER_DATA = 'download_csv_testuser',
  DOWNLOAD_POS_REPORT = 'download_pos_report',
  COMPANY_CHECKIN = 'view_company_checkin_table',
  EDIT_COMPANY_TESTUSER = 'edit_company_testuser',
  EDIT_COMPANY_TESTUSER_AFTER_CHECKIN = 'edit_company_testuser_after_checkin',
  CAN_CHECKIN = 'can_checkin',
  RESET_FREE_TEST = 'reset_free_test',
  MANAGE_ORDERS = 'manage_orders',
  ACCESS_TEST_RECEIVED = 'access_test_received',
  ACCESS_VARIANTS = 'access_variants',
  CAN_SWITCH_LOCATION = 'can_switch_location',
  CAN_SEARCH_CHECKIN = 'can_search_checkin',
  CAN_ACCESS_SEARCH_PAGE = 'can_access_search_page',
  CAN_TESTUSER_TABLE_DELETE = 'can_testuser_table_delete',
  CAN_TESTUSER_FINISHED_TEST_TABLE_DELETE = 'can_testuser_finished_test_table_delete',
  CAN_ENTER_TEST_RESULT_PAGE = 'can_enter_result_page',
  CAN_ENTER_TEST_RESULT_PAGE_PCR = 'can_enter_result_page_pcr',
  CAN_ENTER_RESULT_COMPANY_TABLE = 'can_enter_result_company_table',
  COMPANY_OVERVIEW = 'company_overview',
  ARCHIVE_COMPANY_ORDERS = 'archive_company_orders',
  ACCESS_ADD_TEST_PAGE = 'access_add_test_page',
  ACCESS_SELFCHECKIN_INFO_PAGE = 'access_selfcheckin_info_page',
  CAN_RESEND_RESULT_FROM_TABLE = 'can_resend_result_table',
  CAN_DOWNLOAD_RESULT_FROM_TABLE = 'can_download_result_table',
  CHECKIN_AFTER_ADD_COMPANY_TEST = 'checkin_after_add_company_test',
  CAN_SEARCH_LASTNAME_AT_CHECKIN = 'search_lastname_checkin',
  VIEW_CHECKIN_SEARCH_INFO_TEXT = 'view_checkin_search_info_text',
  CAN_ADD_ORDER_IN_ORDER_OVERVIEW = 'can_add_order_in_order_overview',
  CAN_ADD_EMPLOYEE = 'can_add_employee',
  CAN_EXPORT_EMPLOYEE_RESULTS = 'can_export_employee_results',
  CAN_SWITCH_COMPANY_DOWNLOAD_RESULTS = 'can_switch_company_download_results',
  DOWNLOAD_CSV_TESTUSER_DATA_COMPANY = 'download_csv_testuser_data_company',
  ADMIN_GLOBAL_COMPANY_TEST_SEARCH = 'global_company_test_search',
  CAN_ADD_TEST_USER_IN_COMPANY_CHECKIN = 'can_add_test_in_company_checkin',
  CAN_DOWNLOAD_INVOICE = 'can_download_invoice',
  CAN_EDIT_TEST_TYPES = 'can_edit_test_types',
  CAN_MANAGE_USERS = 'can_manage_users',
  SA_SIGNING = 'signing'
}

const R = USER_ROLES;
const P = PERMISSIONS;
export const PERMISSIONS_BY_ROLES: Partial<Record<USER_ROLES, PERMISSIONS[]>>  = {
  [R.SUPERADMIN]: [
    P.READ_STATISTICS, P.READ_STATISTICS_ALL_COMPANIES, P.DOWNLOAD_CSV_TESTUSER_DATA, P.DOWNLOAD_POS_REPORT, P.MANAGE_COMPANIES, P.COMPANY_CHECKIN, P.CAN_CHECKIN,
    P.RESET_FREE_TEST, P.MANAGE_ORDERS, P.ACCESS_VARIANTS, P.ACCESS_TEST_RECEIVED, P.CAN_SWITCH_LOCATION, P.CAN_TESTUSER_TABLE_DELETE,
    P.ARCHIVE_COMPANY_ORDERS, P.CAN_RESEND_RESULT_FROM_TABLE, P.CAN_DOWNLOAD_RESULT_FROM_TABLE, P.EDIT_COMPANY_TESTUSER,
    P.CAN_SEARCH_LASTNAME_AT_CHECKIN, P.CAN_ENTER_RESULT_COMPANY_TABLE, P.CAN_ADD_EMPLOYEE, P.CAN_EXPORT_EMPLOYEE_RESULTS,
    P.CAN_SWITCH_COMPANY_DOWNLOAD_RESULTS, P.DOWNLOAD_CSV_TESTUSER_DATA_COMPANY, P.EDIT_COMPANY_TESTUSER_AFTER_CHECKIN,
    P.ADMIN_GLOBAL_COMPANY_TEST_SEARCH, P.CAN_TESTUSER_FINISHED_TEST_TABLE_DELETE, P.CAN_DOWNLOAD_INVOICE, P.CAN_EDIT_TEST_TYPES,
    P.CAN_MANAGE_USERS, P.CAN_ENTER_TEST_RESULT_PAGE_PCR
  ],
  [R.SUPERADMIN_READONLY]: [
    P.READ_STATISTICS, P.READ_STATISTICS_ALL_COMPANIES, P.DOWNLOAD_CSV_TESTUSER_DATA, P.CAN_SWITCH_LOCATION, P.DOWNLOAD_CSV_TESTUSER_DATA_COMPANY, P.CAN_DOWNLOAD_INVOICE,
  ],
  [R.ADMIN]: [
    P.READ_STATISTICS, P.READ_STATISTICS_ALL_COMPANIES, P.DOWNLOAD_POS_REPORT, P.MANAGE_COMPANIES, P.COMPANY_CHECKIN, P.CAN_CHECKIN, P.ARCHIVE_COMPANY_ORDERS,
    P.RESET_FREE_TEST, P.MANAGE_ORDERS, P.ACCESS_VARIANTS, P.ACCESS_TEST_RECEIVED, P.CAN_TESTUSER_TABLE_DELETE,
    P.CAN_RESEND_RESULT_FROM_TABLE, P.CAN_DOWNLOAD_RESULT_FROM_TABLE, P.EDIT_COMPANY_TESTUSER, P.CAN_SEARCH_LASTNAME_AT_CHECKIN,
    P.DOWNLOAD_CSV_TESTUSER_DATA, P.CAN_ENTER_RESULT_COMPANY_TABLE, P.CAN_ADD_EMPLOYEE, P.CAN_EXPORT_EMPLOYEE_RESULTS,
    P.CAN_SWITCH_COMPANY_DOWNLOAD_RESULTS, P.EDIT_COMPANY_TESTUSER_AFTER_CHECKIN, P.ADMIN_GLOBAL_COMPANY_TEST_SEARCH,
    P.CAN_TESTUSER_FINISHED_TEST_TABLE_DELETE, P.DOWNLOAD_CSV_TESTUSER_DATA_COMPANY, P.CAN_DOWNLOAD_INVOICE,
  ],
  [R.TZ_RECEPTION]: [
    P.CAN_SEARCH_LASTNAME_AT_CHECKIN, P.VIEW_CHECKIN_SEARCH_INFO_TEXT,
    P.CAN_SEARCH_CHECKIN, P.CAN_SEARCH_LASTNAME_AT_CHECKIN,
  ],
  [R.DOCTOR]: [
    P.CAN_ENTER_TEST_RESULT_PAGE, P.CAN_ENTER_TEST_RESULT_PAGE_PCR,
  ],
  [R.COMPANY]: [
    P.COMPANY_OVERVIEW, P.EDIT_COMPANY_TESTUSER, P.ARCHIVE_COMPANY_ORDERS, P.DOWNLOAD_CSV_TESTUSER_DATA, P.CAN_DOWNLOAD_RESULT_FROM_TABLE,
    P.CAN_TESTUSER_TABLE_DELETE, P.CAN_ADD_EMPLOYEE, P.CAN_EXPORT_EMPLOYEE_RESULTS,
  ],
  [R.COMPANY_IPAD]: [
    P.CAN_CHECKIN, P.EDIT_COMPANY_TESTUSER, P.ARCHIVE_COMPANY_ORDERS, P.CAN_RESEND_RESULT_FROM_TABLE, P.COMPANY_CHECKIN,
    P.CAN_DOWNLOAD_RESULT_FROM_TABLE, P.CAN_SEARCH_LASTNAME_AT_CHECKIN, P.CAN_ENTER_RESULT_COMPANY_TABLE, P.CAN_EXPORT_EMPLOYEE_RESULTS,
    P.CAN_ADD_EMPLOYEE, P.CAN_ADD_TEST_USER_IN_COMPANY_CHECKIN, P.CAN_TESTUSER_TABLE_DELETE, P.CAN_DOWNLOAD_INVOICE,
  ],
  [R.SC_MANAGER]: [
    P.DOWNLOAD_CSV_TESTUSER_DATA, P.DOWNLOAD_POS_REPORT, P.COMPANY_OVERVIEW, P.RESET_FREE_TEST, P.EDIT_COMPANY_TESTUSER,
    P.CAN_ADD_ORDER_IN_ORDER_OVERVIEW, P.CAN_RESEND_RESULT_FROM_TABLE, P.CAN_DOWNLOAD_RESULT_FROM_TABLE,
    P.EDIT_COMPANY_TESTUSER_AFTER_CHECKIN, P.CAN_TESTUSER_TABLE_DELETE, P.CAN_DOWNLOAD_INVOICE, P.READ_STATISTICS
  ],
  [R.SC_INFOPOINT]: [
    P.ACCESS_ADD_TEST_PAGE, P.ACCESS_SELFCHECKIN_INFO_PAGE, P.CAN_ACCESS_SEARCH_PAGE, P.CAN_DOWNLOAD_RESULT_FROM_TABLE,
  ],
  [R.SC_RECEPTION]: [
    P.EDIT_COMPANY_TESTUSER, P.CHECKIN_AFTER_ADD_COMPANY_TEST, P.ACCESS_ADD_TEST_PAGE, P.CAN_DOWNLOAD_INVOICE,
    P.CAN_SEARCH_LASTNAME_AT_CHECKIN, P.CAN_ENTER_RESULT_COMPANY_TABLE, P.CAN_SEARCH_CHECKIN,
  ],
  [R.SC_RECEPTION_INFOPOINT]: [
    P.CHECKIN_AFTER_ADD_COMPANY_TEST, P.ACCESS_ADD_TEST_PAGE, P.CAN_ACCESS_SEARCH_PAGE, P.CAN_CHECKIN,
    P.CAN_DOWNLOAD_RESULT_FROM_TABLE, P.CAN_SEARCH_LASTNAME_AT_CHECKIN, P.CAN_SEARCH_CHECKIN,
  ],
  [R.SA_SIGNING]: [P.SA_SIGNING],
};
