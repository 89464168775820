import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AuthenticatedData } from './authenticated-data';
import { AuthenticatedUser } from './authenticated-user';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CompanyService } from './company.service';
import { SharedService } from './shared-module/shared.service';
import { NgxRolesService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends ApiService {
  private currentUserSubject!: BehaviorSubject<AuthenticatedUser>;
  public currentUser!: Observable<AuthenticatedUser>;

  private accessToken!: BehaviorSubject<string>;
  private refreshToken!: BehaviorSubject<string>;

  constructor(http: HttpClient, private router: Router, private sharedService: SharedService, private rolesService: NgxRolesService) {
    super(http);

    this.reassign();
  }

  private reassign(): void {
    try {
      this.currentUserSubject = new BehaviorSubject<AuthenticatedUser>(JSON.parse(sessionStorage.getItem('user') || '{}'));
    } catch (err) {
      this.currentUserSubject = new BehaviorSubject<AuthenticatedUser>(JSON.parse('{}'));
    }
    this.accessToken = new BehaviorSubject<string>(sessionStorage.getItem('access_token') || '');
    this.refreshToken = new BehaviorSubject<string>(sessionStorage.getItem('refresh_token') || '');
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public getLoggedInUser(): AuthenticatedUser {
    return this.currentUserSubject.value;
  }

  public isLocationShoppingcenter(): boolean {
    return this.getLoggedInUser().location === 'Shoppingcenter' || this.sharedService.locationId === '5';
  }

  public getAccessToken(): string {
    return this.accessToken.value;
  }

  public getRefreshToken(): string {
    return this.refreshToken.value;
  }

  public getPrivateKey(): string {
    const privateKey = sessionStorage.getItem('privkey') || '';
    const locationId = this.sharedService.locationId;

    if (locationId && privateKey.startsWith('LOCATION')) {
      const from = 'LOCATION:' + locationId;
      const to = '-----END PRIVATE KEY-----';

      let locationKey = privateKey.slice(privateKey.indexOf(from) + from.length);

      if (locationKey) {
        locationKey = locationKey.slice(0, locationKey.indexOf(to) + to.length);
        return locationKey;
      }
    }

    return privateKey;
  }

  public isLoggedIn(): boolean {
    if (this.currentUserSubject.value.id && this.accessToken.value !== '' && this.refreshToken.value !== '') {
      return true;
    }

    return false;
  }

  public login(username: string, password: string): Observable<AuthenticatedData> {
    return this.http.post<any>(this.baseUrl + this.authEndpoint + '/login', { username, password })
      .pipe(map(response => {
        const authenticatedData = response.data;
        if (authenticatedData) {
          const user: AuthenticatedUser = authenticatedData.user;
          sessionStorage.setItem('user', JSON.stringify(user));
          this.currentUserSubject.next(user);
          sessionStorage.setItem('access_token', authenticatedData.access_token);
          this.accessToken.next(authenticatedData.access_token);
          sessionStorage.setItem('refresh_token', authenticatedData.refresh_token);
          this.refreshToken.next(authenticatedData.refresh_token);
        }

        return authenticatedData;
      }));
  }

  public refreshAccessToken(): Observable<AuthenticatedData> {
    return this.http.post<any>(this.baseUrl + this.authEndpoint + '/refresh', { refresh_token: this.getRefreshToken() })
      .pipe(map(response => {
        const authenticatedData = response.data;
        if (authenticatedData) {
          sessionStorage.setItem('access_token', authenticatedData.access_token);
          this.accessToken.next(authenticatedData.access_token);
          sessionStorage.setItem('refresh_token', authenticatedData.refresh_token);
          this.refreshToken.next(authenticatedData.refresh_token);
        }

        return authenticatedData;
      }));
  }

  public isLocationCologne(): boolean {
    return ['köln', 'sw5'].includes(this.getLoggedInUser().location?.toLowerCase());
  }

  public getCompanyId(): number {
    return parseInt((this.getLoggedInUser().companyId as any), 10);
  }

  public logout(): void {
    this.rolesService.flushRolesAndPermissions();
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('privkey');
    sessionStorage.removeItem('company');
    this.reassign();
    this.router.navigate(['/login']);
  }
}
