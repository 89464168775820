import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Person } from '../../../models/person';
import { Company } from '../../../models/company';
import { Order } from '../../../models/order';
import { CompanyService } from '../../../company.service';
import { AuthenticationService } from '../../../authentication.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  faChevronUp,
  faDownload,
  faFileCsv,
  faFileDownload,
  faFilePdf,
  faFolderOpen,
  faQrcode,
  faReceipt,
  faShare,
  faTrash,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import exportTestuserData from '../../../download/csvHelper';
import { TestTypes } from '../../../checkin/checkin.component';
import { SharedService } from '../../../shared-module/shared.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { PERMISSIONS } from '../../../roles';

@Component({
  selector: 'app-testuser-table',
  templateUrl: './testuser-table.component.html',
  styleUrls: ['./testuser-table.component.scss']
})
export class TestuserTableComponent implements OnInit, OnDestroy {

  public faSync: IconDefinition = faSync;
  public faShare: IconDefinition = faShare;
  public faTrash: IconDefinition = faTrash;
  public faQrcode: IconDefinition = faQrcode;
  public faFilePdf: IconDefinition = faFilePdf;
  public faFileCsv: IconDefinition = faFileCsv;
  public faDownload: IconDefinition = faDownload;
  public faChevronUp: IconDefinition = faChevronUp;
  public faFileInvoice: IconDefinition = faReceipt;
  public faFolderOpen: IconDefinition = faFolderOpen;
  public faFileDownload: IconDefinition = faFileDownload;

  public readonly P = PERMISSIONS;

  @Output() actionError: EventEmitter<any> = new EventEmitter<any>();
  @Output() escape: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickAddUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickUploadCSV: EventEmitter<any> = new EventEmitter<any>();

  @Input() disableCheckIn = false;
  @Input() showCompanyAndTestTime = false;
  @Input() infopoint = false;
  @Input() showAddButtons = false;
  @Input() allowAddNewTestPCR = false;
  @Input() allowDelete = true;
  @Input() showSearchBar = false;
  @Input() disableEdit = false;
  @Input() allowArchive = false;
  @Input() showExport = false;
  @Input() selectedCompany: Company | null = null;
  @Input() selectedOrder: Order | null = null;
  @Input() autoRefresh = false;
  @Input() patients: Person[] = [];

  allowDeleteFinishedTest = false;
  selectedPatient: Person | null = null;
  privateKey = '';
  showAddNewTestUserPCR = false;
  showAddNewTestUser = false;
  showResendResultDialog = false;
  showEditTestUserModal = false;
  showOrderQRCode = false;
  showCheckinDialog = false;
  showTestResultDialog = false;
  showPCRTestResultDialog = false;
  showSelfcheckinModal = false;
  sortToggle = true;
  currentOrder: any = null;
  setIntervalId: any = null;
  userMessage = '';
  isArchive = false;
  isWalkInCompany: boolean | undefined = false;
  companyOrderMode = true;
  showPrintPDF = false;
  timoutFilterId: any = 0;

  _patients: Person[] = [];

  sortState = {
    testkit_id: true,
    lastname: true,
    birthday: true,
    test_type: true,
    test_location: true,
    test_time: true,
    testresult: true,
  };
  showPDFPreview = false;
  pdfSource: any = '';
  testresultReceived = false;

  public userAllowedToSeeUnreceivedResults = false;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    if (!this.showAddNewTestUser &&
      !this.showAddNewTestUserPCR &&
      !this.showResendResultDialog &&
      !this.showEditTestUserModal &&
      !this.showOrderQRCode &&
      !this.showCheckinDialog &&
      !this.showTestResultDialog &&
      !this.showSelfcheckinModal) {
      this.escape.emit();
    }
  }

  constructor(
    @Inject('Window') public window: Window,
    public sharedService: SharedService,
    private permissions: NgxPermissionsService,
    private companyService: CompanyService,
    private sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private translate: TranslateService) {
    if (this.authenticationService.getPrivateKey() !== '') {
      this.privateKey = this.authenticationService.getPrivateKey();
      this.showPrintPDF = true;
    }
    const userRole = this.authenticationService.getLoggedInUser().role;
    // Nobody is allowed to see unreceived test results anymore
    /* if(userRole === "superadmin" || userRole === "superadmin_readonly" || userRole === "admin" || userRole === "sc_manager") {
      this.userAllowedToSeeUnreceivedResults = true;
    } */
    this.allowDeleteFinishedTest = !!this.permissions.getPermission(PERMISSIONS.CAN_TESTUSER_FINISHED_TEST_TABLE_DELETE);
    this.allowDelete = !!this.permissions.getPermission(PERMISSIONS.CAN_TESTUSER_TABLE_DELETE);
    this.isWalkInCompany = this.authenticationService.getLoggedInUser().companyIsWalkIn;

    if (this.autoRefresh && this.selectedOrder && this.selectedOrder) {
      this.setIntervalId = setInterval(() => {
        this.companyService.getCompanyOrderTests(this.selectedOrder?.id, this.selectedCompany?.id, this.privateKey).subscribe(response => {
          this.patients = response.data;
          this._patients = [ ...response.data ];
          if (this.currentOrder) {
            this.sortList(this.currentOrder?.key, this.currentOrder?.elem, true);
          }
        });
      }, 1000 * 60 * 5); // 5 minutes
    }
  }

  ngOnInit(): void {
    this.companyOrderMode = !!(this.selectedCompany && this.selectedOrder);

    if (this.companyOrderMode) {
      this.loadOrderTests();
    }
  }

  loadOrderTests(): void {
    this.companyService.getCompanyOrderTests(this.selectedOrder?.id, this.selectedCompany?.id, this.privateKey).subscribe(response => {
      this.patients = response.data;
      this._patients = [ ...response.data ];
      this.isArchive = this.patients && this.patients.some(item => item.is_archive);
    });
  }

  search(e: any): void {
    if (this.timoutFilterId) {
      clearTimeout(this.timoutFilterId);
    }
    const searchValue = e.value;
    if (!searchValue) {
      this.patients = this._patients;
    } else {
      this.timoutFilterId = setTimeout(() => {
        this.patients = this._patients.filter(patient => patient.lastname?.toLowerCase()?.includes(searchValue.toLowerCase()));
      }, (this._patients.length < 100) ? 0 : 1000);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.setIntervalId);
  }

  public sanitize(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  showSelfCheckInInfo(): void {
    this.showSelfcheckinModal = true;
  }

  showOrderQrCode(event: Event, person: Person): void {
    event.stopPropagation();
    this.selectedPatient = person;
    this.showOrderQRCode = true;
  }

  archiveCompanyOrder(): void {
    this.translate.get('COMPANY.ARCHIVE_ORDER', { name: this.selectedOrder?.name }).subscribe((text: string) => {
      if (confirm(text)) {
        this.companyService.archiveCompanyOrder(this.selectedCompany?.id, this.selectedOrder?.id).subscribe(
          (response: any) => {
            this.translate.get('COMPANY.ARCHIVE_ORDER_SUCCESS', { name: this.selectedOrder?.name }).subscribe((success: string) => {
              this.userMessage = success;
            });
          },
          (error: any) => {
            this.actionError.emit(error?.error?.error?.description ?? 'Archive Error');
          }
        );
      }
    });
  }

  exportTestuserData(): void {
    const csvPatients = this.patients.map(data => {
      // tslint:disable-next-line:max-line-length
      const { status, test_user_id, testkit_id, test_location, test_type, test_time, is_archive, consent_given, testresult, ...patient} = data;
      return patient;
    });
    exportTestuserData(csvPatients, null, this.selectedCompany?.name + '_' + this.selectedOrder?.name + '.csv');
  }

  exportResults(): void {
    this.companyService.exportResults(this.selectedCompany?.id, this.selectedOrder?.id, this.privateKey).subscribe(
      (response: any) => {
        const data = response.data;
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});
        FileSaver.saveAs(blob, 'export.pdf');
      },
      (error: any) => {
        this.actionError.emit(error?.error?.error?.description ?? 'Export Error');
      }
    );
  }

  deleteUser(user: Person, event: Event): void {
    event.stopPropagation();
    this.companyService.deleteCompanyTestUser(user.company_id, user.company_order_id, user.order_id).subscribe(
      (response: any) => {
        this.patients = this.patients.filter(item => item.order_id !== user?.order_id);
      },
      (error: any) => {
        this.actionError.emit(error?.error?.error?.description ?? 'Delete Error');
      }
    );
  }

  possibleActionsOnItem(patient: Person): boolean {
    const allowCheckIn = this.permissions.getPermission(PERMISSIONS.CAN_CHECKIN) && !this.disableCheckIn;
    const allowEdit = this.permissions.getPermission(PERMISSIONS.EDIT_COMPANY_TESTUSER) && !this.disableEdit;
    const allowResult = this.permissions.getPermission(PERMISSIONS.CAN_ENTER_RESULT_COMPANY_TABLE);
    const allowEditAfterCheckin = this.permissions.getPermission(PERMISSIONS.EDIT_COMPANY_TESTUSER_AFTER_CHECKIN) && !this.disableEdit;
    const status = +patient.status!;

    if (allowEditAfterCheckin) {
      return true;
    }

    if (allowEdit && !allowCheckIn && status === 0) {
      return true;
    } else if (allowEdit && !allowCheckIn && status !== 0) {
      return false;
    }

    if (status === 0 && allowCheckIn) {
      return true;
    }

    if (status === 1 && allowResult && allowCheckIn && patient?.test_type !== TestTypes.PCR_LAB_FREE) {
      return true;
    }

    if (status === 2 && this.allowAddNewTestPCR && patient.test_type!.includes('antigen') && patient.testresult === 'positive') {
      return true;
    }

    return false;
  }

  onSelectPatient(patient: Person): void {
    this.selectedPatient = patient;

    const allowCheckIn = this.permissions.getPermission(PERMISSIONS.CAN_CHECKIN) && !this.disableCheckIn;
    const allowEdit = this.permissions.getPermission(PERMISSIONS.EDIT_COMPANY_TESTUSER) && !this.disableEdit;
    const allowEditAfterCheckin = this.permissions.getPermission(PERMISSIONS.EDIT_COMPANY_TESTUSER_AFTER_CHECKIN) && !this.disableEdit;
    const allowResult = this.permissions.getPermission(PERMISSIONS.CAN_ENTER_RESULT_COMPANY_TABLE);

    if (allowEdit && !allowCheckIn && +patient.status! === 0) {
      this.showEditTestUserModal = true;
      return;
    }

    switch (+patient.status!) {
      case 0:
        if (allowCheckIn) {
          this.showCheckinDialog = true;
        }
        break;
      case 1:
        if (allowResult && allowCheckIn && patient?.test_type !== TestTypes.PCR_LAB_FREE && patient?.test_type !== TestTypes.PCR_LAB_PAID) {
          if ([TestTypes.PCR_EXPRESS, TestTypes.PCR_STANDARD].includes(patient?.test_type!)) {
            this.showPCRTestResultDialog = true;
          } else {
            this.showTestResultDialog = true;
          }
        } else if (allowEditAfterCheckin) {
          this.showEditTestUserModal = true;
        }
        break;
      case 2:
        if (this.allowAddNewTestPCR && patient.test_type!.includes('antigen') && patient.testresult === 'positive') {
          this.showAddNewTestUserPCR = true;
          return;
        }
        if (allowEditAfterCheckin) {
          this.showEditTestUserModal = true;
        }
        break;
      default:
        break;
    }

  }

  sortList(key: keyof Person, elem: HTMLSpanElement, skipSortOder = false): void {
    document.querySelectorAll('.item.normal').forEach((element: Element) => element.classList.remove('up', 'down', 'active'));
    this.currentOrder = {
      key,
      elem,
    };

    if (skipSortOder) {
      // @ts-ignore
      this.sortState[key] = !this.sortState[key];
    }

    // @ts-ignore
    const compare = this.sortState[key] ? 1 : -1;
    elem.classList.add(compare < 1 ? 'up' : 'down', 'active');

    this.patients.sort((a: any, b: any) => {

      if (key === 'testresult') {
        a = { status: a.status === '2' && !(key in a) ? '_pcr' : a.testresult ?? a.status};
        b = { status: b.status === '2' && !(key in b) ? '_pcr' : b.testresult ?? b.status};

        if (a.status! < b.status!) { return compare; }
        if (a.status! > b.status!) { return -compare; }
      }

      // General fallback for empty values (e.g. testkit id)
      if (!(key in a) || a[key] === null) {
        a = { [key]: 0 };
      }
      if (!(key in b) || b[key] === null) {
        b = { [key]: 0 };
      }

      if (typeof a[key] === 'string' ) {
        a = { [key]: a[key].toLowerCase() };
      }
      if (typeof b[key] === 'string') {
        b = { [key]: b[key].toLowerCase() };
      }

      if (a[key]! < b[key]!) { return compare; }
      if (a[key]! > b[key]!) { return -compare; }

      return 0;
    });

    // @ts-ignore
    this.sortState[key] = !this.sortState[key];
  }

  getPrivacy(person: Person, e: Event): void {
    e.stopPropagation();

    this.companyService.getTestUserConsent(person.company_id, person.order_id).subscribe((res: any) => {
      const dataUrl = res.data.data_privacy_url;
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'privacy';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    });
  }

  sendResult(patient: Person, event: Event): void {
    event.stopPropagation();
    this.selectedPatient = patient;
    this.showResendResultDialog = true;
  }

  downloadInvoice(patient: Person, event: Event): void {
    event.stopPropagation();

    this.selectedPatient = patient;

    this.companyService.getInvoicePdf(
      this.selectedPatient!.order_id,
      this.selectedPatient!.company_id ?? this.selectedCompany?.id,
    ).subscribe(response => {
      // not needed for invoice
      this.testresultReceived = true;
      this.downloadPdfResponse(response)
    }, error => {
      this.actionError.emit(error);
    });
  }

  printResult(patient: Person, event: Event): void {
    event.stopPropagation();
    this.selectedPatient = patient;

    this.companyService.getTestresultPdf(
      this.selectedPatient.test_type,
      this.selectedPatient.company_order_id,
      this.selectedPatient.order_id,
      this.privateKey,
    ).subscribe(response => {
      this.testresultReceived = response.data.received ? response.data.received : false;
      this.downloadPdfResponse(response)
    }, error => {
      this.actionError.emit(error);
    });
  }

  downloadPdfResponse(response: any): void {
    const linkSource = 'data:application/pdf;base64,' + response.data.pdf;
    const link = document.createElement('a');
    link.href = linkSource;

    const ifrm = document.createElement('iframe');
    ifrm.setAttribute('src', linkSource);
    ifrm.style.width = '640px';
    ifrm.style.height = '480px';
    this.pdfSource = this.sanitize(linkSource);
    this.showPDFPreview = true;
  }

  updateTestresultReceivedStatus(): void {
    this.companyService.updateTestresultReceivedStatus(
      this.selectedPatient!.order_id
    ).subscribe(() => {
      this.testresultReceived = true;
    }, error => {
      this.actionError.emit(error);
    });
  }

  resendFinished(event: Event): void {
    this.showResendResultDialog = false;
  }

  userEditFinished(testuser: Person): void {
    this.translate.get('COMPANY.EDIT_USER_SUCCESS').subscribe((success: string) => this.userMessage = success);
    this.patients[this.patients.findIndex(person => person === this.selectedPatient)] = testuser;
    this.showEditTestUserModal = false;
  }

  userUpdateFromCheckin(testuser: Person): void {
    this.patients[this.patients.findIndex(person => person === this.selectedPatient)] = { ...this.selectedPatient, ...testuser };
  }

  addNewTestUser(testuser: Person): void {
    if (testuser) {
      this.patients.push(testuser);
    }
    this.translate.get('COMPANY.ADD_USER_SUCCESS').subscribe((success: string) => this.userMessage = success);
    this.showAddNewTestUserPCR = false;
    this.showAddNewTestUser = false;
  }

  resultFinished(data: any): void {
    this.showTestResultDialog = false;
    this.selectedPatient!.status = '2';
    this.selectedPatient!.testkit_id = data.testkit_id;
    this.selectedPatient!.testresult = data.testresult;
  }

  checkInFinished(lastTestKitId: any): void {
    this.showCheckinDialog = false;
    this.selectedPatient!.testkit_id = lastTestKitId;
    this.selectedPatient!.status = '1';

    this.patients[this.patients.findIndex(person => person.order_id === this.selectedPatient!.order_id)].testkit_id = lastTestKitId;
    this.patients[this.patients.findIndex(person => person.order_id === this.selectedPatient!.order_id)].status = '1';

    this.selectedPatient = null;
  }

}
