<div *ngIf="pdfLink">
  <p class="description-text">{{ 'REGISTRATION.PDF_LINK' | translate }}: <a [href]="sanitize(pdfLink)" target="_blank">PDF-Link</a></p>
  <br>
</div>

<p class="description-text">{{ 'REGISTRATION.DESCRIPTION_FIRSTNAME' | translate }} {{ 'REGISTRATION.DESCRIPTION_LASTNAME' | translate }}</p>
<h3>{{ customerName }}</h3>
<br>
<ng-container *ngIf="!showCompanyAntigenButton">
  <p *ngIf="!inputMode">{{ 'REGISTRATION.TEXT_HEADER_TESTKIT_CHOICE' | translate }}</p>
  <p *ngIf="inputMode">{{ 'REGISTRATION.TEXT_HEADER_TESTKIT_INPUT' | translate }}</p>
</ng-container>

<ng-container *ngIf="eurofinsType == 'free' || eurofinsType == 'paid'">
  <div class="rounded-top rounded-bottom row" style="margin:0; padding:6px 0 10px 0; width:{{wVal}}; border: none; background-color:rgb(240, 240, 240);">
    <div class="col-5" *ngIf="eurofinsType == 'free'"><b>{{ 'REGISTRATION.EUROFINS_INFO_INTRO' | translate }}</b><br>{{ 'REGISTRATION.EUROFINS_INFO_FREE' | translate }}</div>
    <div class="col-5" *ngIf="eurofinsType == 'paid'"><b>{{ 'REGISTRATION.EUROFINS_INFO_INTRO' | translate }}</b><br>{{ 'REGISTRATION.EUROFINS_INFO_PAID' | translate }}</div>
    <div class="col">
      <i>{{ 'REGISTRATION.EUROFINS_INFO_PIC_LABEL' | translate }}</i><br>
      <img *ngIf="eurofinsType == 'free'" style="width:50%; min-width:100px; margin-top:6px;" src="/assets/images/eurofins_qr_free.jpg" alt="eurofins qr free">
      <img *ngIf="eurofinsType == 'paid'" style="width:50%; min-width:100px; margin-top:6px;" src="/assets/images/eurofins_qr_paid.jpg" alt="eurofins qr paid">
    </div>
  </div>
  <br>
</ng-container>

<div class="alert alert-warning" role="alert" [hidden]="!showValidationError">
  {{ 'REGISTRATION.TEXT_ERROR_VALIDATION' | translate }}
</div>
<div class="center" [class.hide-container]="showCompanyAntigenButton">

  <ng-container *ngIf="!inputMode">
    <app-test-type-item
      *ngFor="let testType of this.testKitEntries"
      [data]="testType"
      [class.done]="testType.done"
      (click)="setActiveTestType(testType)"
      class="type-item"></app-test-type-item>
  </ng-container>

  <ng-container *ngIf="inputMode">
    <input #testKitInput type="text" class="rounded d-block mb-2"
           [pattern]="validationPattern"
           [class.invisible]="showCompanyAntigenButton"
           [formControl]="activeTestKitEntry.formControl"
           required
           placeholder="{{ activeTestKitEntry.label | translate }} {{ 'REGISTRATION.PLACEHOLDER_TESTKIT' | translate }}"/>
  </ng-container>
  <iframe #iframe></iframe>

</div>
<div class="center" *ngIf="inputMode">

  <button class="custom-btn antigen-company-btn rounded" (click)="submit()" *ngIf="showCompanyAntigenButton">
    {{ companyAntigenButtonLabel }}
  </button>

  <button class="custom-btn rounded" (click)="setCustomId()" *ngIf="showCustomIdButton">
    Eigene ID eingeben
  </button>

  <button class="custom-btn rounded mb-1" (click)="submit()" *ngIf="!showCompanyAntigenButton">
    {{ 'REGISTRATION.BTN_SUBMIT' | translate }}
  </button>

  <app-back-button (click)="back()" *ngIf="!companyMode"></app-back-button>
</div>
