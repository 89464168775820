const templateDe = `
    <div>
      <img src="/assets/images/mcs_logo.svg">
    </div>
    <div>
      <h3>Einwilligungs- & Datenschutzerklärung</h3>
      <p>Einwilligungserklärung zur Durchführung von Corona Tests & Datenschutzerklärung zur Verarbeitung meiner Daten und Übermittlung meines Testergebnisses durch die Praxis Dr. M. Reith und den von ihr beauftragten Praxisdienstleister Strand und Wiesen GmbH (nachfolgend gemeinschaftlich MEIN CORONA SCHNELLTEST genannt) an den Auftraggeber der vorliegenden Testung.</p>
      <p>Hiermit stimme ich zu, einen Corona Test (Antigen-Schnelltest/ PCR Test oder Antikörper Test) von MEIN CORONA SCHNELLTEST vornehmen zu lassen. Ich bin mit der Weitergabe der Informationen, die zum Zweck der Abrechnung der erbrachten ärztlichen Leistungen jeweils erforderlich sind, insbesondere der Patientenkartei entnommenen Informationen (Name, Geburtsdatum, Anschrift, Testergebnis, Untersuchungs- und Behandlungsdaten) einverstanden.</p>
      <p>Ich erkläre, dass ich keine akuten Krankheitssymptome habe, die auf eine Infektion mit SARS-CoV-2 hindeuten. Ich bin hiermit informiert worden, dass MEIN CORONA SCHNELLTEST daher keine Produkthaftungsgarantie für die verwendeten Corona Tests übernimmt. Ich bin aufgeklärt worden, dass in sehr wenigen Fällen Corona Tests nicht das richtige Ergebnis zeigen können. Ursachen können eine niedrige Qualität des entnommenen Materials - oder nachträgliche Verunreinigungen sein, ebenso ein Ausfall der Analyse durch unvorhersehbare oder unbekannte (technische) Gründe. MEIN CORONA SCHNELLTEST ist in solchen Fällen nicht für das dann möglicherweise nicht korrekte Ergebnis des Corona Tests verantwortlich.</p>
      <p>Mir ist bewusst, dass es sich bei SARS-CoV-2 um eine meldepflichtige Krankheit nach dem Infektionsschutzgesetz handelt und dass ein positives Corona Testergebnis an das zuständige Gesundheitsamt gemeldet werden muss. Ich bin einverstanden, dass das Testergebnis von MEIN CORONA SCHNELLTEST auf lokalen Datenträgern und auf Servern v für 3 Tage abgespeichert wird. Meine Einwilligung erstreckt sich ausdrücklich auf die Verarbeitung von Gesundheitsdaten im Sinne des Art. 9 Datenschutz-Grundverordnung (DSGVO). Ich bin damit einverstanden, dass mich MEIN CORONA SCHNELLTEST über die von mir angegebenen Kontaktdaten über mein Testergebnis informiert. Ich bin zudem einverstanden, dass MEIN CORONA SCHNELLTEST meinen Arbeitgeber bzw. den jeweiligen Auftraggeber der vorliegenden Testung über mein Testergebnis informiert. Diese Einwilligung ist freiwillig. Ich kann sie ohne Angabe von Gründen verweigern. Ich kann meine Einwilligung zudem jederzeit widerrufen; in diesem Fall werden meine Daten von MEIN CORONA SCHNELLTEST unverzüglich gelöscht. Der Widerruf erstreckt sich nicht auf Datenverarbeitungen, die im Rahmen der Aufgabenerfüllung der zuständigen Behörden nach einer Meldung an diese stattfinden (vgl. Ziffer 4 der Informationen gemäß Art. 13 DSGVO).</p>
      <p>Informationen gemäß Art. 13 Datenschutz-Grundverordnung (DSGVO):<br>Name und Kontaktdaten des Verantwortlichen: MEIN CORONA SCHNELLTEST, Strand und Wiesen GmbH, Petersbergstraße 44, 50939 Köln. Kontaktdaten des Datenschutzbeauftragten: Der Kontakt des Datenschutzbeauftragten von MEIN CORONA SCHNELLTEST ist auf der Website www.meincoronaschnelltest.de und unter datenschutz@meincoronaschnelltest.de bekanntgegeben bzw. erreichbar. Zwecke und Rechtsgrundlagen der Verarbeitung: Die personenbezogenen Daten werden zum Zweck der Prävention und Bekämpfung des Coronavirus SARS-CoV-2 gemäß Art. 9 Abs. 2 Buchstabe a) i.V.m. Art. 6 Abs. 1 UAbs.1 Buchst. a) DSGVO aufgrund der Einwilligung verarbeitet. Empfänger von personenbezogenen Daten, Anlass der Offenlegung: Gesundheitsamt im Rahmen von Meldepflichten gemäß Art. 9 des Infektionsschutzgesetzes (IfSG).</p>
      <p>Dauer der Speicherung der personenbezogenen Daten: Bis zum Widerruf der Einwilligung. Das Testergebnis wird nach maximal 3 Tagen gelöscht. Betroffenenrechte: Es besteht das Recht auf Auskunft über die zu der Person gespeicherten Daten (Art. 15 DSGVO). Sollten unrichtige personenbezogene Daten verarbeitet werden, gibt es das Recht auf Berichtigung (Art. 16 DSGVO). Liegen die gesetzlichen Voraussetzungen vor, so kann die Löschung oder Einschränkung der Verarbeitung verlangt werden (Art. 17 und 18 DSGVO). Wenn in die Verarbeitung eingewilligt wurde oder ein Vertrag zur Datenverarbeitung besteht und die Datenverarbeitung mithilfe automatisierter Verfahren durchgeführt wird, gibt es gegebenenfalls ein Recht auf Datenübertragbarkeit (Art. 20 DSGVO). Es gibt das Recht, aus Gründen, die sich aus der besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Daten Widerspruch einzulegen, wenn die Verarbeitung ausschließlich auf Grundlage des Art. 6 Abs. 1 Buchst. e oder f DSGVO erfolgt (Art. 21 Abs. 1 Satz 1 DSGVO). Falls in die Verarbeitung eingewilligt wurde und die Verarbeitung auf dieser Einwilligung beruht, kann die Einwilligung jederzeit für die Zukunft widerrufen werden. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Datenverarbeitung wird dadurch nicht berührt. Weiterhin besteht ein Beschwerderecht beim Landesbeauftragten für den Datenschutz.</p>
      <p>Mit der nachfolgenden Unterschrift stimme ich der oben stehenden Datenschutzerklärung zu.</p>
    </div>
`;

export default templateDe;
