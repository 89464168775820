<input #inputCsv type="file"><br>
<div class="btn-container">
  <button class="custom-btn rounded" (click)="upload()">{{ 'REGISTRATION.BTN_CONFIRM' | translate }}</button>
  <div class="info-wrap">
    <div class="warning-msg"><b>Achtung:</b> Neues CSV-Format beachten ⮯</div>
    <a href="/assets/mitarbeiter_beispiel.csv" target="_blank"><fa-icon [icon]="faFileDownload"></fa-icon> Download: Beispiel CSV</a>
  </div>
</div>

<ngx-spinner
  [name]="'upload-spinner'"
  bdColor="rgba(51,51,51,0.8)"
  size="large"
  color="#fff"
  type="ball-clip-rotate">
  <p style="margin-top: 80px; font-size: 20px; color: white">{{ 'APP.UPLOADING' | translate:{'current':itemsCounter,'total':totalElements} }}</p>
</ngx-spinner>
