import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input() loadingText: string | undefined;

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    if (!this.loadingText) {
      this.translate.get('APP.PLEASE_WAIT').subscribe(text => this.loadingText = text);
    }
  }

}
