<app-alert *ngIf="errorMessage" [message]="errorMessage"></app-alert>
<ng-container *ngIf="deviceList && deviceList.length">
  <div class="select-wrap">
    <select #deviceSelect (change)="changeDevice($event)">
      <option *ngFor="let device of deviceList" value="{{device.id}}">{{ device.label }}</option>
    </select>
    <button class="custom-btn rounded" style="width: auto;" (click)="initVerify()"><fa-icon [icon]="faAddressCard"></fa-icon> {{ 'VERIFICATION.VERIFY' | translate }}</button>
  </div>
 </ng-container>
<app-modal *ngIf="modalOpen" (closeClicked)="abortVerify()">
  <app-loading-spinner *ngIf="isVerifying && !result" [loadingText]="loadingInstructions"></app-loading-spinner>
  <ng-container *ngIf="!isVerifying && result">
    <h3>{{ 'VERIFICATION.RESULT_TITLE' | translate }}</h3>
    <table class="preview-data">
      <tr>
        <td>{{ 'COMPANYADMIN.EMPLOYEE_PASSPORTNO' | translate}}</td>
        <td>{{ result.idcardno }}</td>
      </tr>
      <tr>
        <td>{{ 'REGISTRATION.DESCRIPTION_FIRSTNAME' | translate}}</td>
        <td>{{ result.firstname }}</td>
      </tr>
      <tr>
        <td>{{ 'REGISTRATION.DESCRIPTION_LASTNAME' | translate}}</td>
        <td>{{ result.lastname }}</td>
      </tr>
      <tr>
        <td>{{ 'COMPANYADMIN.EMPLOYEE_SEX' | translate}}</td>
        <td>{{ result.sex | genderLabel }}</td>
      </tr>
      <tr>
        <td>{{ 'REGISTRATION.DESCRIPTION_BIRTH' | translate}}</td>
        <td>{{ result.birthday | date: 'dd.MM.yyyy' }}</td>
      </tr>
      <tr>
        <td>{{ 'REGISTRATION.DESCRIPTION_STREET' | translate}}</td>
        <td>{{ result.address }}</td>
      </tr>
      <tr>
        <td>{{ 'REGISTRATION.DESCRIPTION_CITY' | translate}}</td>
        <td>{{ result.city }}</td>
      </tr>
      <tr>
        <td>{{ 'REGISTRATION.DESCRIPTION_ZIP' | translate}}</td>
        <td>{{ result.zip }}</td>
      </tr>
    </table>
  </ng-container>
  <div class="btn-container">
    <button class="custom-btn rounded" (click)="confirm()" [disabled]="isVerifying">{{ 'VERIFICATION.OK' | translate }}</button>
    <button class="custom-btn rounded cancel-btn" (click)="abortVerify()">{{ 'VERIFICATION.CANCEL' | translate }}</button>
  </div>
</app-modal>


