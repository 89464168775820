<div class="wrapper">
  <h1>{{ 'TESTRESULT.TITLE' | translate }}</h1>

  <!-- Error Message -->
  <app-alert *ngIf="errorMessage" [message]="errorMessage" [serverMessage]="serverErrorMessage"></app-alert>

  <!-- Header -->

  <div class="center no-margin">
    <p *ngIf='!this.sucessfullyDone'>{{ description }}</p>
    <p class="alert alert-success" role="alert" *ngIf='this.sucessfullyDone'>{{ description }}</p>
     <div class="center" [hidden]="showTestKistContainer || showSummaryContainer">
       <p class="description-text">{{ 'TESTRESULT.TEXT_SUMMARY_NUMBER' | translate }}</p>
       <h3>{{ summaryTestKit }}</h3>
       <p class="description-text">{{ 'TESTRESULT.TEXT_SUMMARY_TYPE' | translate }}</p>
       <h3>{{ summaryType }}</h3>
     </div>
  </div>

  <!-- Testkit Entry Container -->

  <div [hidden]="!showTestKistContainer || isModal">
    <div class="center">
      <input #textInput type="text" id="textinput" class="rounded" (keyup.enter)="testKitSubmit()"
        [formControl]="testKitNumber" pattern="^[A-Z0-9]{8,9}$"
        placeholder="{{ 'TESTRESULT.TEXT_SUMMARY_NUMBER' | translate }}" />
    </div>
    <div class="center">
      <button class="custom-btn rounded" (click)="testKitSubmit()">Suchen</button>
    </div>
  </div>

  <!-- Result Container ANTIGEN -->
  <div *ngIf="type.includes('antigen')">
    <div [hidden]="!showResultContainer">
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultSubmit('positive')">{{ 'TESTRESULT.POSITIV' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultSubmit('invalid')">{{ 'TESTRESULT.INVALID' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultSubmit('negative')">{{ 'TESTRESULT.NEGATIV' | translate }}</button>
      </div>
    </div>

    <div [hidden]="!showResultCheckContainer">
      <div class="devider"></div>
      <div class="center">
        <p>{{ 'TESTRESULT.TEXT_RESULT_REPEAT' | translate }}</p>

        <button class="result-btn rounded"
          (click)="resultCheckSubmit('positive')">{{ 'TESTRESULT.POSITIV' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultCheckSubmit('invalid')">{{ 'TESTRESULT.INVALID' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultCheckSubmit('negative')">{{ 'TESTRESULT.NEGATIV' | translate }}</button>
      </div>
    </div>
  </div>

  <!-- Result Container ANTIBODY -->
  <div *ngIf="type === 'antibody'">
    <div [hidden]="!showResultContainer">
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultSubmit('negative')">{{ 'TESTRESULT.NEGATIV' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultSubmit('invalid')">{{ 'TESTRESULT.INVALID' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultSubmit('IgG')">{{ 'TESTRESULT.POSITIV_IGG' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultSubmit('IgM')">{{ 'TESTRESULT.POSITIV_IGM' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultSubmit('IgG+IgM')">{{ 'TESTRESULT.POSITIV_BOTH' | translate }}</button>
      </div>
    </div>

    <div [hidden]="!showResultCheckContainer">
      <div class="devider"></div>
      <div class="center">
        <p>{{ 'TESTRESULT.TEXT_RESULT_REPEAT' | translate }}</p>

        <button class="result-btn rounded"
          (click)="resultCheckSubmit('negative')">{{ 'TESTRESULT.NEGATIV' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultCheckSubmit('invalid')">{{ 'TESTRESULT.INVALID' | translate }}</button>
      </div>
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultCheckSubmit('IgG')">{{ 'TESTRESULT.POSITIV_IGG' | translate }}</button>
      </div>
       <div class="center">
         <button class="result-btn rounded"
           (click)="resultCheckSubmit('IgM')">{{ 'TESTRESULT.POSITIV_IGM' | translate }}</button>
       </div>
      <div class="center">
        <button class="result-btn rounded"
          (click)="resultCheckSubmit('IgG+IgM')">{{ 'TESTRESULT.POSITIV_BOTH' | translate }}</button>
      </div>
    </div>
  </div>

  <!-- Summary Container -->

  <div [hidden]="!showSummaryContainer">
    <div class="center">
      <p class="description-text">{{ 'TESTRESULT.TEXT_SUMMARY_NUMBER' | translate }}</p>
      <h3>{{ summaryTestKit }}</h3>

      <p class="description-text">{{ 'TESTRESULT.TEXT_SUMMARY_TYPE' | translate }}</p>
      <h3>{{ summaryType }}</h3>

      <p class="description-text">{{ 'TESTRESULT.TEXT_SUMMARY_RESULT' | translate }}</p>
      <h3>{{ summaryResult }}</h3>
    </div>
    <div class="center">
      <button class="custom-btn rounded" (click)="submit()">{{ 'TESTRESULT.BTN_SUBMIT' | translate }}</button>
    </div>
  </div>

  <!-- Cancel Message -->

  <div class="center">
    <button *ngIf='sucessfullyDone' class="cancel-btn rounded"
      (click)="cancel()">{{ 'TESTRESULT.BTN_OK' | translate }}</button>
  </div>

  <app-back-button *ngIf="!sucessfullyDone && !showTestKistContainer" (click)="cancel()"></app-back-button>
</div>
