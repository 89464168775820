import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UserService} from '../../../user.service';
import {TestType, TestTypes} from '../../checkin.component';

@Component({
  selector: 'app-third-party-consent',
  templateUrl: './third-party-consent.component.html',
  styleUrls: ['./third-party-consent.component.scss']
})
export class ThirdPartyConsentComponent implements OnInit {

  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Input() useAppCwa = false;
  @Input() useAppLuca = false;
  @Input() orderId: any;
  @Input() disabled = false;
  @Input() testTypes: TestType[] | undefined;

  useAppCwaInput = new FormControl();
  useAppLucaInput = new FormControl();

  disableCWA = false;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.useAppCwaInput.setValue(this.useAppCwa);
    this.useAppLucaInput.setValue(this.useAppLuca);
    this.disableCWA = !this.isAntigen() && !this.isPcrLab();

    if (this.disabled) {
      this.useAppCwaInput.setValue(0);
      this.useAppLucaInput.setValue(0);
      this.useAppCwaInput.disable();
      this.useAppLucaInput.disable();
    }

    if (this.disableCWA) {
      this.useAppCwaInput.setValue(0);
      this.useAppCwaInput.disable();
    }
  }

  isAntigen(): boolean {
    return [TestTypes.ANTIGEN_FREE, TestTypes.ANTIGEN, TestTypes.ANTIGEN_PAID, TestTypes.ANTIGEN_PAID_REDUCED, TestTypes.ANTIGEN_CARD, TestTypes.ANTIGEN_CARD_USE, TestTypes.ANTIGEN_CARD_TEAM_PAID, TestTypes.ANTIGEN_CARD_TEAM_FREE].includes(this.testTypes![0].testType);
  }

  isPcrLab(): boolean {
    return [TestTypes.PCR_LAB_FREE, TestTypes.PCR_LAB_PAID].includes(this.testTypes![0].testType);
  }

  confirm(): void {
    this.userService.appConsent(this.orderId, this.useAppCwaInput.value, this.useAppLucaInput.value)
      .subscribe((res: any) => this.finished.emit());
  }
}
