<h4 *ngIf="headline">{{ headline | translate }}</h4>

<app-alert *ngIf="errorMessage" [message]="errorMessage" [type]="'danger'"></app-alert>

<div class="row">
  <div class="col-md-6">
    <app-verification *ngIf="allowVerification" (receiveData)="receiveData($event)" [companyId]="companyId"></app-verification>
  </div>
  <div class="col-md-6 btn-wrap">
    <button class="custom-btn rounded" style="margin:0; width: auto;" (click)="openCWAvCardImport()"><fa-icon></fa-icon> {{ 'COMPANYCHECKIN.IMPORT_CWA_VCARD' | translate }}</button>
    <button *ngIf="showOEGDDownload" class="custom-btn rounded oegd-import" (click)="oegdDownload()">{{ 'COMPANYCHECKIN.OEGD_PRINT' | translate }}</button>
  </div>
</div>

<form class="row g-3" [formGroup]="form">
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYADMIN.EMPLOYEE_SEX' | translate }}</label>
    <select class="form-control" formControlName="sex">
      <option [ngValue]="''" disabled selected>{{ 'COMPANYADMIN.CHOOSE_LABEL' | translate }}</option>
      <option value="ms">weiblich</option>
      <option value="mr">männlich</option>
      <option value="d">divers</option>
    </select>
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_IDCARDNO' | translate }}</label>
    <input type="text" class="form-control" formControlName="idcardno">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_FIRST' | translate }}</label>
    <input type="text" class="form-control" formControlName="firstname">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_LAST' | translate }}</label>
    <input type="text" class="form-control" formControlName="lastname">
  </div>
  <div class="col-md-6" *ngIf="addMode">
    <label class="form-label">{{ 'COMPANYCHECKIN.JOB_FUNCTION' | translate }}</label>
    <input type="text" class="form-control" formControlName="job_function">
  </div>
  <div class="col-md-6" *ngIf="addMode">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_TESTTYPE' | translate }}</label>
    <select class="form-control" formControlName="test_type">
      <option [ngValue]="null" disabled selected>{{ 'COMPANYADMIN.CHOOSE_LABEL' | translate }}</option>

      <ng-container *ngIf="!isShoppingcenter">
        <option value="pcr-exp" [disabled]="isShoppingcenter">PCR-Express</option>
        <option value="pcr-std" [disabled]="isShoppingcenter">PCR-Premium</option>
      </ng-container>

      <option value="pcr-lab-paid">PCR-Labor (bezahlt)</option>
      <option value="pcr-lab-free">PCR-Labor (angeordnet, KV)</option>

      <option value="antigen-team-free">Antigen Team (frei)</option>
      <option value="antigen-paid">Antigen (bezahlt)</option>
<!--      <option [disabled]="!isShoppingcenter" value="antigen-team-paid">Antigen Team (bezahlt)</option>-->
<!--      <option [disabled]="!isShoppingcenter" value="antigen-card">Antigen 10er Karte (bezahlt)</option>-->
      <option [disabled]="!isShoppingcenter" value="antigen-card-use">Antigen 10er Karte (einlösen)</option>

      <ng-container *ngIf="!isShoppingcenter">
        <option value="antigen">Antigen</option>
      </ng-container>

      <option value="antigen-free">kostenloser Bürgertest</option>
      <option value="antigen-paid-reduced">Bürgertest (3 EUR)</option>
    </select>
  </div>
  <div class="col-md-6" *ngIf="addMode && (form.controls.test_type.value === 'antigen-free' || form.controls.test_type.value === 'antigen-paid-reduced' || form.controls.test_type.value === 'antigen-paid')">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_REASON' | translate }}</label>
    <select *ngIf="reasons" class="form-control" formControlName="reason" id="reason-select">
      <option [ngValue]="null" disabled>{{ 'COMPANYADMIN.CHOOSE_LABEL' | translate }}</option>
      <ng-container *ngFor="let reason of reasons">
        <option *ngIf="form.controls.test_type.value === reason.test_type"  [value]="reason.label">{{ reason.title }}</option>
      </ng-container>
    </select>
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_BIRTH' | translate }}</label>
    <input type="text" class="form-control" formControlName="birthday">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_EMAIL' | translate }}</label>
    <input type="text" class="form-control" formControlName="email">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_PHONE' | translate }}</label>
    <input type="text" class="form-control" formControlName="phone">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_ADDRESS' | translate }}</label>
    <input type="text" class="form-control" formControlName="address">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_COUNTRY' | translate }}</label>
    <select class="form-control" formControlName="country">
      <option *ngFor="let country of countries" [value]="country.name">{{ country.name }}</option>
    </select>
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_ZIP' | translate }}</label>
    <input type="text" class="form-control" formControlName="zip">
  </div>
  <div class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_CITY' | translate }}</label>
    <input type="text" class="form-control" formControlName="city">
  </div>

  <div *ngIf="addMode" class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_TESTTIME' | translate }}</label>
    <input type="text" class="form-control" formControlName="test_time">
  </div>
  <div *ngIf="addMode" class="col-md-6">
    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_TESTLOCATION' | translate }}</label>
    <input type="text" class="form-control" formControlName="test_location">
  </div>

  <div *ngIf="showOrderSelection" class="col-md-12">
    <label class="form-label">{{ 'COMPANYCHECKIN.KV_ORDER_SELECTION' | translate }}</label>
    <select class="form-control" formControlName="target_order_option">
      <option value="current">aktueller Auftrag</option>
      <option value="custom">wählen...</option>
    </select>

    <ng-container *ngIf="form.controls.target_order_option.value === 'custom'">
      <br>
      <select class="form-control" formControlName="target_company">
        <option selected [value]="null">Firma wählen</option>
        <option *ngFor="let company of companies" [value]="company.id">{{ company.name }}</option>
      </select>
      <br>
      <select class="form-control" formControlName="target_order" *ngIf="selectedCompany">
        <option selected [value]="null">Auftrag wählen</option>
        <option *ngFor="let order of selectedCompany.orders" [value]="order.id">{{ order.name }}</option>
      </select>
    </ng-container>

  </div>
<!--  <div class="col-md-6" *ngIf="addMode && form.get('test_type')!.value === 'antigen-free'">-->
<!--    <label class="form-label">{{ 'COMPANYCHECKIN.PATIENT_REASON' | translate }}</label>-->

<!--    <select class="form-control" formControlName="reason">-->
<!--      <option [ngValue]="''" disabled selected>{{ 'COMPANYADMIN.CHOOSE_LABEL' | translate }}</option>-->

<!--      <option value="1a">Anordnung durch Arzt/Gesundheitsamt</option>-->
<!--      <option value="1b">Corona Warn App auf ROT</option>-->
<!--      <option value="2">Testperson unter 13 J.</option>-->
<!--      <option value="3">Impfung nicht möglich (z.B. Schwangerschaft)</option>-->
<!--      <option value="4">Testperson unter 18 J.</option>-->
<!--      <option value="5">Teilnahme klinische Studie</option>-->
<!--      <option value="6">Quarantäne</option>-->
<!--      <option value="7">Arbeit im medizinischem Bereich</option>-->
<!--      <option value="8">Studierende mit alternativer Impfung</option>-->

<!--    </select>-->

<!--  </div>-->

  <div class="col-12">
    <button [disabled]="!form.valid" (click)="save()" class="custom-btn rounded">{{ (addMode ? 'COMPANYCHECKIN.BTN_ADD' : 'COMPANYCHECKIN.BTN_SAVE') | translate }}</button>
  </div>
</form>

<app-modal *ngIf="showUserPaymentModal" (closeClicked)="showUserPaymentModal = false">
  <div class="selfcheckin-box">

    <p>Hat der Kunde bezahlt?</p>

    <div class="button-wrap payment-dialog">
      <button class="custom-btn rounded" style="margin: auto"
              (click)="showUserPaymentModal = false;">Nein</button>
      <button class="custom-btn rounded" (click)="save(true)">Ja (Test anlegen)</button>
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showImportCWAvCardModal" (closeClicked)="showImportCWAvCardModal=false">
  <app-cwa-vcard-import (submitFinished)="importFinished($event)"></app-cwa-vcard-import>
</app-modal>
