import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../company.service';
import { Company } from '../../../models/company';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.css']
})
export class CompanyEditComponent implements OnInit {

  @Output() submitFinished: EventEmitter<any> = new EventEmitter<any>();
  @Input() company: Company | undefined;

  form!: FormGroup;
  errorMessage = '';

  constructor(private fb: FormBuilder, private translate: TranslateService, private companyService: CompanyService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.company?.name, [Validators.required]],
      description: [this.company?.description],
      name_oegd: [this.company?.name_oegd],
      testcenter_id: [this.company?.testcenter_id],
      street: [this.company?.street],
      postalCode: [this.company?.postalCode],
      city: [this.company?.city],
      isWalkIn: [this.company?.isWalkIn],
      multipleOrderUseAllowed: [this.company?.multipleOrderUseAllowed],
    });
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    this.errorMessage = '';

    const updated: Company | any = { ...this.company };

    updated!.name = this.form.controls.name.value;
    updated!.name_oegd = this.form.controls.name_oegd.value;
    updated!.testcenter_id = this.form.controls.testcenter_id.value;
    updated!.description = this.form.controls.description.value;
    updated!.street = this.form.controls.street.value;
    updated!.postalCode = this.form.controls.postalCode.value;
    updated!.city = this.form.controls.city.value;
    updated!.isWalkIn = this.form.controls.isWalkIn.value;
    updated!.multipleOrderUseAllowed = this.form.controls.multipleOrderUseAllowed.value;

    this.companyService.editCompany(updated!).subscribe((res: any) => {
      this.submitFinished.emit(updated);
    }, (error: any) => {
      this.errorMessage = error?.error?.error?.description ?? 'Unknown Error';
    });

  }

}
