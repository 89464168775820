<div class="wrapper">
  <div class="container-h-legend">
    <div>
      <h1>{{ 'TESTRESULT.TEST_RECEIVED_TITLE' | translate }}</h1>
    </div>
  </div>

  <div class="center">
    <ul class="header">
      <li>
        <span class="normal">{{ 'COMPANYCHECKIN.PATIENT_ORDERID' | translate }}</span>
        <span class="normal">{{ 'TESTRESULT.TEXT_SUMMARY_TYPE' | translate }}</span>
        <span class="normal">{{ 'TESTRESULT.TEST_DATE' | translate }}</span>
        <span class="normal">{{ 'TESTRESULT.TEST_RECEIVED' | translate }}</span>
        <span class="normal">{{ 'TESTRESULT.FIRST_RECEIVED' | translate }}</span>
        <span class="normal">{{ 'TESTRESULT.LAST_RECEIVED' | translate }}</span>
      </li>
    </ul>

    <div class="divider"></div>

    <ul class="list">
      <li *ngFor="let result of results">
        <span class="normal">{{ result.order_id }}</span>
        <span class="normal">{{ result.test_type }}</span>
        <span class="normal">{{ result.test_date | date: 'EE, dd.MM.yyyy'  }}</span>
        <span class="normal">{{ (result.received === "1" || result.received === true) ? 'Ja' : 'Nein' }}</span>
        <span class="normal">{{ result.first_received | date: 'EE, dd.MM.yyyy'  }}</span>
        <span class="normal">{{ result.last_received | date: 'EE, dd.MM.yyyy'  }}</span>
      </li>
    </ul>
  </div>

</div>
