import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  protected http: HttpClient;
  protected baseUrl = 'https://mcsintern.emotion-shops.com/api';
  // protected baseUrl = 'http://localhost:8080/api';
  // protected baseUrl = 'https://mcsintern.meincoronaschnelltest.de/api';
  protected authEndpoint = '/auth';
  protected userEndpoint = '/user';
  protected signingEndpoint = '/signing';

  constructor(http: HttpClient) {
    this.http = http;
  }
}
