<app-alert *ngIf="userMessage" [message]="userMessage" [type]="'success'"></app-alert>

<div class="v-scroll-inner-top">
  <div class="left">
    <div class="general-actions">
      <ng-container *ngIf="showAddButtons">
        <div class="btn-container add-buttons">
          <button class="custom-btn action-btn add-btn" (click)="clickAddUser.emit()">+</button>
          <button class="custom-btn action-btn" (click)="clickUploadCSV.emit()">
            <fa-icon [icon]="faFileCsv"></fa-icon>
            {{ 'COMPANYADMIN.BTN_UPLOAD' | translate }}</button>
        </div>
      </ng-container>

      <button *ngxPermissionsOnly="P.CAN_ADD_TEST_USER_IN_COMPANY_CHECKIN" class="add-btn action-btn" (click)="showAddNewTestUser = true">+</button>

      <button *ngIf="showExport" class="action-btn" (click)="exportResults()">
        <fa-icon [icon]="faDownload"></fa-icon>
        <span>Testergebnisse exportieren</span>
      </button>
      <button *ngIf="selectedOrder?.selfcheckin" class="action-btn" (click)="showSelfCheckInInfo()">
        <fa-icon [icon]="faQrcode"></fa-icon>
        <span>Self-Checkin ({{selectedOrder?.selfcheckin_cur_users}}/{{selectedOrder?.selfcheckin_max_users}}
          Benutzer)</span>
      </button>
      <button *ngIf="allowArchive && companyOrderMode" class="action-btn" (click)="archiveCompanyOrder()"
              [disabled]="isArchive">
        <fa-icon [icon]="faFolderOpen"></fa-icon>
        <span>archivieren</span>
      </button>
      <button *ngIf="isWalkInCompany && companyOrderMode" class="action-btn" (click)="exportTestuserData()"
              [disabled]="patients.length == 0">
        <fa-icon [icon]="faDownload"></fa-icon>
        <span>Personendaten exportieren</span>
      </button>

      <button *ngIf="companyOrderMode" class="center-btn action-btn" (click)="loadOrderTests()">
        <fa-icon [icon]="faSync"></fa-icon>
      </button>
    </div>

    <ng-container>
      <div class="filter-wrapper" [class.only-total]="!showSearchBar">
        <input id="searchbar" *ngIf="showSearchBar" type="text" (input)="search($event.target)"
               placeholder="Filter (Nachname)">
        <div>
          <b>Gesamt: </b> {{ patients.length }}
        </div>
      </div>
    </ng-container>
  </div>

  <div class="right">
    <div class="table-legend">
      <b>Legende</b>
      <div class="items">
        <div class="left">
          <div>
            <div class="testresult-indicator open"></div>
            <div>Offen</div>
          </div>
          <div>
            <div class="testresult-indicator checkedin"></div>
            <div>Checked-In</div>
          </div>
          <div>
            <div class="testresult-indicator positive"></div>
            <div>Positiv</div>
          </div>
          <div>
            <div class="testresult-indicator negative"></div>
            <div>Negativ</div>
          </div>
          <div>
            <div class="testresult-indicator invalid"></div>
            <div>Ungültig</div>
          </div>
        </div>
        <div class="right">
          <div>
            <fa-icon [icon]="faFileDownload"></fa-icon>
            <div>Einverständnis</div>
          </div>
          <div>
            <fa-icon [icon]="faShare"></fa-icon>
            <div>Resultat versenden</div>
          </div>
          <div>
            <fa-icon [icon]="faFileInvoice"></fa-icon>
            <div>Rechnung</div>
          </div>
          <div>
            <fa-icon [icon]="faFilePdf"></fa-icon>
            <div>Resultat Download</div>
          </div>
          <div>
            <fa-icon [icon]="faQrcode"></fa-icon>
            <div>CheckIn QR-Code</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="table-wrapper v-scroll-inner-table-wrap" #tableWrap id="table-wrap">
  <div class="table-spacer" #tableSpacer></div>
  <ul class="header" stickyThing [spacer]="tableSpacer" [boundary]="tableBody" [marginTop]="60"
      [class.menu-is-collapsed]="sharedService.menuIsCollapsedAsObservable | async">
    <li>
      <span class="item normal" (click)="sortList('testkit_id', testKitIdLabel)"
            #testKitIdLabel>{{ 'COMPANYCHECKIN.PATIENT_TESTKITID' | translate }}
        <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      <span class="item normal" (click)="sortList('lastname', lastnameLabel)"
            #lastnameLabel>{{ 'COMPANYCHECKIN.PATIENT_LAST' | translate }}
        <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      <span class="item normal" (click)="sortList('firstname', firstnameLabel)"
            #firstnameLabel>{{ 'COMPANYCHECKIN.PATIENT_FIRST'| translate }}
        <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      <span class="item normal" (click)="sortList('birthday', birthdayLabel)"
            #birthdayLabel>{{ 'COMPANYCHECKIN.PATIENT_BIRTH'| translate }}
        <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>

      <span *ngIf="infopoint" class="item normal">{{ 'COMPANYCHECKIN.PATIENT_ORDER_ID'| translate }}</span>
      <span *ngIf="infopoint" class="item normal">{{ 'COMPANYCHECKIN.PATIENT_ORDER_DATE'| translate }}</span>

      <span class="item normal" (click)="sortList('test_type', testTypeLabel)"
            #testTypeLabel>{{ 'COMPANYCHECKIN.PATIENT_TESTTYPE'| translate }}
        <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      <span class="item normal" (click)="sortList('test_location', testLocationLabel)"
            #testLocationLabel>{{ 'COMPANYCHECKIN.PATIENT_TESTLOCATION'| translate }}
        <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      <span class="item normal" (click)="sortList('test_time', testTimeLabel)"
            #testTimeLabel>{{ 'COMPANYCHECKIN.PATIENT_TESTTIME'| translate }}
        <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>

      <ng-container *ngIf="showCompanyAndTestTime">

           <span class="item normal" (click)="sortList('company_name', companyNameLabel)"
                 #companyNameLabel>{{ 'Firma'| translate }}
             <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>

        <span class="item normal" (click)="sortList('order_date', orderDateLabel)"
              #orderDateLabel>{{ 'Auftragsdatum'| translate }}
          <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>

      </ng-container>


      <span class="item normal" (click)="sortList('testresult', testresultLabel)"
            #testresultLabel>{{ 'COMPANYCHECKIN.PATIENT_TESTRESULT'| translate }}
        <fa-icon class="sort-arrow" [icon]="faChevronUp"></fa-icon></span>
      <span class="item normal">{{ 'COMPANYCHECKIN.PATIENT_ACTIONS'| translate }}</span>
    </li>
  </ul>

  <ul class="list" #tableBody>
    <virtual-scroller #scroll [items]="patients" [parentScroll]="scroll.window">
      <li [class.action-possible]="possibleActionsOnItem(patient)" class="test-list-item" *ngFor="let patient of scroll.viewPortItems" (click)="onSelectPatient(patient)">
        <span class="item testkit-id normal"
              [innerHTML]="patient.testkit_id! | formatTestKitNo: patient.company_id : patient.company_order_id"></span>
        <span class="item normal">{{ patient.lastname }}</span>
        <span class="item normal">{{ patient.firstname }}</span>
        <span class="item normal">{{ patient.birthday | date: 'dd.MM.yyyy' }}</span>
        <span *ngIf="infopoint" class="item normal">{{ patient.order_id }}</span>
        <span *ngIf="infopoint" class="item normal">{{ patient.order_date | date: 'dd.MM.yyyy' }}</span>
        <span class="item normal">{{ patient.test_type }}</span>
        <span class="item normal">{{ patient.test_location }}</span>
        <span class="item normal">{{ patient?.test_time! | time }}</span>
        <ng-container *ngIf="showCompanyAndTestTime">
          <span class="item normal">{{ patient?.company_name! }}</span>
          <span class="item normal">{{ patient?.order_date! | date }}</span>
        </ng-container>
        <span class="item normal" [innerHTML]="patient?.testresult! | testresult : patient?.status!"></span>
        <span class="item normal actions">

           <ng-template [ngxPermissionsOnly]="[P.CAN_TESTUSER_TABLE_DELETE, P.CAN_TESTUSER_FINISHED_TEST_TABLE_DELETE]" [ngxPermissionsElse]="elseDeleteBlock">
               <fa-icon *ngIf="(patient.status === '0' && allowDelete) || allowDeleteFinishedTest; else elseDeleteBlock" [icon]="faTrash" (click)="deleteUser(patient, $event)"></fa-icon>
           </ng-template>
           <ng-template #elseDeleteBlock>
               <fa-icon [icon]="faTrash" class="unavailable"></fa-icon>
           </ng-template>

            <fa-icon *ngIf="patient.consent_given; else elsePrivacy" [icon]="faFileDownload" (click)="getPrivacy(patient, $event)"></fa-icon>
            <ng-template #elsePrivacy><fa-icon [icon]="faFileDownload" class="unavailable" title="UNAVAILABLE"></fa-icon></ng-template>

            <ng-template [ngxPermissionsOnly]="[P.CAN_RESEND_RESULT_FROM_TABLE]" [ngxPermissionsElse]="elseSendResult">
              <fa-icon *ngIf="patient.status === '2' && patient.testresult; else elseSendResult" [icon]="faShare" (click)="sendResult(patient, $event)"></fa-icon>
            </ng-template>
            <ng-template #elseSendResult><fa-icon [icon]="faShare" class="unavailable"></fa-icon></ng-template>

             <ng-template [ngxPermissionsOnly]="[P.CAN_DOWNLOAD_INVOICE]">
              <fa-icon *ngIf="(patient.status === '2' || patient.status === '1') && ['antigen-paid', 'antigen-paid-reduced', 'antigen-team-paid', 'antigen-card', 'pcr-lab-paid'].includes(patient.test_type); else elseDownloadInvoice"
                       [icon]="faFileInvoice" (click)="downloadInvoice(patient, $event)"></fa-icon>
            </ng-template>
            <ng-template #elseDownloadInvoice><fa-icon [icon]="faFileInvoice" class="unavailable"></fa-icon></ng-template>

             <ng-template [ngxPermissionsOnly]="[P.CAN_DOWNLOAD_RESULT_FROM_TABLE]" [ngxPermissionsElse]="elsePrintResult">
              <fa-icon *ngIf="patient.status === '2' && patient.testresult && showPrintPDF && P.CAN_DOWNLOAD_RESULT_FROM_TABLE; else elsePrintResult"
                       [icon]="faFilePdf" (click)="printResult(patient, $event)"></fa-icon>
             </ng-template>
           <ng-template #elsePrintResult><fa-icon [icon]="faFilePdf" class="unavailable"></fa-icon></ng-template>

            <fa-icon *ngIf="patient?.order_id! && patient.status === '0'; else elseShowOrderQRCode" [icon]="faQrcode"
                     (click)="showOrderQrCode($event, patient)"></fa-icon>
             <ng-template #elseShowOrderQRCode>
                   <fa-icon [icon]="faQrcode" class="unavailable" title="UNAVAILABLE"></fa-icon>
            </ng-template>
          </span>
      </li>
    </virtual-scroller>
  </ul>
</div>

<!-- ResendResult Modal -->

<app-modal *ngIf="showResendResultDialog" (closeClicked)="showResendResultDialog = false">
  <app-resend-result [patient]="selectedPatient!" [companyOderId]="selectedPatient?.company_order_id"
                     (submitFinished)="resendFinished($event)"></app-resend-result>
</app-modal>

<!-- PDF Modal -->

<app-modal [fixedSize]="false" *ngIf="showPDFPreview" (closeClicked)="showPDFPreview = false">
  <div *ngIf="!testresultReceived" class="alert alert-warning" role="alert">
    Achtung: Dieses Testergebnis wurde noch nicht abgeholt.<br />
    Das Testergebnis muss bestätigt werden, bevor es an die Testperson ausgehändigt werden darf.<br />
    <button class="custom-btn rounded" style="width: auto;" (click)="updateTestresultReceivedStatus()">Testergebnis bestätigen</button>
  </div>
  <iframe *ngIf="testresultReceived || userAllowedToSeeUnreceivedResults" style="height:75vh;" [src]="pdfSource" frameborder="0"></iframe>
</app-modal>

<!-- checkin Modal -->

<app-modal *ngIf="showCheckinDialog" (closeClicked)="showCheckinDialog = false">
  <app-checkin [modalMode]="true" [orderId]="selectedPatient!.order_id!.toString()" [isWalkIn]="selectedOrder?.is_walk_in!"
               [consecutiveOrderIds]="selectedOrder?.consecutive_order_ids!"
               (updateUser)="userUpdateFromCheckin($event)"
               (submitFinished)="checkInFinished($event)"></app-checkin>
</app-modal>

<!-- Result Modal -->

<app-modal *ngIf="showTestResultDialog && selectedPatient" (closeClicked)="showTestResultDialog = false">
  <app-testresult [isModal]="true" [testType]="selectedPatient?.test_type!" [testKitId]="selectedPatient?.testkit_id!"
                  (submitFinished)="resultFinished($event)"></app-testresult>
</app-modal>

<app-modal *ngIf="showPCRTestResultDialog && selectedPatient" (closeClicked)="showPCRTestResultDialog = false">
  <app-testresult-exp [isModal]="true" (submitFinished)="resultFinished($event)"></app-testresult-exp>
</app-modal>

<app-modal *ngIf="showSelfcheckinModal" (closeClicked)="showSelfcheckinModal = false">
  <app-qr-code [uuid]="selectedOrder?.selfcheckin_uuid"></app-qr-code>
</app-modal>

<app-modal *ngIf="showEditTestUserModal" (closeClicked)="showEditTestUserModal = false">
  <app-testuser-edit [testuser]="selectedPatient!" [pk]="privateKey" (submitFinished)="userEditFinished($event!)"
                     [companyId]="selectedCompany?.id"></app-testuser-edit>
</app-modal>

<app-modal *ngIf="showAddNewTestUserPCR" (closeClicked)="showAddNewTestUserPCR = false">
  <app-testuser-edit
    [testuser]="selectedPatient!"
    [headline]="'COMPANYADMIN.CREATE_NEW_PCR_TEST'"
    [lockTestType]="'pcr-lab-free'"
    [allowVerification]="false"
    [addMode]="true"
    [showOrderSelection]="true"
    (submitFinished)="addNewTestUser($event!)">
  </app-testuser-edit>
</app-modal>

<app-modal *ngIf="showAddNewTestUser" (closeClicked)="showAddNewTestUser = false">
  <app-testuser-edit
    [orderId]="selectedOrder?.id"
    [companyId]="selectedCompany?.id"
    [allowVerification]="false"
    [addMode]="true"
    (submitFinished)="addNewTestUser($event!)">
  </app-testuser-edit>
</app-modal>

<app-modal *ngIf="showOrderQRCode" (closeClicked)="showOrderQRCode = false">
  <app-qr-code [orderId]="selectedPatient?.order_id!" [printLink]="true"></app-qr-code>
</app-modal>
