import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Errors } from '../../../testresult-exp/testresult-exp.component';
import * as moment from 'moment';
import { Order } from '../../../models/order';
import { CompanyService } from '../../../company.service';
import { AuthenticationService } from '../../../authentication.service';
import { concatMap, finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { from } from 'rxjs';
import { Person } from '../../../models/person';
import { testUserCSVMap, testUserCSVMapUpload } from '../../../download/csvHelper';


@Component({
  selector: 'app-add-company-testuser-csv',
  templateUrl: './add-company-testuser-csv.component.html',
  styleUrls: ['./add-company-testuser-csv.component.css']
})
export class AddCompanyTestuserCsvComponent {

  readonly allowedFileTypes = ['text/csv', 'application/vnd.ms-excel'];
  public faFileDownload: IconDefinition = faFileDownload;

  @ViewChild('inputCsv') inputCsv!: ElementRef;

  @Input() selectedOrder: Order | null = null;
  @Input() companyId: any;
  @Output() errorEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() done: EventEmitter<any> = new EventEmitter<any>();

  private privateKey = '';

  itemsCounter = 0;
  totalElements = 0;

  constructor(
    private companyService: CompanyService,
    private authenticationService: AuthenticationService,
    private spinnerService: NgxSpinnerService,
    private ngxCsvParser: NgxCsvParser,
  ) {
    if (this.authenticationService.getPrivateKey() !== '') { this.privateKey = this.authenticationService.getPrivateKey(); }
  }

  public upload(): void {
    const files = this.inputCsv.nativeElement.files;

    if (files && files.length > 0) {
      const file: File = files.item(0)!;

      if (!this.allowedFileTypes.includes(file.type)) {
        this.errorEmitter.emit(Errors.FileType);
        return;
      }

      const addUserRequests: any[] = [];

      let callCounter = 0; // current index of the slice
      let totalCalls = 0;
      let ajaxCounter = 0; // current ajax call after each request
      const sliceSize = 50;

      this.ngxCsvParser.parse(file, { header: false, delimiter: ';' })
        .pipe(
          finalize(() => {
            this.spinnerService.show('upload-spinner');
            from(addUserRequests).pipe(
              concatMap(res => res),
              finalize(() => {
                if (totalCalls === callCounter){
                  this.done.emit();
                  if (totalCalls === ajaxCounter){
                    this.spinnerService.hide('upload-spinner');
                  }
                }
              })
            ).subscribe(() => {},
            error => this.errorEmitter.emit(error?.error?.error?.description ?? error.statusText));
          })
        ).subscribe((csv: any) => {
          let header = csv.shift(); // remove csv header labels
          const formatHeader = testUserCSVMapUpload.map(item => item.label);

          if (header.length === 3) {
            header = csv.shift(); // Fallback if csv is export format with extra header
          }

          header = header.toString();
          if (header.charAt(header.length - 1) === ',') {
            header = header.slice(0, -1);
          }

          if (header.trim().toLocaleLowerCase() !== formatHeader.toString().toLocaleLowerCase()) {
            this.errorEmitter.emit('Falsches CSV Format');
            return;
          }

          totalCalls = csv.chunk(sliceSize).length;
          this.totalElements = csv.length;
          csv.chunk(sliceSize).forEach((block: any) => {
            const formattedUsers: any = [];
            block.forEach((line: any) => {
              if (line.length < 14) {
                return;
              }
              const person: Person | any = {};
              testUserCSVMapUpload.forEach((item, index) => {
                let value = (line[index] ?? '').trim();
                if (item.key === 'sex') {
                  if (value === 'm') {
                    value = 'mr';
                  } else if (value === 'w') {
                    value = 'ms';
                  } else {
                    value = 'd';
                    }
                } else if (item.key === 'birthday' && value) {
                  value = moment(value, 'DD.MM.YYYY').utc(true).toISOString() ?? '';
                } else if (item.key === 'phone' && value) {
                  value = value.replace(/[^0-9+]/g, '');
                }
                person[item.key] = value;
              });

              if (person.firstname && person.lastname && person.test_type) {
                formattedUsers.push(person);
              } else {
                this.errorEmitter.emit('Tests konnten nicht vollständig angelegt werden. Eine oder mehr Pflichtangaben unvollständig (Vorname, Nachname, Testtyp)');
              }
            });

            addUserRequests.push(this.companyService.uploadCompanyEmployees(
              formattedUsers,
              this.selectedOrder?.company_id ?? this.companyId,
              this.selectedOrder?.id,
            ).pipe(

              finalize(() => {
                ajaxCounter += 1;
                if (ajaxCounter === totalCalls - 1){
                  this.itemsCounter = csv.length;
                } else {
                  this.itemsCounter = (ajaxCounter + 1) * sliceSize;
                }
              })

            ));

            callCounter += 1;

          });
      }, (error: NgxCSVParserError) => this.errorEmitter.emit(error.message));
    }
  }
}
