<div class="type">
  {{ type }}
</div>
<div class="date" [class.warning]="!testDateIsToday">
  {{ date }}
</div>
<div class="time" [class.warning]="testTimeIsLate">
  {{ time }}
</div>
<div class="text-center" [class.done]="done">
  <ng-container *ngIf="!done">
    -
  </ng-container>
  <ng-container *ngIf="done">
    <fa-icon [icon]="check"></fa-icon>
  </ng-container>
</div>
