import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { Person } from './models/person';
import { Company } from './models/company';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getUser(): Observable<any> {
    return this.http.get<any>(this.baseUrl + this.userEndpoint + '/1');
  }

  public getAllUsersAdmin(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/user/all');
  }

  public deleteUserAdmin(id: any): Observable<any> {
    return this.http.delete<any>(this.baseUrl + '/user/' + id);
  }

  public editUserAdmin(id: any, user: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/user/edit/' + id, user);
  }

  public addUserAdmin(user: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/user', user);
  }

  public getAllLocations(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/admin/locations');
  }

  public getMappedSampleTestTypes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/admin/mappedSampleTestTypes');
  }

  public getTestReasons(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/admin/testReasons');
  }

  public sampleTestTypes(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/admin/sampleTestTypes');
  }

  public addSampleTestType(name: any, type: any, description: any, deviceId: any, testId: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/admin/addSampleTestType', {name, type, description, device_id: deviceId, test_id: testId});
  }

  public updateSampleTestType(id: any, name: any, type: any, description: any, deviceId: any, testId: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/admin/updateSampleTestType', {id, name, type, description, device_id: deviceId, test_id: testId});
  }

  public getAccountInfoForOrder(orderID: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/test/checkin/' + orderID.trim());
  }

  public getTestkitInfo(testkitID: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/test/' + testkitID.trim());
  }

  public appConsent(orderId: string, useCwa: boolean, useLuca: boolean): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/test/apps', {
      order_id: orderId,
      use_app_cwa: useCwa,
      use_app_luca: useLuca,
    });
  }

  public updateSampleTestTypeMapping(id: number, sampleTypeId: string, category: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/admin/editSampleTestTypesMapping', {
      id,
      sample_type_id: sampleTypeId,
      category,
    });
  }

  public checkIn(orderID: string, testkit: string, testType: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/test/checkin', {
      order_id: orderID.trim(),
      test_id: testkit.trim(),
      test_type: testType.trim()
    });
  }

  public getNotReceived(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/admin/notreceived');
  }

  public saveTestUserConsent(userId: string, privacyBlob: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/test/privacy/user/' + userId.trim(), {
      privacy: privacyBlob,
    });
  }

  public submitResult(testkit: string, result: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/test/result', { test_id: testkit.trim(), test_result: result });
  }

  public updateOrder(orderID: string, firstname: string, lastname: string, email: string, phone: string, zip: string, city: string, street: string, idcardno: string, birthday: string, companyTestUserId = 0, originalTestType = '', newTestType = '', selectedReason: string | null | undefined = '', originalReason = ''): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/test/checkin/' + orderID.trim(),
      { firstname, lastname, email, birthday, phone, zip, city, street, idcardno, company_test_user_id: companyTestUserId, original_test_type: originalTestType, new_test_type: newTestType, selected_reason: selectedReason, original_reason: originalReason }
    );
  }

  public downloadPositives(date: string, filter: string, privateKey: string, companyId: number | undefined, zip = false): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/admin/positive/' + date, { test_types: filter, company_id: companyId, private_key: privateKey, zip_archive: zip }, { observe: 'response' });
  }

  public downloadOEGD(orderId: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/company/downloadoegd/' + orderId, null, { observe: 'response' });
  }

  public reencryptTest(orderID: string, privateKey: string, testType: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/admin/reencrypt/' + orderID, { private_key: privateKey, test_type: testType });
  }

  public resetTestuser(orderID: string, privateKey: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/admin/reset/' + orderID, { private_key: privateKey });
  }

  public getTeststatus(id: any, isTestId: boolean, privateKey: any): Observable<any> {
    let body = { };
    if (privateKey) {
      body = {
        private_key: privateKey
      };
    }
    return this.http.post<any>(this.baseUrl + '/admin/details/' + isTestId + '/' + id, body);
  }

  public getUtilization(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/admin/utilization/check');
  }

  public updateTestuser(orderId: number,  data: any, encrypted: boolean, testId: number, pk:string, testtype: string, company_order: boolean, employee_id: number): Observable<any> {
    let result = this.http.post<any>(this.baseUrl + '/admin/updateOrder/' + orderId,
      { company_id: 'nichtvorhanden', encrypted, data, test_id: testId, private_key: pk , testtype, company_order, employee_id });

    return result;
  }

  public getCheckedInPCRTests(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/test/pcr/checkedin');
  }

  public getHealyzerTests(testkitIds: any[]): Observable<any> {
    let params = "";
    let first = true;
    testkitIds.forEach(testkitId => {
      if(first) {
        first = false;
        params += "?testkitids[]=" + testkitId["testkit_id"];
      } else {
        params += "&testkitids[]=" + testkitId["testkit_id"];
      }
    });
    return this.http.get<any>(this.baseUrl + '/test/healyzer' + params);
  }
}
