<div *ngIf="disabled" class="hint-box">{{ 'REGISTRATION.HEADER_THIRD_PARTY_CONSENT_DISABLED' | translate }}</div>
<div *ngIf="disableCWA && !disabled" class="hint-box">{{ 'REGISTRATION.HEADER_THIRD_PARTY_DCC_ONLY_ANTIGEN_INFO' | translate }}</div>
<div class="hint-box"><b>{{ 'REGISTRATION.HEADER_THIRD_PARTY_DCC_INFO' | translate }}</b></div>
<div class="item-wrapper">
  <div class="item">
    <span class="title">
      <img src="/assets/images/corona-warn-app-logo.svg" alt="corona warn app">
      Corona Warn-App
    </span>
    <label class="radio-item">
      <input type="radio" [value]="0" [formControl]="useAppCwaInput">
      <span><b>Keine Nutzung.</b></span>
    </label>
    <label class="radio-item">
      <input type="radio" [value]="1" [formControl]="useAppCwaInput">
      <span><b>Einwilligung zur pseudonymisierten Übermittlung (nicht-namentliche Anzeige)</b>Hiermit erkläre ich mein Einverständnis zum Übermitteln meines Testergebnisses und meines pseudonymen Codes an das Serversystem des RKI, damit ich mein Testergebnis mit der Corona-Warn-App abrufen kann. Das Testergebnis in der App kann hierbei nicht als namentlicher Testnachweis verwendet werden. Die Datenschutzhinweise habe ich zur Kenntnis genommen und bin damit einverstanden.</span>
    </label>
    <label class="radio-item">
      <input type="radio" [value]="2" [formControl]="useAppCwaInput" >
      <span><b>Einwilligung zur personalisierten Übermittlung (namentlicher Testnachweis)</b>Hiermit erkläre ich mein Einverständnis zum Übermitteln des Testergebnisses und meines pseudonymen Codes an das Serversystem des RKI, damit ich mein Testergebnis mit der Corona-Warn-App abrufen kann. Ich willige außerdem in die Übermittlung meines Namens und Geburtsdatums an die App ein, damit mein Testergebnis in der App als namentlicher Testnachweis angezeigt werden kann. Die Datenschutzhinweise habe ich zur Kenntnis genommen und bin damit einverstanden.</span>
    </label>
  </div>
  <label class="item">
    <span class="title">
      <img src="/assets/images/luca-app-logo.svg" alt="luca app">
      Luca
    </span>
    <span class="check">
      <input type="checkbox" [formControl]="useAppLucaInput">
    </span>
    <span class="label">
      <b>Einwilligung zur pseudonymisierten Übermittlung (nicht-namentliche Anzeige)</b>Hiermit erkläre ich mein Einverständnis zum Übermitteln des Testergebnisses an die LUCA-App.
    </span>
  </label>
</div>

<button class="custom-btn rounded" (click)="confirm()">{{ 'REGISTRATION.BTN_CONFIRM' | translate }}</button>
